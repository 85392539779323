import React, {useState, useEffect} from 'react';
import { get } from 'lodash';
import {
    Chip,
    FormControl,
    Input,
    InputLabel,
    makeStyles,
    MenuItem,
    Select,
  } from '@material-ui/core';
import CustomInput from '../../../components/CustomInput/CustomInput';
import CardHeader from '../../../components/Card/CardHeader';
import Error from '../../../components/Error';
import GridItem from '../../../components/Grid/GridItem';
import GridContainer from '../../../components/Grid/GridContainer';
import Card from '../../../components/Card/Card';
import {
    channels,
    packageStatus,
    packageType,
    validityType,
    gender
  } from '../../../config/env.json';
  import { search } from '../../../dataService/search';

const PackageFormBlock = ({ formik }) => {
    const classes = useStyle();
  const { touched, errors } = formik;
  const [packages, setPackages] = useState([]);

  const loadPackages = async () => {
    let allPackages = [];
    let pageData = [];
    let counter = 1;
    while (pageData) {
      pageData = await search({
        _entity: 'Package',
        packageCode: 'PKG',
        _select: 'packageCode',
        _page: counter,
      });
      if (pageData.data.length === 0) {
        break;
      }
      allPackages = allPackages.concat(pageData.data);
      counter++;
    }
    setPackages(allPackages);
  };

  useEffect(() => {
    loadPackages();
  }, []);

  return (
    <Card>
     
      <h4 style={{ paddingLeft: '20px' }}>
        <b>Package Details</b>
      </h4>
      <GridItem>
        <GridContainer>
       
         
            <GridItem xs={6}>
            <FormControl className={classes.formControl}>
              <InputLabel>Valid packages</InputLabel>
              <Select {...formik.getFieldProps('validPackages')}    >
                <MenuItem key="PKG_SELECT_ALL" value="PKG_SELECT_ALL">
                    Valid for All Packages
                </MenuItem>
                {packages.map(({ packageCode }) => (
                  <MenuItem key={packageCode} value={packageCode}>
                    {packageCode}
                  </MenuItem>
                ))}
              </Select>
                  
              {get(errors, 'validPackages', '') &&
                get(touched, 'validPackages', '') && (
                  <Error
                    msg={get(errors, 'validPackages', '')}
                  />
                )}
            </FormControl>
            {get(errors, 'validPackages', '') && get(touched, 'validPackages', '') && (
              <Error msg={get(errors, 'validPackages', '')} />
            )}
          </GridItem>
        </GridContainer>
      </GridItem>
    </Card>
  );
};
const useStyle = makeStyles({
    formControl: {
      width: '100%',
      marginTop: '27px',
    },
  });
export default PackageFormBlock;
