import React, { useState, useEffect, useCallback } from 'react';
import { makeStyles, Grid } from '@material-ui/core';
import { KeyboardArrowRight, KeyboardArrowLeft } from '@material-ui/icons';
import TableFormatter from '../../components/TableFormatter';
import Button from '../../components/CustomButtons/Button';
import GridItem from '../../components/Grid/GridItem';
import GridContainer from '../../components/Grid/GridContainer';
import { allActivities } from '../../dataService/allActivities';
import ActivityRow from './ActivityRow';
import NoContentCard from '../../components/NoContentCard';
import Loading from '../../components/Loading';
import { createMuiTheme, MuiThemeProvider } from '@material-ui/core/styles';
import { Link } from 'react-router-dom';
import LastPageIcon from '@material-ui/icons/LastPage';
import FirstPageIcon from '@material-ui/icons/FirstPage';
export default () => {
  const classes = useStyles();
  const [rows, setRows] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [pageNumber, setPageNumber] = useState(0);
  const [resultCount, setResultCount] = useState(20);
  const [pageCount, setPageCount] = useState(0);
  const tableHead = [
    { name: 'Activity Code', align: 'left' },
    { name: 'Title', align: 'left' },
    { name: 'Created At', align: 'left' },
    { name: 'Updated At', align: 'left' },
    { name: 'Actions', align: 'right' },
  ];
  const [searchTerms, setSearchTerms] = useState({
    _entity: 'Activity',
    _select: '*',
    _page: 1,
  });

  const createData = (
    id,
    activityCode,
    title,
    createdAt,
    updatedAt,
  ) => {
    return {
      id,
      activityCode,
      title,
      createdAt,
      updatedAt,
    };
  };

  const createUserRows = useCallback(async () => {
    const res = await allActivities(searchTerms);
    setIsLoading(true);
    const rows = [];

    if (res.status !== 'success') return [];

    res.data.forEach(item => {
      const {
        id,
        activityCode,
        title,
        createdAt,
        updatedAt,
      } = item;
      rows.push(
        createData(
          id,
          activityCode,
          title,
          createdAt,
          updatedAt,
        ),
      );
    });
    const count = Math.ceil(res.meta.totalCount / 20);
    setRows(rows);
    setIsLoading(false);
    setPageNumber(res.meta.page);
    setResultCount(res.meta.resultCount);
    setPageCount(count);
  }, [searchTerms]);

  useEffect(() => {
    createUserRows();
  }, [searchTerms]);

  const prevPage = () => {
    setSearchTerms({
      ...searchTerms,
      // eslint-disable-next-line no-underscore-dangle
      ...{ _page: searchTerms._page - 1 },
    });
  };

  const nextPage = () => {
    setSearchTerms({
      ...searchTerms,
      // eslint-disable-next-line no-underscore-dangle
      ...{ _page: searchTerms._page + 1 },
    });
  };

  const lastPage = () => {
    setSearchTerms({
      ...searchTerms,
      ...{ _page: searchTerms._page = pageCount },
    });
  };

  const firstPage = () => {
    setSearchTerms({
      ...searchTerms,
      ...{ _page: searchTerms._page = 1 },
    });
  };

  return (
    <>
      <React.Fragment>
        <h3>All Activities</h3>
        <Link to={`/activities/new`}>
          <Button color="success" small>
            Add new activity
          </Button>
        </Link>
      </React.Fragment>
      {isLoading && (
        <Loading
          className={classes.topSpacing}
          boxHeight="calc(100vh - 120px)"
        />
      )}

      {!isLoading && !rows.length && (
        <Grid container justify="flex-end" className={classes.topSpacing}>
          <Grid item md={12}>
            <NoContentCard message="No activities found" />
            <Pagination
              nextPage={nextPage}
              prevPage={prevPage}
              pageNumber={pageNumber}
              resultCount={resultCount}
              lastPage={lastPage}
              firstPage={firstPage}
            />
          </Grid>
        </Grid>
      )}

      {rows.length > 0 && !isLoading && (
        <TableFormatter
          tableHead={tableHead}
          height="calc(100vh - 150px)"
          pagination={() => (
            <Pagination
              nextPage={nextPage}
              prevPage={prevPage}
              pageNumber={pageNumber}
              resultCount={resultCount}
              pageCount={pageCount}
              lastPage={lastPage}
              firstPage={firstPage}
            />
          )}
        >
          {rows.map(row => (
            <MuiThemeProvider theme={theme}>
              <ActivityRow key={row.id} {...row} />
            </MuiThemeProvider>
          ))}
        </TableFormatter>
      )}
    </>
  );
};

const Pagination = ({ nextPage, prevPage, pageNumber, pageCount, resultCount, lastPage, firstPage }) => {
  const classes = useStyles();
  return (
    <div className={classes.pagination}>
      <GridContainer direction="row" justify="center">
      <GridItem>
          <Button
            onClick={firstPage}
            color="transparent"
            round
            small
            disabled={pageNumber === 1}
          >
            <FirstPageIcon />
          </Button>
        </GridItem>
        { pageNumber === 1 ? null : (
        <GridItem>
          <Button
            onClick={prevPage}
            color="transparent"
            round
            small
            disabled={pageNumber === 1}
          >
            <KeyboardArrowLeft />
          </Button>
        </GridItem>
        )}
        <p>
          <strong>{pageNumber}</strong>
        </p>
        <GridItem>
          <Button
            onClick={nextPage}
            color="transparent"
            round
            small
            disabled={resultCount < 20}
          >
            <KeyboardArrowRight />
          </Button>
        </GridItem>
        <GridItem>
          <Button
            onClick={lastPage}
            color="transparent"
            round
            small
            disabled={resultCount < 20}
          >
              {pageCount}
            <LastPageIcon />
          </Button>
        </GridItem>
      </GridContainer>
    </div>
  );
};

const useStyles = makeStyles({
  topSpacing: {
    marginTop: '30px',
  },
  marginNone: {
    margin: 0,
  },
  wrapperCard: {
    marginBottom: 0,
  },
  pagination: {
    paddingTop: '15px',
  },
  tableContainer: {
    height: 'calc(100vh - 373px)',
    '& .MuiTableCell-stickyHeader': {
      background: 'white',
    },
  },
  notFoundContainer: {
    display: 'flex',
    alignItems: 'center',
    width: '100%',
    justifyContent: 'center',
    height: 'calc(100% - 57px)',
  },
});

const theme = createMuiTheme({
  overrides: {
    MuiTableCell: {
      root: {
        paddingTop: 4,
        paddingBottom: 4,
        paddingRight: 5,
      },
    },
  },
});
