import axiosInstance from './axiosInstance';

export const updateCustomer = async (values, customerCode) => {
  try {
    const res = await axiosInstance.put(
      `profile/source/${customerCode}`,
      values,
    );
    return res.data;
  } catch (error) {
    return error.response.data;
  }
};
