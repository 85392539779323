import axiosInstance from './axiosInstance';

export const addCSV = async csvInput => {
  try {
    var bodyFormData = new FormData();
    bodyFormData.append('csvFile', csvInput);
    const res = await axiosInstance.post('/register/csv', bodyFormData);
    return res.data;
  } catch (error) {
    return error.response.data;
  }
};
