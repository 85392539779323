import React ,{ useState } from 'react';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import { Link } from 'react-router-dom';
import { resetPassword } from './../../dataService/resetPassword';
import Snackbar from './../../components/Snackbar/Snackbar';
import { AddAlert } from '@material-ui/icons';


export default function ForgotPassword() {
const [open, setOpen] = useState(false);
const [email, setEmail] = useState('');
const [snackbarConfig, setSnackbarConfig] = useState({
    open: false,
    message: '',
    color: 'success',
  });

  const snackBarClose = () => {
    setSnackbarConfig({ ...snackbarConfig, open: false });
  };

const handleClickOpen = () => {
  setOpen(true);
};
function validateEmail(email) {
    const re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(String(email).toLowerCase());
}
const resetPW = async () => {
    try{
        const { status, message } = await resetPassword({
        type: "InternalUser",
	    email: email
    })
    setSnackbarConfig({
        open: true,
        message:
          status === 'success'
            ? 'Please check your email for the password reset'
            : 'No user with given email address. please try again...',
        icon: AddAlert,
        color: status === 'success' ? 'success' : 'danger',
      });
    }catch(e){
        setSnackbarConfig({
            open: true,
            message: "Something went wrong please contact your developer",
            icon: AddAlert,
            color:'danger',
          });
        console.log("error",e)
    }

}

const handleClose = () => {
    if(validateEmail(email)){
        resetPW()
        setOpen(false);
    }else{
        setSnackbarConfig({
            open: true,
            message: "Enter a valid email",
            icon: AddAlert,
            color:'danger',
          });
    }
};
return (
<div>
    <Link
        style={{display: 'flex',  justifyContent:'center', alignItems:'center', paddingBottom: 10}}
          onClick={handleClickOpen}
        >
          Forgot Password?
    </Link>
<Dialog open={open} onClose={handleClose} aria-labelledby="form-dialog-title">
<DialogTitle id="form-dialog-title">Reset Password</DialogTitle>
<DialogContent>
  <DialogContentText>
    For the reset password please enter your email address
  </DialogContentText>
  <TextField
    autoFocus
    margin="dense"
    id="name"
    label="Email Address"
    type="email"
    fullWidth
    onInput={(val)=>setEmail(val.target.value)}
  />
</DialogContent>
<DialogActions>
<Button onClick={handleClose} color="primary">
  Reset my password
</Button>
  <Button onClick={()=>setOpen(false)} color="primary">
    Cancel
  </Button>
</DialogActions>
</Dialog>
<Snackbar
{...snackbarConfig}
icon={AddAlert}
close
closeNotification={snackBarClose}
place="bl"
/>
</div>)
}