import React, { useState, useEffect } from 'react';
import { Formik } from 'formik';
import * as Yup from 'yup';
import { makeStyles } from '@material-ui/core';
import { AddAlert } from '@material-ui/icons';
import GridItem from '../../components/Grid/GridItem';
import CardBody from '../../components/Card/CardBody';
import GridContainer from '../../components/Grid/GridContainer';
import Card from '../../components/Card/Card';
import Snackbar from '../../components/Snackbar/Snackbar';
import GeneralInputFormBlock from './formParts/GeneralInputFormBlock';
import PackageFormBlock from './formParts/PackageFormBlock';
import EditFormBlock from './formParts/EditFormBlock';
import Header from '../../components/Header';
import Button from '../../components/CustomButtons/Button';
import Loading from '../../components/Loading';
import { updateCustomer } from '../../dataService/updateCustomer';
import { getCustomerInfo } from '../../dataService/getCustomerInfo';
import { yupSchemaObject, initialValues } from './editFormSchema';
import { isConditionalExpression } from 'typescript';

const EditCustomer = ({
  match: {
    params: { customerCode },
  },
}) => {
  const classes = useStyles();
  const [upi, setUpi] = useState('')
  const [formValues, setFormValues] = useState(initialValues);
  const validationSchema = Yup.object(yupSchemaObject);
  const [isLoaded, setIsLoaded] = useState(false);
  const [submitDisable, setSubmitDisable] = useState(false);
  const [snackbarConfig, setSnackbarConfig] = useState({
    open: false,
    message: '',
    color: 'success',
  });

  const snackBarClose = () => {
    setSnackbarConfig({ ...snackbarConfig, open: false });
  };

  const setSchemaAndInitialValueForEditForm = async customerCode => {
    const { data: formValues } = await getCustomerInfo(customerCode, true);
   setFormValues({
     firstName:formValues.profile.firstName,
     lastName:formValues.profile.lastName, 
     mobile:formValues.credential.mobile,
     dob:formValues.profile.dob,
     email:formValues.credential.email,
     street:formValues.profile.address.street,
     city:formValues.profile.address.city,
     zip:formValues.profile.address.zip, 
     
     });
     setUpi(formValues.upi)
    console.log("form values", formValues)
    console.log("upi", upi)
    setIsLoaded(true);
  };

  useEffect(() => {
    setSchemaAndInitialValueForEditForm(customerCode);
  }, []);

  const onSubmit = async formValues => {
    setSubmitDisable(true);
    const values = {
      firstName: formValues.firstName,
      lastName: formValues.lastName,
      dob: formValues.dob,
      address: {
          street: formValues.street,
          city: formValues.city,
          zip: formValues.zip
      }
    }
    const { status, message } = await updateCustomer(values,customerCode);

    if (status === 'success') {
      setSubmitDisable(false);
      setSnackbarConfig({
        open: true,
        message,
        icon: AddAlert,
        color: 'success',
      });
      setSchemaAndInitialValueForEditForm(customerCode);
    } else {
      setSubmitDisable(false);
      setSnackbarConfig({
        open: true,
        message,
        icon: AddAlert,
        color: 'danger',
      });
    }
  };

  if (!isLoaded) {
    return (
      <>
      
        <Card style={{ backgroundColor: '#800054' }}>
          <CardBody>
            <h3 style={{ color: '#ffffff' }}>
              <b>Edit Customer: {upi? upi:''}</b>
            </h3>
          </CardBody>
        </Card>
        <Loading
          className={classes.pageTopSpacing}
          boxHeight="calc(100vh - 122px)"
        />
      </>
    );
  }

  return (
    <>
   
      <Card style={{ backgroundColor: '#800054' }}>
        <CardBody>
          <h3 style={{ color: '#ffffff' }}>
            <b>Edit Customer: {upi? upi:''}</b>
          </h3>
        </CardBody>
      </Card>
      <Formik
        enableReinitialize
        initialValues={formValues}
        validationSchema={validationSchema}
        onSubmit={onSubmit}
      >
        {formik => (
          <form
            className={classes.pageTopSpacing}
            onSubmit={formik.handleSubmit}
          >
            <GridContainer>
              <GridItem xs={12}>
                <EditFormBlock formik={formik} isEdit />

             
               
                <GridContainer>
                  <GridItem>
                    <Button
                      disabled={
                        submitDisable ||
                        (formik.isValid && !formik.dirty) ||
                        !formik.isValid
                      }
                      color="success"
                      type="submit"
                    >
                      Update Customer
                    </Button>
                  </GridItem>
                </GridContainer>
                {/* </CardBody>
                </Card> */}
              </GridItem>
            </GridContainer>
          </form>
        )}
      </Formik>
      <Snackbar
        {...snackbarConfig}
        icon={AddAlert}
        close
        closeNotification={snackBarClose}
        place="bl"
      />
    </>
  );
};

const useStyles = makeStyles({
  pageTopSpacing: {
    marginTop: '30px',
  },
  blockSpacing: {
    marginTop: '60px',
  },
});

export default EditCustomer ;
