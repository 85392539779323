import React from 'react';
import {
  Card,
  Table,
  TableHead,
  TableCell,
  TableRow,
  TableBody,
  makeStyles,
  withStyles,
} from '@material-ui/core';
import CardBody from '../Card/CardBody';
import GridItem from '../Grid/GridItem';
import GridContainer from '../Grid/GridContainer';
import { Link } from 'react-router-dom';
import { Edit } from '@material-ui/icons';
import Button from '../CustomButtons/Button';
import { styles } from 'assets/jss/material-dashboard-react/components/cardIconStyle.js';

const DiscountInfoDetailsBody = ({
  discountInfo: {
    promoCode,
    title,
    promoStart,
    promoEnd,
    serviceType,
    discountAmount,
    status,
    channel,
  },
}) => {
  const classes = useStyles();

  return (
    <Card>
      <CardBody>
        <GridContainer>
          {/* here */}
          {/* <GridItem md={6}>
            <h4>Discount Details</h4>
          </GridItem> */}
          {/* <GridItem md={6}>
            <GridContainer justify="flex-end" alignItems="center">
              <GridItem>
                <Link to={`/customers/${customerCode}/edit`}>
                  <Button
                    color="danger"
                    style={{ position: 'relative', top: '22px' }}
                  >
                    <Edit />
                    Edit Customer Details
                  </Button>
                </Link>
              </GridItem>
            </GridContainer>
          </GridItem> */}
          <GridItem md={12}>
            <Table className={classes.table}>
              <TableHead>
                <TableRow>
                  <StyledTableCell
                    style={{
                      borderTopLeftRadius: '10px',
                      borderBottomLeftRadius: '10px',
                    }}
                  >
                    Discount Details
                  </StyledTableCell>
                  <StyledTableCell
                    style={{
                      borderTopRightRadius: '10px',
                      borderBottomRightRadius: '10px',
                    }}
                    align="right"
                  >
                    Value
                  </StyledTableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                <TableRow>
                  <TableCell align="left">Promo Code</TableCell>
                  <TableCell align="right">{promoCode || '-'}</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell align="left">Title</TableCell>
                  <TableCell align="right">{title || '-'}</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell align="left">Promo Start Date</TableCell>
                  <TableCell align="right">{promoStart || '-'}</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell align="left">Promo End Date</TableCell>
                  <TableCell align="right">{promoEnd || '-'}</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell align="left">Service Type</TableCell>
                  <TableCell align="right">
                    {serviceType.join(' ,') || '-'}
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell align="left">Discounted Amount</TableCell>
                  <TableCell align="right">{discountAmount || '-'}</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell align="left">Status</TableCell>
                  <TableCell align="right">{status || '-'}</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell align="left">Channel</TableCell>
                  <TableCell align="right">
                    {channel.join(' ,') || '-'}
                  </TableCell>
                </TableRow>
              </TableBody>
            </Table>
          </GridItem>
          <GridItem md={12}>
            <div className={classes.spacing} />
          </GridItem>
        </GridContainer>
      </CardBody>
    </Card>
  );
};

const StyledTableCell = withStyles(theme => ({
  head: {
    backgroundColor: '#778899',
    color: theme.palette.common.white,
    paddingTop: '20px',
    paddingBottom: '20px',
  },
  body: {
    fontSize: 14,
  },
}))(TableCell);

const useStyles = makeStyles({
  topSpacing: {
    marginTop: '30px',
  },
  subTitle: {
    marginTop: 0,
  },
  spacing: {
    height: '30px',
  },
  flexVCenter: {
    display: 'flex',
    alignItems: 'center',
  },
});

export default DiscountInfoDetailsBody;
