import React from 'react';
import { TableRow, TableCell } from '@material-ui/core';
import { Link } from 'react-router-dom';
import { Edit } from '@material-ui/icons';
import Button from '../../components/CustomButtons/Button';

const UserRow = ({ name, email, role, status, mobile }) => {
  return (
    <TableRow>
      <TableCell component="th" scope="row">
        {name}
      </TableCell>
      <TableCell align="left">{email}</TableCell>
      <TableCell align="right">{mobile}</TableCell>
      <TableCell align="right">{role}</TableCell>
      <TableCell
        align="right"
        style={{ color: status === 'Active' ? 'green' : 'red' }}
      >
        <strong>{status}</strong>
      </TableCell>
      <TableCell align="right">
        <Link to={`/users/edit/${email}`}>
          <Button small>Edit</Button>
        </Link>
      </TableCell>
    </TableRow>
  );
};

export default UserRow;
