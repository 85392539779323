import React, { useState } from 'react';
import { TableRow, TableCell } from '@material-ui/core';
import { Link } from 'react-router-dom';
import { EditOutlined, InfoOutlined } from '@material-ui/icons';
import InfoIcon from '@material-ui/icons/Info';
import Button from '../../components/CustomButtons/Button';
import MoreHorizIcon from '@material-ui/icons/MoreHoriz';
import { IconButton } from '@material-ui/core';
import { getCustomerInfo } from './../../dataService/getCustomerInfo';
import { getFamilyMemberDetails } from './../../dataService/getFamilyMemberDetails';
import Popover from '@material-ui/core/Popover';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import CircularProgress from '@material-ui/core/CircularProgress';
import Badge from '@material-ui/core/Badge'
import AccessibilityNewIcon from '@material-ui/icons/AccessibilityNew';
import DialogTitle from '@material-ui/core/DialogTitle';
import Dialog from '@material-ui/core/Dialog';
import CustomerSubscriptionDetailsBody from '../../components/CustomerSubscriptionDetailsBody';
import Loading from '../../components/Loading';
import RelativeDetails from './RelativeDetails';
import CloseIcon from '@material-ui/icons/Close';
import Divider from '@material-ui/core/Divider';
import moment from 'moment';

const useStyles = makeStyles((theme) => ({
  popover: {
    pointerEvents: 'none',
  },
  paper: {
    padding: theme.spacing(1),
  },
  packagekk: {
    height: '300px',
    width: '300px'
  },
  container: {
    display: "flex",
    justifyContent: "space-between",
  },
  content1: {
    justifySelf: "start",
  },
  content2: {
    justifySelf: "start",
  }
}));

const CustomerRow = ({
  firstName,
  lastName,
  customerCode,
  status,
  mobile,
  email,
  upi,
  createdAt,
  channel
}) => {
  const classes = useStyles();
  const [isLoading, setIsLoading] = useState(true);
  const [packageFlag, setPackageFlag] = useState(false);
  const [familyFlag, setFamilyFlag] = useState(false);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [packageLIst, setPackageLIst] = useState([])
  const [familyLIst, setFamilyLIst] = useState([])
  const [packageShowFlage, setPackageShowFlage] = useState(false);
  const [subscriptionDetails, setSubscriptionDetails] = useState({});
  const [packageDialogOpen, setPackageDialogOpen] = useState(false);
  const [familyDialogOpen, setFamilyDialogOpen] = useState(false);

  const handleClosePackage = () => {
    setPackageDialogOpen(false);
  }
  const handleCloseFamily = () => {
    setFamilyDialogOpen(false);
  }

  const closeIcon = () => {
    setPackageDialogOpen(false);
    setFamilyDialogOpen(false);
  }

  const dialogClickOpen = () => {
    if (!packageFlag) {
      packageInfo()
    };
    if (packageFlag && (packageLIst.length > 0)) {
      setPackageDialogOpen(true);
    }
  };

  const familyClickOpen = () => {
    if (!familyFlag) {
      familyInfo()
    };
    if (familyFlag && (familyLIst.length > 0)) {
      setFamilyDialogOpen(true);
    }
  }

  const handlePopoverOpen = (event) => {
    packageInfo()
  };

  const handlePopoverClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);

  const packageInfo = async () => {
    setPackageDialogOpen(false);
    try {
      const res = await getCustomerInfo(customerCode)
        .then(res => {
          if (res.data.currentPackages.length > 0) {
            setPackageDialogOpen(true);
            res.data.currentPackages.map((d => {
              packageLIst.push(d.packagetitle)
            }))
          } else {
            setPackageLIst([]);
            setPackageDialogOpen(false)
          }
          if (res.status !== 'error') {
            setSubscriptionDetails(res.data)
            setPackageFlag(true);
          }
        })
      setIsLoading(false);
    } catch (error) {
      console.log(error)
    }
  };

  const familyInfo = async () => {
    setFamilyDialogOpen(false);
    try {
      const res = await getFamilyMemberDetails(customerCode)
        .then(res => {
          if (res.status == "success") {
          
            setFamilyDialogOpen(true);
            setFamilyLIst(res.data);
            setFamilyFlag(true);
            if (res.data.length == 0) {
              setFamilyDialogOpen(false);
            }
          } else {
            setFamilyLIst(['-'])
            setFamilyDialogOpen(false);
          }
        })
      setIsLoading(false);
    } catch (error) {
      console.log(error)
    }

  };
 
  return (
    <TableRow>
      <Dialog
        contentStyle={{ width: "100%", maxWidth: "none" }}
        onClose={handleClosePackage}
        aria-labelledby="simple-dialog-title"
        scroll={'body'}
        open={packageDialogOpen}>
        {!isLoading && <div id="simple-dialog-title" style={{ textAlign: "end" }}>
          <IconButton >
            <CloseIcon onClick={closeIcon} />
          </IconButton>
        </div>}
        {isLoading && (
          <Loading
            className={classes.topSpacing}
            boxHeight="calc(50vh - 120px)"
          />
        )}
        {packageFlag && subscriptionDetails.currentPackages.map((data) => {
          return (<>
            <CustomerSubscriptionDetailsBody subscriptionDetails={data} />
            <Divider light />
          </>)
        })}
      </Dialog>
      <Dialog
        contentStyle={{ width: "100%", maxWidth: "none" }}
        onClose={handleCloseFamily}
        aria-labelledby="simple-dialog-title"
        scroll={'body'}
        open={familyDialogOpen}>
        {!isLoading && <div style={{ textAlign: "end" }} >
          <IconButton >
            <CloseIcon onClick={closeIcon} />
          </IconButton>
        </div>}
        {isLoading && (
          <Loading
            className={classes.topSpacing}
            boxHeight="calc(50vh - 120px)"
          />
        )}
        {familyFlag && familyLIst.map((data) => {
          return <RelativeDetails familyDetails={data} />
        })}
      </Dialog>
      <TableCell>
        <div className={classes.container}>
          <div className={classes.content1}>
            {firstName}
          </div>
        
        </div>
      </TableCell>
      <TableCell>{mobile}</TableCell>
     <TableCell>{email}</TableCell>
      <TableCell>{upi ? upi : '-'}</TableCell>
      <TableCell>{moment(createdAt).format("YYYY-MM-DD HH:MM")}</TableCell>
      <TableCell>
        <IconButton>
          <MoreHorizIcon
            onMouseLeave={handlePopoverClose}
            onClick={handlePopoverOpen}
          />
        </IconButton>
        <Popover
          id="mouse-over-popover"
          className={classes.popover}
          classes={{
            paper: classes.paper,
          }}
          open={open}
          anchorEl={anchorEl}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'left',
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'left',
          }}
          onClose={handlePopoverClose}
          disableRestoreFocus
        >
          <div className={classes.package}>
            {isLoading && <CircularProgress
              size={20}
              thickness={4}
              color={'secondary'} />}
            {packageLIst.map(data => <Typography>{data || '-'}</Typography>)}
          </div>
        </Popover>
      </TableCell>
      <TableCell
        align="right"
      >
        <IconButton onClick={familyClickOpen}>
          <AccessibilityNewIcon />
        </IconButton>
      </TableCell>
      <TableCell align="right">
        
        <Link to={`/customers/${customerCode}`}>
          <IconButton>
            <InfoIcon />
          </IconButton>
        </Link>
      </TableCell>
      <TableCell align="right">
        {channel == 'HIS' &&
          <Link to={`/sendToMunas/${customerCode}`}>
            <IconButton>
              <InfoOutlined />
            </IconButton>
          </Link>
        }
      </TableCell>
      <TableCell align="right">
        <Link to={`/customers/${customerCode}/edit`}>
          <Button small>Edit</Button>
        </Link>
      </TableCell>
    </TableRow>
  );
};

export default CustomerRow;
