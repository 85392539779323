import munasInstance from 'dataService/munasInstance';

export const testResultSubmit = async payload => {
  console.log(payload);

  const data = {
    ...payload,
  };

  console.log(data);

  try {
    const res = await munasInstance.post('/muna/test_result_submit', data);
    return res.data;
  } catch (error) {
    console.log(error);
    return error.response.errors;
  }
};
