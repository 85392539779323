import axiosInstance from './axiosInstance';

export const editUser = async (
  { credentialId, id, createdAt, updatedAt, ...userInfo },
  userEmail,
) => {
  const processedUserInfo = {
    ...userInfo,
    status: userInfo.status ? 'Active' : 'Inactive',
  };
  delete processedUserInfo.profile;
  try {
    const res = await axiosInstance.put(
      `/admin/internalUser/${userEmail}`,
      processedUserInfo,
    );
    return res.data;
  } catch (error) {
    return error.response.data;
  }
};
