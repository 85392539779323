import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core';
import { AddAlert } from '@material-ui/icons';
import { Formik } from 'formik';
import * as Yup from 'yup';
import Card from '../../../components/Card/Card';
import ReEnterPassword from './ReEnterPassword';
import Snackbar from '../../../components/Snackbar/Snackbar';
import { updatePassword } from '../../../dataService/updatePassword';
import Logo from '../Logo';
import CircularProgress from '@material-ui/core/CircularProgress';

const ResetPassword = (props) => {
  
  let queryParamValues = new URLSearchParams(props.location.search);
  const classes = useStyles();
  const [isLoaderSpinning, setIsLoaderSpinning] = useState(false);
  const initialValues = {
    password: '',
    confirmPassword: '',
  };

  const validationSchema = Yup.object({
    password: Yup.string('******')
      .required('Password is required!')
      .min(8, 'Password should me at least 8 char!'),
    confirmPassword: Yup.string('******')
      .required('Confirm Password is required!')
      .test('passwords-match', 'Passwords must match', function(value) {
        return this.parent.password === value;
      }),
  });

  const [snackbarConfig, setSnackbarConfig] = useState({
    open: false,
    message: '',
    color: 'success',
  });

  const snackBarClose = () => {
    setSnackbarConfig({ ...snackbarConfig, open: false });
  };

  const resetPasswordFormSubmit = async ({ password, confirmPassword }) => {  
    setIsLoaderSpinning(true);
    const res = await updatePassword({
      id: queryParamValues.get("userId"),
      token: queryParamValues.get("token"),
      password: password
    });

    const { status, message } = res;

    setSnackbarConfig({
      open: true,
      message: status === 'success'
          ? 'Password Changed successfully, redirecting...'
          : 'Login failed please check the credentials.',
      icon: AddAlert,
      color: status === 'success' ? 'success' : 'danger',
    });

    if (res.status === 'success') {
      setTimeout(() => {
        window.location.href = `${window.location.origin}/signin`;
      }, 1000);
      setIsLoaderSpinning(true);
    }

    if (res.status == '400' || res.status === 'error') {
      setIsLoaderSpinning(false);
    }
    console.log(res.status);
  };

  return (
    <div className={classes.centerItems}>
      <div className={classes.loginFormWrapper}>
        <img src="./praava_logo.png" />
        <Card>
          {isLoaderSpinning ? (
            <CircularProgress
              style={{
                position: 'absolute',
                left: '45%',
                top: '65%',
                color: '#800054',
              }}
            />
          ) : null}
          <Formik
            initialValues={initialValues}
            validationSchema={validationSchema}
            onSubmit={resetPasswordFormSubmit}
          >
            {formik => <ReEnterPassword formik={formik} />}
          </Formik>
        </Card>
        <Snackbar
          {...snackbarConfig}
          icon={AddAlert}
          close
          closeNotification={snackBarClose}
          place="bl"
        />
      </div>
    </div>
  );
};

const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
    '& > * + *': {
      marginLeft: theme.spacing(2),
    },
  },
  centerItems: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    height: '100vh',
  },
  loginFormWrapper: {
    maxWidth: '400px',
    minWidth: '400px',
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'column',
  },
}));

export default ResetPassword;
