import React, { useState } from 'react';
import { Formik } from 'formik';
import * as Yup from 'yup';
import { makeStyles } from '@material-ui/core';
import { AddAlert } from '@material-ui/icons';
import { yupSchemaObject, initialValues } from './formSchema';
import GridItem from '../../components/Grid/GridItem';
import CardBody from '../../components/Card/CardBody';
import GridContainer from '../../components/Grid/GridContainer';
import Card from '../../components/Card/Card';
import Snackbar from '../../components/Snackbar/Snackbar';
import GeneralInputFormBlock from './FormParts/GeneralInputFormBlock';
import ContentFormBlock from './FormParts/ContentFormBlock';
import Header from '../../components/Header';
import Button from '../../components/CustomButtons/Button';
import { addNewFeature } from '../../dataService/addNewFeature';

const AddNewFeature = () => {
  const classes = useStyles();
  const validationSchema = Yup.object(yupSchemaObject);

  const [submitDisable, setSubmitDisable] = useState(false);
  const [snackbarConfig, setSnackbarConfig] = useState({
    open: false,
    message: '',
    color: 'success',
  });

  const snackBarClose = () => {
    setSnackbarConfig({ ...snackbarConfig, open: false });
  };

  const onSubmit = async (values, { resetForm }) => {
    setSubmitDisable(true);
    
    const { status, message } = await addNewFeature(values);

    if (status === 'success') {
      setSubmitDisable(false);
      setSnackbarConfig({
        open: true,
        message: 'Feature creation successful!',
        icon: AddAlert,
        color: 'success',
      });

      resetForm();
    } else {
      setSubmitDisable(false);
      setSnackbarConfig({
        open: true,
        message,
        icon: AddAlert,
        color: 'danger',
      });
    }
  };

  return (
    <>

      <Card style={{ backgroundColor: '#800054' }}>
        <CardBody>
          <h3 style={{ color: '#ffffff' }}>
            <b>Add New Features</b>
          </h3>
        </CardBody>
      </Card>
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={onSubmit}
      >
        {formik => (
          <form
            className={classes.pageTopSpacing}
            onSubmit={formik.handleSubmit}
          >
            <GridContainer>
              <GridItem xs={12}>
                <GeneralInputFormBlock formik={formik} />

                <div className={classes.blockSpacing} />
                <ContentFormBlock formik={formik} />

              
                <GridContainer>
                  <GridItem>
                    <Button
                      disabled={
                        submitDisable ||
                        (formik.isValid && !formik.dirty) ||
                        !formik.isValid
                      }
                      color="success"
                      type="submit"
                    >
                      Create feature
                    </Button>
                  </GridItem>
                </GridContainer>
              
              </GridItem>
            </GridContainer>
          </form>
        )}
      </Formik>
      <Snackbar
        {...snackbarConfig}
        icon={AddAlert}
        close
        closeNotification={snackBarClose}
        place="bl"
      />
    </>
  );
};

const useStyles = makeStyles({
  pageTopSpacing: {
    marginTop: '30px',
  },
  blockSpacing: {
    marginTop: '60px',
  },
});

export default AddNewFeature;
