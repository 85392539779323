import axiosInstance from './axiosInstance';

export const getServiceByUpi = async upi => {
  try {
    const res = await axiosInstance.get(`/services/${upi}`, {
      data: null,
    });
    return res.data;
  } catch (error) {
    return error.response.data;
  }
};
