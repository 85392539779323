import axiosInstance from './axiosInstance';

export const addNewUser = async unserInfo => {
  delete unserInfo.profile;
  try {
    const res = await axiosInstance.post('/admin/internalUser', unserInfo);
    return res.data;
  } catch (error) {
    return error.response.data;
  }
};
