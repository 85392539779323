import axiosInstance from './axiosInstance';

export const getDiscountInfo = async promoCode => {
  try {
    const res = await axiosInstance.get(`/discounts/${promoCode}`, {
      data: null,
    });
    return res.data;
  } catch (error) {
    return error.response.data;
  }
};
