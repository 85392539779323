import React, { useState, useEffect } from 'react';
import {
  makeStyles,
  useTheme,
  Grid,
  FormControl,
  Select,
  MenuItem,
  Input,
  InputLabel,
  TextField,
  Checkbox,
  Chip,
} from '@material-ui/core';
import Card from '../../components/Card/Card';
import CardBody from '../../components/Card/CardBody';
import { applications, activityChannels } from '../../../src/config/env.json'
import moment from 'moment'

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};
let queue;

const Filter = ({ searchTerms, setSearchTerms, activityCodeList }) => {
  const [channel, setChannel] = useState('');
  const [application, setApplication] = useState('');
  const [mobile, setMobile] = useState('');
  const [selectedStartDate, setSelectedStartDate] = React.useState('')
  const [selectedEndDate, setSelecteEndDate] = React.useState('')

  let date = new Date()
  date = moment(date).format("YYYY-MM-DDTHH:MM")

  let prevDate = moment().subtract(1, 'days').format("YYYY-MM-DDTHH:MM");

  const classes = useStyles();
  const theme = useTheme();
  const [activityCode, setActivityCode] = React.useState([]);

  const handleChange = (event) => {
    setActivityCode(event.target.value);
  };



  useEffect(() => {
    const updatedSearchTerms = {
      ...searchTerms,
    }
    if (selectedStartDate.length > 0) {
      updatedSearchTerms['startDate'] = selectedStartDate
    } else {
      delete updatedSearchTerms['startDate'];
    }
    setSearchTerms(updatedSearchTerms);
  }, [selectedStartDate])

  useEffect(() => {
    const updatedSearchTerms = {
      ...searchTerms,
    }
    if (selectedEndDate.length > 0) {
      updatedSearchTerms['endDate'] = selectedEndDate
    } else {
      delete updatedSearchTerms['endDate'];
    }
    setSearchTerms(updatedSearchTerms);
  }, [selectedEndDate])

  useEffect(() => {
    const updatedSearchTerms = {
      ...searchTerms,
    };
    if (activityCode.length > 0) {
      updatedSearchTerms['activityCode'] = activityCode.join('-');
    } else {
      delete updatedSearchTerms['activityCode'];
    }
    setSearchTerms(updatedSearchTerms);
  }, [activityCode]);

  useEffect(() => {
    const updatedSearchTerms = {
      ...searchTerms,
    };

    if (!mobile) {
      delete updatedSearchTerms['mobile'];
      setSearchTerms(updatedSearchTerms);
    }

    if (mobile.length > 2) {
      updatedSearchTerms['mobile'] = mobile;
      clearTimeout(queue);
      queue = setTimeout(() => {
        setSearchTerms(updatedSearchTerms);
      }, 500);
    }
  }, [mobile]);

  useEffect(() => {
    const updatedSearchTerms = {
      ...searchTerms,
    };
    if (application.length) {
      updatedSearchTerms['application'] = application;
    } else {
      delete updatedSearchTerms['application'];
    }
    setSearchTerms(updatedSearchTerms);
  }, [application]);

  useEffect(() => {
    const updatedSearchTerms = {
      ...searchTerms,
    };
    if (channel.length) {
      updatedSearchTerms['channel'] = channel;
    } else {
      delete updatedSearchTerms['channel'];
    }
    setSearchTerms(updatedSearchTerms);
  }, [channel]);

  return (
    <Card>
      <CardBody>
        <Grid container spacing={2}>
          <Grid item md={4}>
            <FormControl className={classes.fullWidth}>
              <InputLabel id="demo-mutiple-chip-label">Activity Code</InputLabel>
              <Select
                labelId="demo-mutiple-chip-label"
                id="demo-mutiple-chip"
                multiple
                value={activityCode}
                onChange={handleChange}
                input={<Input id="select-multiple-chip" />}
                renderValue={(selected) => (
                  <div className={classes.chips}>
                    {selected.map((value) => (
                      <Chip key={value} label={value} className={classes.chip} />
                    ))}
                  </div>
                )}
                MenuProps={MenuProps}
              >
                {activityCodeList.map((data) => (
                  <MenuItem key={data.id} value={data.value}>
                    <Checkbox checked={activityCode.indexOf(data.value) > -1} />
                    {data.value}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>
          <Grid md={2} item>
            <TextField
              value={mobile}
              onChange={ev => setMobile(ev.target.value)}
              className={classes.fullWidth}
              label="Mobile"
            />
          </Grid>
          <Grid md={2} item>
            <FormControl className={classes.fullWidth}>
              <InputLabel>Application</InputLabel>
              <Select
                value={application}
                onChange={ev => setApplication(ev.target.value)}
              >
                <MenuItem value="">Select Validity Type</MenuItem>
                {applications
                  .map(data => (
                    <MenuItem key={data.id} value={data.value}>
                      {data.label}
                    </MenuItem>
                  ))}
              </Select>
            </FormControl>
          </Grid>
          <Grid md={2} item>
            <FormControl className={classes.fullWidth}>
              <InputLabel>Channel</InputLabel>
              <Select
                value={channel}
                onChange={ev => setChannel(ev.target.value)}
              >
                <MenuItem value="">Select Validity Type</MenuItem>
                {activityChannels
                  .map(data => (
                    <MenuItem key={data.id} value={data.value}>
                      {data.label}
                    </MenuItem>
                  ))}
              </Select>
            </FormControl>
          </Grid>
        </Grid>
        <Grid container spacing={2}>
          <Grid md={4} item>
            <form className={classes.container} noValidate>
              <TextField
                id="datetime-local-start"
                label="Search Start With"
                type="datetime-local"
                defaultValue={prevDate}
                className={classes.textField}
                onChange={val => {
                  setSelectedStartDate(val.target.value)
                }}
                InputLabelProps={{
                  shrink: true,
                }}
                size={'small'}
                variant={'outlined'}
                margin={'dense'}
              />
            </form>
          </Grid>
          <Grid md={4} item>
            <form className={classes.container} noValidate>
              <TextField
                id="datetime-local-end"
                label="Search End With"
                type="datetime-local"
                defaultValue={date}
                className={classes.textField}
                onChange={val => {
                  setSelecteEndDate(val.target.value)
                }}
                InputLabelProps={{
                  shrink: true,
                }}
                size={'small'}
                variant={'outlined'}
                margin={'dense'}
              />
            </form>
          </Grid>
        </Grid>
      </CardBody>
    </Card>
  );
};

const useStyles = makeStyles((theme) => ({
  topSpacing: {
    marginTop: '30px',
  },
  filterTitle: {
    margin: '0 0 15px 0',
  },
  marginNone: {
    margin: 0,
  },
  fullWidth: {
    width: '100%',
  },
  formGroup: {
    '& label': {
      margin: 0,
    },
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
    maxWidth: 300,
  },
  chips: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  chip: {
    margin: 0,
    height: '23px',
  },
  noLabel: {
    marginTop: theme.spacing(3),
  },
  container: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  textField: {
    marginLeft: theme.spacing(0),
    marginRight: theme.spacing(0),
    width: 250,
  },
  customDate: {
    height: '10px'
  }
}));

export default Filter;
