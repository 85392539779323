import React from 'react';
import { Switch, Route } from 'react-router-dom';
import { makeStyles } from '@material-ui/core';
import Sidebar from './components/Sidebar/Sidebar';
import styles from './assets/jss/material-dashboard-react/layouts/adminStyle';
import bgImage from './assets/img/sidebar-8.jpg';

import AllCustomers from './pages/AllCustomers';
import Customers from './pages/Customers';
import AllDiscounts from './pages/AllDiscounts';
import Activities from './pages/Activities';
import ActivityDetails from './pages/ActivityDetails';
import AddNewDiscount from './pages/AddEditDiscount/Add';
import AddNewActivity from './pages/AddEditActivity/Add';
import CustomerDetails from './pages/CustomerDetails';
import DiscountDetails from './pages/DiscountDetails';
import EditDiscount from './pages/AddEditDiscount/Edit';
import EditActivity from './pages/AddEditActivity/Edit';
import AddNewUser from './pages/AddEditUser/add';
import AllUsers from './pages/AllUsers';
import EditUser from './pages/AddEditUser/edit';
import AllFeatures from './pages/AllFeatures';
import AddNewFeature from './pages/AddEditFeature/Add';
import EditFeature from './pages/AddEditFeature/Edit';
import AllPackages from './pages/AllPackages';
import AddEditPackages from './pages/AddEditPackages/Add';
import EditPackages from './pages/AddEditPackages/Edit';
import PackageDetails from './pages/PackagesDetails';
import FeatureDetails from './pages/FeatureDetails';
import AddToMunas from './pages/SendMunas/AddToMunas';
import AddCSV from 'pages/AddByCSV/AddCSV';
import AllFamilyMembers from './pages/AllFamilyMembers';
import UpdateStaticData from 'pages/UpdateStaticData/UpdateStaticData';
import CustomerPublicDetails from 'pages/CustomerPublicDetails';
import AddEditCustomer from 'pages/AddEditCustomer/Add'
import EditCustomer from 'pages/AddEditCustomer/Edit';

const useStyles = makeStyles(styles);

const Routes = props => {
  const classes = useStyles();
  const [mobileOpen, setMobileOpen] = React.useState(false);
  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };
  const mainPanel = React.createRef();
  return (
    <div className={classes.wrapper}>
      <Sidebar
        image={bgImage}
        handleDrawerToggle={handleDrawerToggle}
        open={mobileOpen}
        color="red"
      />
      <div className={classes.mainPanel} ref={mainPanel}>
        <div className={classes.content}>
          <div className={classes.container}>
            <Switch>
              {/* eslint-disable */}
              <Route exact {...props} component={AllCustomers} path="/" />

              <Route
                exact
                {...props}
                component={AllCustomers}
                path="/customers"
              />
              <Route
                exact
                {...props}
                component={AddEditCustomer}
                path="/customers/add-new"
              />
                <Route
                exact
                {...props}
                component={EditCustomer}
                path="/customers/:customerCode/edit"
              />
              
              <Route
                exact
                {...props}
                component={AllFamilyMembers}
                path="/family-members"
              />
              <Route
                exact
                {...props}
                component={AllFeatures}
                path="/features"
              />
              <Route
                exact
                {...props}
                component={AddNewFeature}
                path="/features/new"
              />
              <Route
                exact
                {...props}
                component={EditFeature}
                path="/features/:featureCode/edit"
              />
              <Route
                exact
                {...props}
                component={FeatureDetails}
                path="/features/:featureCode"
              />
              <Route
                exact
                {...props}
                component={AllPackages}
                path="/packages"
              />
              <Route
                exact
                {...props}
                component={AddEditPackages}
                path="/packages/new"
              />
              <Route
                exact
                {...props}
                component={EditPackages}
                path="/packages/:packageCode/edit"
              />
              <Route
                exact
                {...props}
                component={PackageDetails}
                path="/packages/:packageCode"
              />
              <Route
                exact
                {...props}
                component={AddNewUser}
                path="/manage/users/new"
              />
              <Route
                exact
                {...props}
                component={EditUser}
                path="/users/edit/:userEmail"
              />
              <Route
                exact
                {...props}
                component={AddCSV}
                path="/manage/add-by-csv"
              />
              <Route
                exact
                {...props}
                component={UpdateStaticData}
                path="/manage/update-static-data"
              />
              <Route
                exact
                {...props}
                component={AllDiscounts}
                path="/manage/discounts"
              />
              <Route
                exact
                {...props}
                component={Activities}
                path="/manage/activities"
              />
              <Route
                exact
                {...props}
                component={ActivityDetails}
                path="/activitydetails"
              />
              <Route
                exact
                {...props}
                component={CustomerDetails}
                path="/customers/:customerCode"
              />
              <Route
                exact
                {...props}
                component={AddNewDiscount}
                path="/discounts/new"
              />
              <Route
                exact
                {...props}
                component={EditDiscount}
                path="/manage/discounts/:promoCode/edit"
              />
              <Route
                exact
                {...props}
                component={DiscountDetails}
                path="/discounts/:promoCode"
              />
              <Route
                exact
                {...props}
                component={AddToMunas}
                path="/sendToMunas/:customerCode"
              />
              <Route
                exact
                {...props}
                component={AddNewActivity}
                path="/activities/new"
              />
              <Route
                exact
                {...props}
                component={EditActivity}
                path="/manage/activities/:activityCode/edit"
              />
              <Route exact {...props} component={AllUsers} path="/users/all" />
            </Switch>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Routes;
