import axiosInstance from '../axiosInstance';

export const getAccountIdentifierByPassport = async passport => {
  try {
    const res = await axiosInstance.get(
      `/muna/get_account_identifier_by_email_or_passport?filterValue=${passport}&filterType=passport`,
      {
        data: null,
      },
    );
    return res.data;
  } catch (error) {
    return error.response.data;
  }
};
