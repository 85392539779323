import React from 'react';
import { TableRow, TableCell } from '@material-ui/core';
import moment from 'moment';

const ActivityRow = ({
          id,
          activityCode,
          mobile,
          application,
          channel,
          createdAt,
}) => {
   createdAt = moment(createdAt).format("YYYY-MM-DD HH:MM")
  return (
    <TableRow>
      <TableCell>{createdAt || '-'}</TableCell>
      <TableCell>{activityCode}</TableCell>
      <TableCell>{mobile}</TableCell>
      <TableCell>{application || '-'}</TableCell>
      <TableCell>{channel || '-'}</TableCell>
    </TableRow>
  );
};

export default ActivityRow;
