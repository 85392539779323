import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import { CKEditor } from '@ckeditor/ckeditor5-react';
import { makeStyles } from '@material-ui/core';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import Select from '@material-ui/core/Select';
import TextField from '@material-ui/core/TextField';
import { AddAlert } from '@material-ui/icons';
import { getEmailContent, updateEmailContent } from 'dataService/staticData';
import React, { useCallback, useEffect, useState } from 'react';
import 'react-quill/dist/quill.snow.css';
import Button from '../../../components/CustomButtons/Button';
import GridContainer from '../../../components/Grid/GridContainer';
import GridItem from '../../../components/Grid/GridItem';
import Snackbar from '../../../components/Snackbar/Snackbar';

const TabContent = ({ type }) => {
  const classes = useStyles();

  const [emailBody, setEmailBody] = useState('');
  const [emailSubject, setEmailSubject] = useState('');
  const [editor, setEditor] = useState();
  const [properties, setProperties] = useState([
    'firstName',
    'lastName',
    'customerCode',
    'email',
    'upi',
    'password',
    'dob',
    'mobile',
    'password',
    'name',
    'service',
    'provider', 
    'appointmentDate',
    'appointmentTime',
    'appointmentLink',
    'consultationFee'
  ]);
  const [selectedProperty, setSelectedProperty] = useState('NONE');

  const [submitDisable, setSubmitDisable] = useState(false);
  const [snackbarConfig, setSnackbarConfig] = useState({
    open: false,
    message: '',
    color: 'success',
  });

  const getData = useCallback(async () => {
    const { status, data, message } = await getEmailContent(type);

    if (status === 'success') {
      setEmailBody(data.emailBody);
      setEmailSubject(data.emailSubject);
    } else {
      setSnackbarConfig({
        open: true,
        message,
        icon: AddAlert,
        color: 'danger',
      });
    }
  }, [type]);

  useEffect(() => {
    if (type) {
      getData();
    }
  }, [type]);

  const handleEmailContentChange = e => {
    setEmailBody(e);
  };

  const handlePropertyChange = e => {
    setSelectedProperty(e.target.value);
    handleEmailContentChange(e.target.value);

    if (e.target.value !== 'NONE') {
      editor.model.change(writer => {
        writer.insertText(
          '&&' + e.target.value + '&&',
          editor.model.document.selection.getFirstPosition(),
        );
      });
    }
  };

  const handleEmailSubjectChange = e => {
    setEmailSubject(e.target.value);
  };

  const snackBarClose = () => {
    setSnackbarConfig({ ...snackbarConfig, open: false });
  };

  const onSubmit = async () => {
    setSubmitDisable(true);
    const { status, message } = await updateEmailContent({
      emailBody,
      emailSubject,
      type: type,
    });

    if (status === 'success') {
      setSubmitDisable(false);
      setSnackbarConfig({
        open: true,
        message: 'Email body updated',
        icon: AddAlert,
        color: 'success',
      });
    } else {
      setSubmitDisable(false);
      setSnackbarConfig({
        open: true,
        message,
        icon: AddAlert,
        color: 'danger',
      });
    }
  };

  return (
    <>
      <div className={classes.container}>
        <GridContainer>
          <GridItem style={{ marginTop: 24 }} xs={6}>
            <FormControl style={{ width: '100%' }} variant="outlined">
              <InputLabel htmlFor="outlined-age-native-simple">
                Select Property
              </InputLabel>
              <Select
                native
                displayEmpty
                value={selectedProperty}
                onChange={handlePropertyChange}
                label="Select Property"
                inputProps={{
                  name: 'Select Property',
                  id: 'outlined-age-native-simple',
                }}
              >
                <option value="NONE">NONE</option>
                {properties.map((p, i) => (
                  <option key={i} value={p}>
                    {p}
                  </option>
                ))}
              </Select>
            </FormControl>
          </GridItem>

          <GridItem xs={6}></GridItem>

          <GridItem style={{ marginTop: 24 }} xs={6}>
            <TextField
              label="Email Subject"
              type="text"
              InputLabelProps={{
                shrink: true,
              }}
              variant="outlined"
              value={emailSubject}
              onChange={handleEmailSubjectChange}
              style={{ width: '100%' }}
            />
          </GridItem>

          <GridItem style={{ marginTop: 24 }} xs={12}>
            <CKEditor
              editor={ClassicEditor}
              data={emailBody}
              onReady={editor => {
                setEditor(editor);
              }}
              onChange={(event, editor) => {
                const data = editor.getData();
                handleEmailContentChange(data);
              }}
            />
          </GridItem>

          <GridItem style={{ marginTop: 24 }} xs={12}>
            <GridContainer>
              <GridItem>
                <Button onClick={onSubmit} color="success" type="button">
                  Submit
                </Button>
              </GridItem>
            </GridContainer>
          </GridItem>
        </GridContainer>
      </div>
      <Snackbar
        {...snackbarConfig}
        icon={AddAlert}
        close
        closeNotification={snackBarClose}
        place="bl"
      />
    </>
  );
};

const useStyles = makeStyles({
  pageTopSpacing: {
    marginTop: '30px',
  },
  blockSpacing: {
    marginTop: '60px',
  },
  container: {
    padding: '0px',
  },
});

export default TabContent;
