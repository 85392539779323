import React, { useState, useEffect } from 'react';
import {
  makeStyles,
  Grid,
  FormControlLabel,
  FormControl,
  FormLabel,
  RadioGroup,
  Radio,
  Select,
  MenuItem,
  InputLabel,
  TextField,
} from '@material-ui/core';
import Card from '../../components/Card/Card';
import CardBody from '../../components/Card/CardBody';
import { search } from '../../dataService/search';
import { getPackageToDropdown } from '../../dataService/getPackageToDropdown';

let queue;
const Filter = ({ searchTerms, setSearchTerms }) => {
  const classes = useStyles();
  const [userStatus, setUserStatus] = useState('Active');
  const [packageCode, setPackageCode] = useState('');
  const [customerCode, setCustomerCode] = useState('');
  const [customerUPI, setCustomerUPI] = useState('');
  const [firstName, setCustomerFirstName] = useState('');
  const [packageCodes, setPackageCodes] = useState([]);
  const [allPackages, setallPackages] = useState([]);
  const [email, setEmail] = useState('');
  const [mobile, setMobile] = useState('');
  const SearchPackages = async () => {
    const res = await search({
      _entity: 'Package',
      packageCode: 'PKG',
      _page: 1,
      _select: 'packageCode,status',
    });
    setPackageCodes(res.data);



  };
  const getallPackages = async () => {
    const res = await getPackageToDropdown({
      _entity: 'Package',
      packageCode: 'PKG',
     
    });
    setallPackages(res.data);





  };

  useEffect(() => {
    SearchPackages();
    getallPackages();

  }, []);

  useEffect(() => {
    const updatedSearchTerms = {
      ...searchTerms,
      ...{ 'customer.status': userStatus },
      ...{ _page: 1 },
    };

    if (!customerCode) {
      delete updatedSearchTerms['customer.customerCode'];
      setSearchTerms(updatedSearchTerms);
    }

    if (customerCode.length > 5) {
      updatedSearchTerms['customer.customerCode'] = customerCode;
      clearTimeout(queue);
      queue = setTimeout(() => {
        setSearchTerms(updatedSearchTerms);
      }, 500);
    }
  }, [customerCode]);

  useEffect(() => {
    const updatedSearchTerms = {
      ...searchTerms,
      ...{ 'customer.status': userStatus },
      ...{ _page: 1 },
    };

    if (!customerUPI) {
      delete updatedSearchTerms['customer.upi'];
      setSearchTerms(updatedSearchTerms);
    }

    if (customerUPI.length > 5) {
      updatedSearchTerms['customer.upi'] = customerUPI;
      clearTimeout(queue);
      queue = setTimeout(() => {
        setSearchTerms(updatedSearchTerms);
      }, 500);
    }
  }, [customerUPI]);

  useEffect(() => {
    const updatedSearchTerms = {
      ...searchTerms,
      ...{ 'customer.status': userStatus },
      ...{ _page: 1 },
    };

    if (!email) {
      delete updatedSearchTerms['credential.email'];
      setSearchTerms(updatedSearchTerms);
    }

    if (email.length > 2) {
      updatedSearchTerms['credential.email'] = email;
      clearTimeout(queue);
      queue = setTimeout(() => {
        setSearchTerms(updatedSearchTerms);
      }, 500);
    }
  }, [email]);

  useEffect(() => {
    const updatedSearchTerms = {
      ...searchTerms,
      ...{ 'customer.status': userStatus },
      ...{ _page: 1 },
    };

    if (!mobile) {
      delete updatedSearchTerms['credential.mobile'];
      setSearchTerms(updatedSearchTerms);
    }

    if (mobile.length > 2) {
      updatedSearchTerms['credential.mobile'] = mobile;
      clearTimeout(queue);
      queue = setTimeout(() => {
        setSearchTerms(updatedSearchTerms);
      }, 500);
    }
  }, [mobile]);

  useEffect(() => {
    const updatedSearchTerms = {
      ...searchTerms,
      ...{ 'customer.status': userStatus },
      ...{ _page: 1 },
    };

    if (!firstName) {
      delete updatedSearchTerms['profile.firstName'];
      setSearchTerms(updatedSearchTerms);
    }

    if (firstName.length > 2) {
      updatedSearchTerms['profile.firstName'] = firstName;
      clearTimeout(queue);
      queue = setTimeout(() => {
        setSearchTerms(updatedSearchTerms);
      }, 500);
    }
  }, [firstName]);

  useEffect(() => {
    const updatedSearchTerms = {
      ...searchTerms,
      ...{ 'customer.status': userStatus },
      ...{ _page: 1 },
    };

    if (packageCode.length > 3) {
      updatedSearchTerms['package.packageCode'] = packageCode;
    } else {
      delete updatedSearchTerms['package.packageCode'];
    }

    setSearchTerms(updatedSearchTerms);
  }, [userStatus, packageCode]);

  return (
    <Card>
      <CardBody>
        <h4 className={classes.filterTitle}>Search Customer Details</h4>
        <Grid container spacing={2}>
          <Grid md={3} item>
            <TextField
              value={customerUPI}
              onChange={ev => setCustomerUPI(ev.target.value)}
              className={classes.fullWidth}
              label="Customer UPI"
              placeholder="PHBL0000000000"
              variant="outlined"
            />
          </Grid>
          <Grid md={3} item>
            <TextField
              value={email}
              onChange={ev => setEmail(ev.target.value)}
              className={classes.fullWidth}
              label="Email"
              variant="outlined"
            />
          </Grid>
          <Grid md={2} item>
            <TextField
              value={firstName}
              onChange={ev => setCustomerFirstName(ev.target.value)}
              className={classes.fullWidth}
              label="First Name"
              variant="outlined"
            />
          </Grid>
          <Grid md={2} item>
            <TextField
              value={mobile}
              onChange={ev => setMobile(ev.target.value)}
              className={classes.fullWidth}
              label="Mobile"
              variant="outlined"
            />
          </Grid>

          <Grid md={2} item>

            <FormControl className={classes.fullWidth} variant="outlined">
              <InputLabel>Packages</InputLabel>
              <Select
                value={packageCode}
                onChange={ev => setPackageCode(ev.target.value)}

              >
                <MenuItem>PackageCode</MenuItem>
                {allPackages.map(packageCode => (
                  <MenuItem key={packageCode.id} value={packageCode.packageCode}>
                    {packageCode.packageTitle}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>
        </Grid>
      </CardBody>
    </Card>
  );
};

const useStyles = makeStyles({
  topSpacing: {
    marginTop: '30px',
  },
  filterTitle: {
    margin: '0 0 15px 0',
  },
  marginNone: {
    margin: 0,
  },
  formControl: {
    '& legend': {
      margin: '0 200px 0 0',
    },
  },
  fullWidth: {
    width: '100%',
  },
  formGroup: {
    '& label': {
      margin: 0,
    },
  },
});

export default Filter;
