import axiosInstance from './axiosInstance';

export const getFeatureDetails = async (featureCode, isCompact) => {
  try {
    const res = await axiosInstance.get(
      `/features/${featureCode}`,
      {
        data: null,
      },
    );
    return res.data;
  } catch (error) {
    return error.response.data;
  }
};
