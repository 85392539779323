import axiosInstance from './axiosInstance';

export const getUser = async userEmail => {
  try {
    const res = await axiosInstance.get(`/admin/internalUser/${userEmail}`, {
      data: null,
    });
    return res.data;
  } catch (error) {
    return error.response.data;
  }
};
