import axiosInstance from './axiosInstance';

export const getCustomerInfo = async customerCode => {
  try {
    const res = await axiosInstance.get(`/customers/${customerCode}`, {
      data: null,
    });
    return res.data;
  } catch (error) {
    return error.response.data;
  }
};

export const getCustomerPublicInfo = async customerCode => {
  const data = {
    customerCode,
  };

  try {
    const res = await axiosInstance.post('/customers/publicData', data);
    return res.data;
  } catch (error) {
    return error.response.data;
  }
};
