import {
  Card,
  makeStyles,
  TableCell,
  withStyles,
  Grid,
  Modal,
} from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import CardBody from '../Card/CardBody';
import GridContainer from '../Grid/GridContainer';
import GridItem from '../Grid/GridItem';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import { updateDocument } from 'dataService/updateDocument';
import { AddAlert } from '@material-ui/icons';
import Snackbar from '../Snackbar/Snackbar';
import { uploadDocument } from 'dataService/updateDocument';
import { createMuiTheme, MuiThemeProvider } from '@material-ui/core/styles';

function getModalStyle() {
  const top = 50;
  const left = 50;

  return {
    top: `${top}%`,
    left: `${left}%`,
    transform: `translate(-${top}%, -${left}%)`,
  };
}

const theme = createMuiTheme({
  overrides: {
    MuiTableCell: {
      root: {
        paddingTop: 4,
        paddingBottom: 4,
        paddingRight: 5,
      },
    },
  },
});

const useStyles = makeStyles({
  paper: {
    position: 'absolute',
    width: 400,
    backgroundColor: theme.palette.background.paper,
    border: '2px solid #000',
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
  },
  topSpacing: {
    marginTop: '30px',
  },
  subTitle: {
    marginTop: 0,
  },
  spacing: {
    height: '30px',
  },
  flexVCenter: {
    display: 'flex',
    alignItems: 'center',
  },
});

const CustomerDocuments = ({
  customerInfo: {
    customerCode,
    profile: { nid, nidNumber, passport, passportNumber, ticket, ticketNumber },
  },
}) => {
  const classes = useStyles();

  const [_nid, setNid] = useState(nid);
  const [_nidNumber, setNidNumber] = useState(nidNumber);
  const [_passport, setPassport] = useState(passport);
  const [_passportNumber, setPassportNumber] = useState(passportNumber);
  const [_ticket, setTicket] = useState(ticket);
  const [_ticketNumber, setTicketNumber] = useState(ticketNumber);

  const [selectedImage, setSelectedImage] = useState('');

  const [snackbarConfig, setSnackbarConfig] = useState({
    open: false,
    message: '',
    color: 'success',
  });

  const [openModal, setOpenModal] = React.useState(false);
  const [modalStyle] = React.useState(getModalStyle);

  const handleOpenModal = () => {
    setOpenModal(true);
  };

  const handleCloseModal = () => {
    setOpenModal(false);
  };

  // const handleNidNumberChange = e => {
  //   setNidNumber(e.target.value);
  // };

  // const handlePassportNumberChange = e => {
  //   setPassportNumber(e.target.value);
  // };

  // const handleTicketNumberChange = e => {
  //   setTicketNumber(e.target.value);
  // };

  const handleNidDocChange = async e => {
    const file = e.target.files[0];
    const formData = new FormData();
    formData.append('uploadFile', file, file.name);

    try {
      const uploadedFile = await uploadDocument(formData);

      if (uploadedFile.status === 'success') {
        setNid(uploadedFile.data.Location);
        save('NID', uploadedFile.data.Location);
      }
    } catch (error) {
      setSnackbarConfig({
        open: true,
        message: error.response.message,
        color: 'error',
      });
    }
  };

  const handlePassportDocChange = async e => {
    const file = e.target.files[0];
    const formData = new FormData();
    formData.append('uploadFile', file, file.name);

    try {
      const uploadedFile = await uploadDocument(formData);

      if (uploadedFile.status === 'success') {
        setPassport(uploadedFile.data.Location);
        save('PASSPORT', uploadedFile.data.Location);
      }
    } catch (error) {
      setSnackbarConfig({
        open: true,
        message: error.response.message,
        color: 'error',
      });
    }
  };

  const handleTiketDocChange = async e => {
    const file = e.target.files[0];
    const formData = new FormData();
    formData.append('uploadFile', file, file.name);

    try {
      const uploadedFile = await uploadDocument(formData);

      if (uploadedFile.status === 'success') {
        setTicket(uploadedFile.data.Location);
        save('TICKET', uploadedFile.data.Location);
      }
    } catch (error) {
      setSnackbarConfig({
        open: true,
        message: error.response.message,
        color: 'error',
      });
    }
  };

  const save = async (type, link) => {
    const data = {
      customerId: customerCode,
    };

    if (type === 'NID') {
      data.documentType = 'NID';
      data.documentNumber = '';
      data.document = link;
    } else if (type === 'TICKET') {
      data.documentType = 'TICKET';
      data.documentNumber = '';
      data.document = link;
    } else if (type === 'PASSPORT') {
      data.documentType = 'PASSPORT';
      data.documentNumber = '';
      data.document = link;
    }
    const updateDocRes = await updateDocument(data);

    if (updateDocRes.status === 'success') {
      setSnackbarConfig({
        open: true,
        message: type + ' SAVED',
        icon: AddAlert,
        color: 'success',
      });
    }
  };

  const viewImage = async type => {
    if (type === 'NID') {
      setSelectedImage(_nid);
    } else if (type === 'TICKET') {
      setSelectedImage(_ticket);
    } else if (type === 'PASSPORT') {
      setSelectedImage(_passport);
    }
    setOpenModal(true);
  };

  const snackBarClose = () => {
    setSnackbarConfig({ ...snackbarConfig, open: false });
  };

  const modalBody = (
    <div style={modalStyle} className={classes.paper}>
      <img src={selectedImage} width="400px" />
      <br />
      <Button
        onClick={() => {
          setOpenModal(false);
        }}
        color="primary"
        type="button"
      >
        CLOSE
      </Button>
    </div>
  );

  return (
    <MuiThemeProvider theme={theme}>
      <Card>
        <CardBody>
          <h4 className={classes.subTitle}>Upload Your Documents</h4>
          <Grid container spacing={2}>
            <Grid item md={4}>
              {/* <TextField
                id="outlined-basic"
                label="NID NUMBER"
                variant="outlined"
                value={_nidNumber}
                onChange={handleNidNumberChange}
              />
              <br />
              <br /> */}
              <div style={{ fontWeight: '400', color: '#820054' }}>
                Upload NID
              </div>
              <br />
              <div>
                <input type="file" onChange={handleNidDocChange} />
              </div>
              <br />
              {/* <Button
                onClick={() => {
                  save('NID');
                }}
                variant="contained"
                color="primary"
                small
              >
                SAVE
              </Button> */}
              <Button
                onClick={() => {
                  viewImage('NID');
                }}
                variant="contained"
                color="primary"
                small
              >
                VIEW
              </Button>
            </Grid>
            <Grid item md={4}>
              {/* <TextField
                id="outlined-basic"
                label="PASSPORT NUMBER"
                variant="outlined"
                value={_passportNumber}
                onChange={handlePassportNumberChange}
              />
              <br />
              <br /> */}
              <div style={{ fontWeight: '400', color: '#820054' }}>
                Upload Pasport
              </div>
              <br />
              <div>
                <input type="file" onChange={handlePassportDocChange} />
              </div>
              <br />
              {/* <Button
                onClick={() => {
                  save('PASSPORT');
                }}
                variant="contained"
                color="primary"
                small
              >
                SAVE
              </Button> */}
              <Button
                onClick={() => {
                  viewImage('PASSPORT');
                }}
                variant="contained"
                color="primary"
                small
              >
                VIEW
              </Button>
            </Grid>
            <Grid item md={4}>
              {/* <TextField
                id="outlined-basic"
                label="TICKET NUMBER"
                variant="outlined"
                value={_ticketNumber}
                onChange={handleTicketNumberChange}
              />
              <br />
              <br /> */}
              <div style={{ fontWeight: '400', color: '#820054' }}>
                Upload Ticket
              </div>
              <br />
              <div>
                <input type="file" onChange={handleTiketDocChange} />
              </div>
              <br />
              {/* <Button
                onClick={() => {
                  save('TICKET');
                }}
                variant="contained"
                color="primary"
                small
              >
                SAVE
              </Button> */}
              <Button
                onClick={() => {
                  viewImage('TICKET');
                }}
                variant="contained"
                color="primary"
                small
              >
                VIEW
              </Button>
            </Grid>
          </Grid>
        </CardBody>
        <Snackbar
          {...snackbarConfig}
          icon={AddAlert}
          close
          closeNotification={snackBarClose}
          place="bl"
        />
        <Modal
          open={openModal}
          onClose={handleCloseModal}
          aria-labelledby="simple-modal-title"
          aria-describedby="simple-modal-description"
        >
          {modalBody}
        </Modal>
      </Card>
    </MuiThemeProvider>
  );
};

export default CustomerDocuments;
