import React, { useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/core';
import { AddAlert } from '@material-ui/icons';
import { Formik } from 'formik';
import GridItem from '../../components/Grid/GridItem';
import GridContainer from '../../components/Grid/GridContainer';
import Card from '../../components/Card/Card';
import Snackbar from '../../components/Snackbar/Snackbar';
import EditUserForm from './form';
import { editUser } from '../../dataService/editUser';
import { getUser } from '../../dataService/getUser';
import Header from '../../components/Header';
import { editFormInitialValues, editValidationSchema } from './formSchema';
import Loading from '../../components/Loading/index';
import CardBody from '../../components/Card/CardBody';

const EditUser = ({
  match: {
    params: { userEmail },
  },
}) => {
  const [submitDisable, setSubmitDisable] = useState(false);
  const [isLoaded, setIsLoaded] = useState(false);
  const [formValues, setFormValues] = useState(editFormInitialValues);
  const [snackbarConfig, setSnackbarConfig] = useState({
    open: false,
    message: '',
    color: 'success',
  });

  const classes = useStyles();

  const getUserDetails = async userEmail => {
    const { data: formValues } = await getUser(userEmail);
    (formValues.profile)?(formValues['target'] = formValues.profile.target):(formValues['target']= 0);
    delete formValues.profile;
    setFormValues({
      ...formValues,
      status: formValues.status === 'Active',
    });
    setIsLoaded(true);
  };

  useEffect(() => {
    getUserDetails(userEmail);
  }, []);

  const snackBarClose = () => {
    setSnackbarConfig({ ...snackbarConfig, open: false });
  };

  const onSubmit = async values => {
    setSubmitDisable(true);
    const { status, message } = await editUser(values, userEmail);
    if (status === 'success') {
      setSubmitDisable(false);
      setSnackbarConfig({
        open: true,
        message,
        icon: AddAlert,
        color: 'success',
      });
      getUserDetails(userEmail);
    } else {
      setSubmitDisable(false);
      setSnackbarConfig({
        open: true,
        message,
        icon: AddAlert,
        color: 'danger',
      });
    }
  };

  if (!isLoaded) {
    return (
      <>
        {/* <Header title="Edit User" /> */}
        <Card style={{ backgroundColor: '#800054' }}>
          <CardBody>
            <h3 style={{ color: '#ffffff' }}>
              <b>Edit User</b>
            </h3>
          </CardBody>
        </Card>
        <Loading
          className={classes.pageTopSpacing}
          boxHeight="calc(100vh - 120px)"
        />
      </>
    );
  }

  return (
    <>
      <Card style={{ backgroundColor: '#800054' }}>
        <CardBody>
          <h3 style={{ color: '#ffffff' }}>
            <b>Edit User</b>
          </h3>
        </CardBody>
      </Card>
      <GridContainer>
        <GridItem xs={12}>
          <Card style={{ position: 'relative', top: '-5%' }}>
            <Formik
              initialValues={formValues}
              validationSchema={editValidationSchema}
              onSubmit={onSubmit}
              enableReinitialize
            >
              {formik => (
                <EditUserForm
                  submitDisable={submitDisable}
                  formik={formik}
                  edit
                />
              )}
            </Formik>
          </Card>
        </GridItem>
      </GridContainer>
      <Snackbar
        {...snackbarConfig}
        icon={AddAlert}
        close
        closeNotification={snackBarClose}
        place="bl"
      />
    </>
  );
};

const useStyles = makeStyles({
  pageTopSpacing: {
    marginTop: '30px',
  },
});

export default EditUser;
