import axiosInstance from './axiosInstance';

export const requestLogin = async requestBody => {
  try {
    const res = await axiosInstance.post('/auth', {
      authType: 'Credential',
      userType: 'InternalUser',
      ...requestBody,
    });

    return res.data;
  } catch (error) {
    return error.response;
  }
};

export const setLoginData = (token, refreshToken, expiresIn) => {
  const currentTimeStamp = Math.floor(Date.now() / 1000);
  const buffer = 60 * 60;
  localStorage.setItem('refreshToken', refreshToken);
  localStorage.setItem('token', token);
  localStorage.setItem('expiresIn', currentTimeStamp + expiresIn - buffer);
};

export const checkLogin = () => {
  const refreshToken = localStorage.getItem('refreshToken');
  const token = localStorage.getItem('token');

  if (refreshToken && refreshToken.length && token && token.length) {
    return true;
  }

  return false;
};

export const logout = () => {
  localStorage.removeItem('refreshToken');
  localStorage.removeItem('token');
  const baseUrl = process.env.REACT_APP_BASE_URL;
  window.location.href = baseUrl
    ? `${baseUrl}/signin`
    : `${window.location.origin}/signin`;
};
