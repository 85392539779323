import React, { useState, useEffect } from 'react';
import {
  makeStyles,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Input,
  Chip,
  Checkbox
} from '@material-ui/core';
import { get } from 'lodash';
import CustomInput from '../../../components/CustomInput/CustomInput';
import CardHeader from '../../../components/Card/CardHeader';
import Error from '../../../components/Error';
import GridItem from '../../../components/Grid/GridItem';
import GridContainer from '../../../components/Grid/GridContainer';
import Card from '../../../components/Card/Card';
import { search } from '../../../dataService/search';
import {
  serviceType,
  channel,
  status,
  isPrecentage,
} from '../../../config/env.json';
import Grid from '@material-ui/core/Grid';
import CardBody from '../../../components/Card/CardBody';
import TextField from '@material-ui/core/TextField';
import Check from '@material-ui/icons/Check';
// import moment from 'moment';

// const DATE_FORMAT = 'MM-DD-YYYY';

const DiscountFormBlock = ({ formik, isEdit }) => {
  const classes = useStyle();
  const { touched, errors } = formik;

  const [packages, setPackages] = useState([]);

  const loadPackages = async () => {
    let allPackages = [];
    let pageData = [];
    let counter = 1;
    while (pageData) {
      pageData = await search({
        _entity: 'Package',
        packageCode: 'PKG',
        _select: 'packageCode',
        _page: counter,
      });
      if (pageData.data.length === 0) {
        break;
      }
      allPackages = allPackages.concat(pageData.data);
      counter++;
    }
    setPackages(allPackages);
  };

  useEffect(() => {
    loadPackages();
  }, []);

  const multipleSelectPropsProcess = props => {
    return { ...props, ...{ value: props.value || [] } };
  };
  return (
    <Card>
     
      <h3 style={{ paddingLeft: '20px' }}>
        <b>Discount Information</b>
      </h3>

      <GridItem>
        <GridContainer>
          <GridItem xs={6}>
            <CustomInput
              groupText={!isEdit ? 'PRM_' : ''}
              labelText="Promo Code *"
              formControlProps={{
                fullWidth: true,
              }}
              inputProps={{
                ...formik.getFieldProps('promoCode'),
                disabled: isEdit,
                error: !!(touched.promoCode && errors.promoCode),
              }}
            />
            {touched.promoCode && errors.promoCode && (
              <Error msg={errors.promoCode} />
            )}
          </GridItem>

          <GridItem xs={6}>
            <CustomInput
              labelText="Title *"
              formControlProps={{
                fullWidth: true,
              }}
              inputProps={{
                type: 'text',
                ...formik.getFieldProps('title'),
                error: get(touched, 'title', '') && get(errors, 'title', ''),
              }}
            />
            {get(errors, 'title', '') && get(touched, 'title', '') && (
              <Error msg={get(errors, 'title', '')} />
            )}
          </GridItem>
        </GridContainer>
      </GridItem>

      <GridItem>
        <GridContainer>
          <GridItem xs={4} style={{ marginTop: '27px' }}>
            <TextField
              className={classes.container}
              label="Prmotion Start Date *"
              formControlProps={{
                fullWidth: true,
              }}
              InputLabelProps={{
                shrink: true,
              }}
              inputProps={{
                type: 'date',
                ...formik.getFieldProps('promoStart'),
                error:
                  get(touched, 'promoStart', '') &&
                  get(errors, 'promoStart', ''),
              }}
            />
            {get(errors, 'promoStart', '') &&
              get(touched, 'promoStart', '') && (
                <Error msg={get(errors, 'promoStart', '')} />
              )}
          </GridItem>
          <GridItem xs={4} style={{ marginTop: '27px' }}>
            <TextField
              // dateFormat={DATE_FORMAT}
              className={classes.container}
              label="Prmotion End Date *"
              formControlProps={{
                fullWidth: true,
              }}
              InputLabelProps={{
                shrink: true,
              }}
              inputProps={{
                type: 'date',
                ...formik.getFieldProps('promoEnd'),
                error:
                  get(touched, 'promoEnd', '') && get(errors, 'promoEnd', ''),
              }}
            />
            {get(errors, 'promoEnd', '') && get(touched, 'promoEnd', '') && (
              <Error msg={get(errors, 'promoEnd', '')} />
            )}
          </GridItem>
          <GridItem xs={4}>
            <FormControl className={classes.formControl}>
              <InputLabel>Service Type *</InputLabel>
              <Select
                multiple
                input={<Input id="select-service-type" />}
                renderValue={selected => (
                  <div className={classes.chips}>
                    {selected.map(value => (
                      <Chip
                        key={value}
                        label={value}
                        className={classes.chip}
                      />
                    ))}
                  </div>
                )}
                {...multipleSelectPropsProcess(
                  formik.getFieldProps('serviceType'),
                )}
              >
                {serviceType.map(serviceType => (
                  <MenuItem key={serviceType.id} value={serviceType.value}>
                    {serviceType.label}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
            {get(errors, 'serviceType', '') &&
              get(touched, 'serviceType', '') && (
                <Error msg={get(errors, 'serviceType', '')} />
              )}
          </GridItem>
        </GridContainer>
        <GridContainer>
          <GridItem xs={4}>
            <FormControl className={classes.formControl}>
              <InputLabel>Channel *</InputLabel>
              <Select
                multiple
                input={<Input id="select-channel" />}
                renderValue={selected => (
                  <div className={classes.chips}>
                    {selected.map(value => (
                      <Chip
                        key={value}
                        label={value}
                        className={classes.chip}
                      />
                    ))}
                  </div>
                )}
                {...multipleSelectPropsProcess(formik.getFieldProps('channel'))}
              >
                {channel.map(channel => (
                  <MenuItem key={channel.id} value={channel.value}>
                    {channel.label}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
            {get(errors, 'channel', '') && get(touched, 'channel', '') && (
              <Error msg={get(errors, 'channel', '')} />
            )}
          </GridItem>
          <Grid item md={2} style={{ paddingLeft: '20px' }}>
            <FormControl className={classes.formControl}>
              <InputLabel>Status *</InputLabel>
              <Select {...formik.getFieldProps('status')}>
                <MenuItem value="">Select Status Type</MenuItem>
                {status.map(status => (
                  <MenuItem key={status.id} value={status.value}>
                    {status.label}
                  </MenuItem>
                ))}
              </Select>
              {touched.status && errors.status && <Error msg={errors.status} />}
            </FormControl>
          </Grid>
          <Grid item md={2} style={{ paddingLeft: '20px' }}>
            <FormControl className={classes.formControl}>
              <InputLabel>Is Precentage *</InputLabel>
              <Select
                {...formik.getFieldProps('isPrecentage')}
              >
                <MenuItem value="">Is Precentage</MenuItem>
                {isPrecentage.map(isPrecentage => (
                  <MenuItem key={isPrecentage.id} value={isPrecentage.value}>
                    {isPrecentage.label}
                  </MenuItem>
                ))}
              </Select>
              {touched.isPrecentage && errors.isPrecentage && (
                <Error msg={errors.isPrecentage} />
              )}
            </FormControl>
          </Grid>
          <GridItem md={3} style={{ paddingLeft: '20px' }}>
            <CustomInput
              labelText="Discount Amount *"
              formControlProps={{
                fullWidth: true,
              }}
              inputProps={{
                type: 'text',
                ...formik.getFieldProps('discountAmount'),
                error:
                  get(touched, 'discountAmount', '') &&
                  get(errors, 'discountAmount', ''),
              }}
            />
            {get(errors, 'discountAmount', '') &&
              get(touched, 'discountAmount', '') && (
                <Error msg={get(errors, 'discountAmount', '')} />
              )}
          </GridItem>
        </GridContainer>
        <GridContainer>
          <GridItem xs={6}>
            <FormControl className={classes.formControl}>
              <InputLabel>Valid packages</InputLabel>
              <Select
                multiple
                input={<Input id="select-packages" />}
                renderValue={selected => (
                  <div className={classes.chips}>
                    {selected.map(value => (
                      <Chip
                        key={value}
                        label={value}
                        className={classes.chip}
                      />
                    ))}
                  </div>
                )}
                {...multipleSelectPropsProcess(
                  formik.getFieldProps('validPackages'),
                )}
              >
                <MenuItem key="PKG_SELECT_ALL" value="PKG_SELECT_ALL">
                    Valid for All Packages
                </MenuItem>
                {packages.map(({ packageCode }) => (
                  <MenuItem key={packageCode} value={packageCode}>
                    {packageCode}
                  </MenuItem>
                ))}
              </Select>

              {get(errors, 'validPackages', '') &&
                get(touched, 'validPackages', '') && (
                  <Error
                    msg={get(errors, 'validPackages', '')}
                  />
                )}
            </FormControl>
          </GridItem>
        </GridContainer>
      </GridItem>
    </Card>
  );
};


const useStyle = makeStyles(theme => ({
  formControl: {
    width: '100%',
    marginTop: '27px',
  },
  container: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  textField: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    width: 200,
  },
}));

export default DiscountFormBlock;
