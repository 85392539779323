import React from 'react';
import {
  Card,
  Table,
  TableHead,
  TableCell,
  TableRow,
  TableBody,
  makeStyles,
  withStyles,
} from '@material-ui/core';
import CardBody from '../Card/CardBody';
import GridItem from '../Grid/GridItem';
import GridContainer from '../Grid/GridContainer';
import { Link } from 'react-router-dom';
import { Edit } from '@material-ui/icons';
import Button from '../CustomButtons/Button';
import { styles } from 'assets/jss/material-dashboard-react/components/cardIconStyle.js';
import moment from 'moment';

const CustomerPublicInfoDetailsBody = ({
  customerInfo: {
    customerCode,
    status,
    channel,
    upi,
    profile: {
      firstName,
      lastName,
      dob,
      address: { city, street, country, zip },
    },
    credential: { fbId, mobile, email },
    createdAt,
  },
}) => {
  const classes = useStyles();

  const city1 = city;
  const street1 = street;
  const country1 = country;
  const zip1 = zip;
  const createDate = moment(createdAt).format('YYYY-MM-DD HH:MM');
  var location = '-';

  if (city != null && street != null && country != null && zip != null) {
    location = city1 + ' ,' + street1 + ' ,' + country1 + ' ,' + zip1;
  } else if (street != null) {
    location = street1;
  }

  var fbIdField = fbId;

  if (fbId != null) {
    fbIdField = 'Yes';
  } else {
    fbIdField = 'No';
  }

  const getStartedPhoneNumber = number => {
    return '*******' + number.slice(-4);
  };

  return (
    <Card>
      <CardBody>
        <GridContainer>
          {/* here */}
          <GridItem md={6}>
            <div>
              <img
                src="https://admin.praavahealth.com/praava_logo.png"
                alt="logo"
                style={{ width: '20%' }}
              />
            </div>
          </GridItem>
          {/* <GridItem md={6}>
            <GridContainer justify="flex-end" alignItems="center">
              <GridItem>
                <Link to={`/customers/${customerCode}/edit`}>
                  <Button
                    color="danger"
                    style={{ position: 'relative', top: '22px' }}
                  >
                    <Edit />
                    Edit Customer Details
                  </Button>
                </Link>
              </GridItem>
            </GridContainer>
          </GridItem> */}
          <GridItem md={12}>
            <Table className={classes.table}>
              <TableHead>
                <TableRow>
                  <StyledTableCell
                    style={{
                      borderTopLeftRadius: '10px',
                      borderBottomLeftRadius: '10px',
                    }}
                  >
                    Customer Details
                  </StyledTableCell>
                  <StyledTableCell
                    style={{
                      borderTopRightRadius: '10px',
                      borderBottomRightRadius: '10px',
                    }}
                    align="right"
                  >
                    Value
                  </StyledTableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                <TableRow>
                  <TableCell align="left">First Name</TableCell>
                  <TableCell align="right">{firstName || '-'}</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell align="left">Last Name</TableCell>
                  <TableCell align="right">{lastName || '-'}</TableCell>
                </TableRow>

                {/* <TableRow>
                  <TableCell align="left">Status</TableCell>
                  <TableCell align="right">{status || '-'}</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell align="left">Email</TableCell>
                  <TableCell align="right">{email || '-'}</TableCell>
                </TableRow> */}
                <TableRow>
                  <TableCell align="left">Mobile Number</TableCell>
                  <TableCell align="right">
                    {getStartedPhoneNumber(mobile) || '-'}
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell align="left">UPI</TableCell>
                  <TableCell align="right">{upi}</TableCell>
                </TableRow>
                {/* <TableRow>
                  <TableCell align="left">FB Integration</TableCell>
                  <TableCell align="right">{fbIdField}</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell align="left">Address</TableCell>
                  <TableCell align="right">{location || '-'}</TableCell>
                </TableRow> */}

                {/* <TableRow>
                  <TableCell align="left">Channel</TableCell>
                  <TableCell align="right">{channel || '-'}</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell align="left">Created At</TableCell>
                  <TableCell align="right">{createDate || '-'}</TableCell>
                </TableRow> */}
              </TableBody>
            </Table>
          </GridItem>
          <GridItem md={12}>
            <div className={classes.spacing} />
          </GridItem>
        </GridContainer>
      </CardBody>
    </Card>
  );
};

const StyledTableCell = withStyles(theme => ({
  head: {
    backgroundColor: '#778899',
    color: theme.palette.common.white,
    paddingTop: '20px',
    paddingBottom: '20px',
  },
  body: {
    fontSize: 14,
  },
}))(TableCell);

const useStyles = makeStyles({
  topSpacing: {
    marginTop: '30px',
  },
  subTitle: {
    marginTop: 0,
  },
  spacing: {
    height: '30px',
  },
  flexVCenter: {
    display: 'flex',
    alignItems: 'center',
  },
});

export default CustomerPublicInfoDetailsBody;
