import React from 'react';
import { TableRow, TableCell } from '@material-ui/core';
import { Link } from 'react-router-dom';
import { EditOutlined, InfoOutlined } from '@material-ui/icons';
import Button from '../../components/CustomButtons/Button';

const FeatureCard = ({ content }) => {
  const { packageType, packageTitle, packageCode, packageWeight, price, status } = content;
  return (
    <TableRow>
      <TableCell component="th" scope="row">
        {packageType}
      </TableCell>
      <TableCell align="left">{packageTitle}</TableCell>
      <TableCell>{packageCode}</TableCell>
      <TableCell
        align="right"
        style={{ color: status === 'Active' ? 'green' : 'red' }}
      >
        <strong>{status}</strong>
      </TableCell>
      
      <TableCell align="right">{price}</TableCell>
      <TableCell align="right">
        <Link to={`/packages/${packageCode}/edit`}>
          <Button small>Edit</Button>
        </Link>
        <Link to={`/packages/${packageCode}`}>
          <Button color="success" small>
            <InfoOutlined />
            Details
          </Button>
        </Link>
      </TableCell>
    </TableRow>
  );
};

export default FeatureCard;
