import axios from 'axios';
import {
  nebulaHost,
  clientName,
  clientVersion,
  countryCode,
  requestId,
} from '../config/env';

const headers = {
  'Content-Type': 'application/json',
  'X-Client-Name': clientName,
  'X-Client-Version': clientVersion,
  'X-Country-Code': countryCode,
  'X-Request-ID': requestId,
};

const munasInstance = axios.create({
  baseURL: nebulaHost,
  headers,
  timeout: 1000 * 60 * 5,
});

export default munasInstance;
