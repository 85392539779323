import React from 'react';
import { makeStyles } from '@material-ui/core';

import GridContainer from '../../../components/Grid/GridContainer';
import CustomInput from '../../../components/CustomInput/CustomInput';
import Button from '../../../components/CustomButtons/Button';
import CardHeader from '../../../components/Card/CardHeader';
import CardBody from '../../../components/Card/CardBody';
import CardFooter from '../../../components/Card/CardFooter';
import GridItem from '../../../components/Grid/GridItem';
import Error from '../../../components/Error';
import TextField from '@material-ui/core/TextField';

const ReEnterPassword = ({ formik }) => {
  const classes = useStyles();
  return (
    <form onSubmit={formik.handleSubmit}>
       <CardHeader color="danger">
        <h4 className={classes.cardTitleWhite}>Reset Password</h4>
      </CardHeader> 
      <CardBody style={{ paddingTop: '40px', paddingBottom: '40px' }}>
        <GridContainer>
          <GridItem xs={12}>
            <TextField
              style={{ paddingBottom: '5%' }}
              variant="outlined"
              fullWidth
              label="new password"
              name="password"
              inputProps={{
                ...formik.getFieldProps('password'),
                placeholder: '********',
                type: 'password',
              }}
              formControlProps={{
                fullWidth: true,
              }}
            />
            {formik.touched.password && formik.errors.password && (
              <Error msg={formik.errors.password} />
            )}
          </GridItem>
          <GridItem xs={12}>
            <TextField
              variant="outlined"
              fullWidth
              label="re-enter new password"
              name="confirmPassword"
              inputProps={{
                ...formik.getFieldProps('confirmPassword'),
                placeholder: '********',
                type: 'password',
              }}
              formControlProps={{
                fullWidth: true,
              }}
            />
            {formik.touched.confirmPassword && formik.errors.confirmPassword && (
              <Error msg={formik.errors.confirmPassword} />
            )}
          </GridItem>
        </GridContainer>
      </CardBody>
      <CardFooter>
        <Button
          fullWidth
          style={{ background: '#800054', borderRadius: '10px' }}
          disabled={
            (formik.isValid && !formik.dirty) ||
            (formik.dirty && !formik.isValid)
          }
          type="submit"
        >
          Reset Password
        </Button>
      </CardFooter>
    </form>
  );
};

const useStyles = makeStyles({
  cardCategoryWhite: {
    color: 'rgba(255,255,255,.62)',
    margin: '0',
    fontSize: '14px',
    marginTop: '0',
    marginBottom: '0',
  },
  cardTitleWhite: {
    color: '#FFFFFF',
    marginTop: '0px',
    minHeight: 'auto',
    fontWeight: '300',
    fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
    marginBottom: '3px',
    textDecoration: 'none',
  },
});

export default ReEnterPassword;
