import axiosInstance from './axiosInstance';

export const updateDiscount = async discountInput => {
  var validPkgs = discountInput.validPackages;

  var selectedPackages = [];
  var isValidForAll = false;
  if (validPkgs.includes('PKG_SELECT_ALL')) {
    selectedPackages = [];
    isValidForAll = true;
  } else {
    selectedPackages = validPkgs;
    isValidForAll = false;
  }

  try {
    const res = await axiosInstance.put(
      `/discounts/${discountInput.promoCode}`,
      {
        title: discountInput.title,
        promoStart: discountInput.promoStart,
        promoEnd: discountInput.promoEnd,
        serviceType: discountInput.serviceType,
        status: discountInput.status,
        channel: discountInput.channel,
        validPackages: selectedPackages,
        validAll: isValidForAll,
        discountAmount: parseInt(discountInput.discountAmount),
        isPrecentage: discountInput.isPrecentage,
      },
    );
    return res.data;
  } catch (error) {
    return error.response.data;
  }
};
