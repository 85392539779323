import axiosInstance from './axiosInstance';

export const addNewDiscount = async promoInput => {
  const { promoCode } = promoInput;

  var validPkgs = promoInput.validPackages;

  var selectedPackages = [];
  var isValidForAll = false;
  if(validPkgs.includes("PKG_SELECT_ALL")) {
    selectedPackages = [];
    isValidForAll = true;
  } else {
    selectedPackages = validPkgs;
    isValidForAll = false;
  }

  promoInput.validPackages = selectedPackages;

  const processedInfo = {
    ...promoInput,
    promoCode: `PRM_${promoCode}`,
    validAll: isValidForAll
  };

  try {
    const res = await axiosInstance.post('/discounts', processedInfo);
    return res.data;
  } catch (error) {
    return error.response.data;
  }
};
