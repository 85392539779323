import React, { useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/core';
import GridItem from '../../components/Grid/GridItem';
import GridContainer from '../../components/Grid/GridContainer';
import Card from '../../components/Card/Card';
import CardBody from '../../components/Card/CardBody';
import Header from '../../components/Header';
import Loading from '../../components/Loading';
import FeatureDetailsBody from '../../components/FeatureDetailsBody';
import { getFeatureDetails } from '../../dataService/getFeatureDetails';

const Contents = props => {
  const classes = useStyles();

  const [featureDetails, setFeatureDetails] = useState({});
  const [isLoading, setIsLoading] = useState(true);

  const requestFeatureDetails = async featureCode => {
    const res = await getFeatureDetails(featureCode);
    setIsLoading(false);
    if (res.status !== 'error') setFeatureDetails(res.data);
  };

  useEffect(() => {
    requestFeatureDetails(props.match.params.featureCode);
  }, []);

  if (isLoading) {
    return (
      <div className={classes.topSpacing}>
        <Loading />
      </div>
    );
  }

  if (!featureDetails.featureCode) {
    return (
      <Card>
        <CardBody>
          <h4>Feature not found</h4>
        </CardBody>
      </Card>
    );
  }

  const { featureType } = featureDetails;

  return (
    <div>
     
      <Card style={{ backgroundColor: '#800054' }}>
        <CardBody>
          <h3 style={{ color: '#ffffff' }}>
            <b>Feature Type: {featureType} </b>
          </h3>
        </CardBody>
      </Card>
      <GridContainer>
        <GridItem xs={12} className={classes.topSpacing}>
          <div className={classes.topSpacing} />
          <FeatureDetailsBody feature={featureDetails} />
        </GridItem>
      </GridContainer>
    </div>
  );
};

const useStyles = makeStyles({
  topSpacing: {
    marginTop: '30px',
  },
  subTitle: {
    fontSize: '24px',
    margin: '10px 0 20px 0',
  },
  spacing: {
    height: '30px',
  },
});

export default Contents;
