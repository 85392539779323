import axiosInstance from './axiosInstance';

export const getPackageToDropdown = async params => {
    try {
        const res = await axiosInstance.get('/packages'
            , {
                params,
                data: null,
            }
        );
        return res.data;
    } catch (error) {
        return error.response.data;
    }
};
