import React, { useState, useEffect } from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import {
  Drawer,
  makeStyles,
  Hidden,
  ListItem,
  ListItemText,
  Icon,
} from '@material-ui/core';
import { KeyboardArrowDown, KeyboardArrowUp } from '@material-ui/icons';
import SupervisedUserCircleIcon from '@material-ui/icons/SupervisedUserCircle';
import PermIdentityIcon from '@material-ui/icons/PermIdentity';
import LockIcon from '@material-ui/icons/Lock';
import EmailIcon from '@material-ui/icons/Email';
import RedeemIcon from '@material-ui/icons/Redeem';
import LowPriorityIcon from '@material-ui/icons/LowPriority';
import GroupAddIcon from '@material-ui/icons/GroupAdd';
import GroupIcon from '@material-ui/icons/Group';
import RecentActorsIcon from '@material-ui/icons/RecentActors';
import LocalPlayIcon from '@material-ui/icons/LocalPlay';
import CreateNewFolderIcon from '@material-ui/icons/CreateNewFolder';
import FingerprintIcon from '@material-ui/icons/Fingerprint';
import CSVIcon from '@material-ui/icons/GridOn';
import { useDispatch } from 'react-redux';
import MenuItem from './MenuItem';
import { logout } from '../../dataService/loginService';
import styles from '../../assets/jss/material-dashboard-react/components/sidebarStyle';

const Sidebar = props => {
  const classes = useStyles();
  const { open, handleDrawerToggle } = props;
  const dispatch = useDispatch();
  const { logo, image } = props;

  const [dropDownOpen, setDropDownOpen] = useState('');
  const dropdownToggle = key => {
    setDropDownOpen(dropDownOpen === key ? '' : key);
  };

  const page = window.location.href.split('/').slice(-2)[0];

  useEffect(() => {
    setDropDownOpen(page || '');
  }, [page]);

  const brand = (
    <div className={classes.logo}>
      <Link to="/dashboard" className={classNames(classes.logoLink)}>
        <div className={classes.logoImage}>
          <img
            src="./praava_logo.png"
            alt="logo"
            className={classes.img}
            style={{ width: '90%' }}
          />
        </div>
      </Link>
    </div>
  );
  return (
    <div>
      <Hidden mdUp implementation="css">
        <Drawer
          variant="temporary"
          open={open}
          classes={{
            paper: classNames(classes.drawerPaper),
          }}
          onClose={handleDrawerToggle}
          ModalProps={{
            keepMounted: true, // Better open performance on mobile.
          }}
        >
          {brand}
          {image !== undefined ? (
            <div
              className={classes.background}
              style={{ backgroundImage: `url(${image})` }}
            />
          ) : null}
        </Drawer>
      </Hidden>
      <Hidden smDown implementation="css">
        <Drawer
          variant="permanent"
          open
          classes={{
            paper: classNames(classes.drawerPaper),
          }}
        >
          {brand}
          <div className={classes.sidebarWrapper}>
            {/* <MenuItem icon={Assignment} name="Content" to="/contents" /> */}
            {/* <MenuItem
              icon={PermIdentityIcon}
              name="Public Customers"
              to="/search-customer-using-upi"
            /> */}
            <MenuItem
              icon={PermIdentityIcon}
              name="Customers"
              to="/customers"
            />
            <MenuItem
              icon={SupervisedUserCircleIcon}
              name="Family Members"
              to="/family-members"
            />
            <MenuItem icon={LocalPlayIcon} name="Features" to="/features" />
            <MenuItem
              icon={CreateNewFolderIcon}
              name="Packages"
              to="/packages"
            />
            <MenuItem
              icon={FingerprintIcon}
              name="Activity Details"
              to="/activitydetails"
            />
            {/* <MenuItem icon={RedeemIcon} name="Discounts" to="/discounts" /> */}
            {/* <div
              className={classNames({
                [classes.item]: true,
                [classes.dropDownActive]: dropDownOpen === 'manage',
              })}
              onClick={() => dropdownToggle('manage')}
            > */}
            {/* <ListItem button className={`${classes.itemLink}`}>
                <Icon className={`${classes.itemIcon} ${classes.whiteFont}`}>
                  <Person />
                </Icon>
                <ListItemText className={classes.submenu}>
                  Manage
                  {dropDownOpen !== 'manage' && (
                    <KeyboardArrowUp className={classes.dropddownIcon} />
                  )}
                  {dropDownOpen === 'manage' && (
                    <KeyboardArrowDown className={classes.dropddownIcon} />
                  )}
                </ListItemText>
              </ListItem>
            </div> */}

            {/* {dropDownOpen === 'manage' && (
              <>
                <MenuItem
                  icon={SupervisedUserCircleIcon}
                  name="Customers"
                  to="/manage/customers"
                />
              </>
            )} */}
            <div
              className={classNames({
                [classes.item]: true,
                [classes.dropDownActive]: dropDownOpen === 'manage',
              })}
              onClick={() => dropdownToggle('manage')}
            >
              <ListItem button className={`${classes.itemLink}`}>
                <Icon className={`${classes.itemIcon} ${classes.whiteFont}`}>
                  <LowPriorityIcon />
                </Icon>
                <ListItemText className={classes.submenu}>
                  Manage
                  {dropDownOpen !== 'manage' && (
                    <KeyboardArrowUp className={classes.dropddownIcon} />
                  )}
                  {dropDownOpen === 'manage' && (
                    <KeyboardArrowDown className={classes.dropddownIcon} />
                  )}
                </ListItemText>
              </ListItem>
            </div>

            {dropDownOpen === 'manage' && (
              <>
                <MenuItem
                  icon={RedeemIcon}
                  name="Discounts"
                  to="/manage/discounts"
                />
                <MenuItem
                  icon={FingerprintIcon}
                  name="Activities"
                  to="/manage/activities"
                />
                <MenuItem
                  icon={CSVIcon}
                  name="Add By CSV"
                  to="/manage/add-by-csv"
                />
                <MenuItem
                  icon={EmailIcon}
                  name="Update Static Data"
                  to="/manage/update-static-data"
                />
              </>
            )}
            <div
              className={classNames({
                [classes.item]: true,
                [classes.dropDownActive]: dropDownOpen === 'users',
              })}
              onClick={() => dropdownToggle('users')}
            >
              <ListItem button className={`${classes.itemLink}`}>
                <Icon className={`${classes.itemIcon} ${classes.whiteFont}`}>
                  <GroupIcon />
                </Icon>
                <ListItemText className={classes.submenu}>
                  Users
                  {dropDownOpen !== 'users' && (
                    <KeyboardArrowUp className={classes.dropddownIcon} />
                  )}
                  {dropDownOpen === 'users' && (
                    <KeyboardArrowDown className={classes.dropddownIcon} />
                  )}
                </ListItemText>
              </ListItem>
            </div>

            {dropDownOpen === 'users' && (
              <>
                <MenuItem
                  icon={RecentActorsIcon}
                  name="All Users"
                  to="/users/all"
                />
                <MenuItem
                  icon={GroupAddIcon}
                  name="Add New User"
                  to="/manage/users/new"
                />
              </>
            )}

            <div className={classes.item}>
              <ListItem
                button
                onClick={() => logout(dispatch)}
                className={classes.itemLink}
              >
                <Icon className={classNames(classes.itemIcon)}>
                  <LockIcon />
                </Icon>
                <ListItemText
                  primary="Logout"
                  className={classNames(classes.itemText)}
                  disableTypography
                />
              </ListItem>
            </div>
          </div>
          {image !== undefined ? (
            <div
              className={classes.background}
              style={{ backgroundImage: `url(${image})` }}
            />
          ) : null}
        </Drawer>
      </Hidden>
    </div>
  );
};

const useStyles = makeStyles(styles);

Sidebar.propTypes = {
  handleDrawerToggle: PropTypes.func,
  // bgColor: PropTypes.oneOf(['purple', 'blue', 'green', 'orange', 'red']),
  logo: PropTypes.string,
  image: PropTypes.string,
  // logoText: PropTypes.string,
  routes: PropTypes.arrayOf(PropTypes.object),
  open: PropTypes.bool,
};

export default Sidebar;
