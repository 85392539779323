import React, { useState, useEffect } from 'react';
import {
  makeStyles,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  withStyles,
} from '@material-ui/core';
import { Edit, FileCopy } from '@material-ui/icons';
import { Link } from 'react-router-dom';
import Button from '../../components/CustomButtons/Button';
import GridItem from '../../components/Grid/GridItem';
import GridContainer from '../../components/Grid/GridContainer';
import Card from '../../components/Card/Card';
import CardBody from '../../components/Card/CardBody';
import Header from '../../components/Header';
import { getPackageDetails } from '../../dataService/getPackageDetails';
import Loading from '../../components/Loading';
import CopyPackage from '../../components/CopyPackage';

const PackagesDetails = props => {
  const classes = useStyles();

  const [packageDetails, setPackageDetails] = useState({});
  const [isLoading, setIsLoading] = useState(true);
  const [openCopyForm, setOpenCopyForm] = useState(false);
  const requestConcentnDetails = async packageCode => {
    const res = await getPackageDetails(packageCode);
    setIsLoading(false);
    if (res.status !== 'error') setPackageDetails(res.data);
  };

  useEffect(() => {
    requestConcentnDetails(props.match.params.packageCode);
  }, []);

  if (isLoading) {
    return (
      <div className={classes.topSpacing}>
        {/* <Header title="..." /> */}
        <Card style={{ backgroundColor: '#800054' }}>
          <CardBody>
            <h3 style={{ color: '#ffffff' }}>
              <b>
                Package Type: {packageType || '-'} | Package Status:{' '}
                {status || '-'}
              </b>
            </h3>
          </CardBody>
        </Card>
        <Loading />
      </div>
    );
  }

  if (!packageDetails.packageCode) {
    return (
      <Card>
        <CardBody>
          <h4>Content not found</h4>
        </CardBody>
      </Card>
    );
  }

  const {
    id,
    packageCode,
    packageTitle,
    packageType,
    status,
    price,
    enabledForChannels,
    content: { bn, en },
    validity: { type: validityType, count: validityCount },
    activationRules: {
      startDelayInDays,
      maxActivationCountPerYear,
      maxActivationCountPerMonth,
    },
    updatedBy,
    featureIds,
    createdAt,
    updatedAt,
    features,
    content,
  } = packageDetails;

  return (
    <>
      {/* <Header title={`${packageType} | ${status}`} /> */}
      <Card style={{ backgroundColor: '#800054' }}>
        <CardBody>
          <h3 style={{ color: '#ffffff' }}>
            <b>
              Package Type: {packageType} | Package Status: {status}
            </b>
          </h3>
        </CardBody>
      </Card>
     

      <Card>
        <CardBody>
          <GridContainer>
            <GridItem md={12}>
              <GridContainer justify="flex-end" alignItems="center">
             
              </GridContainer>
              <Table className={classes.table}>
                <TableHead>
                  <TableRow>
                    <StyledTableCell
                      style={{
                        borderTopLeftRadius: '10px',
                        borderBottomLeftRadius: '10px',
                      }}
                    >
                      Information Type
                    </StyledTableCell>
                    <StyledTableCell
                      style={{
                        borderTopRightRadius: '10px',
                        borderBottomRightRadius: '10px',
                      }}
                      align="right"
                    >
                      Information Value
                    </StyledTableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  <TableRow>
                    <TableCell align="left">ID</TableCell>
                    <TableCell align="right">{id}</TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell align="left">Package Type</TableCell>
                    <TableCell align="right">{packageType}</TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell align="left">Package Code</TableCell>
                    <TableCell align="right">{packageCode}</TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell align="left">Package Title</TableCell>
                    <TableCell align="right">{packageTitle}</TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell align="left">Status</TableCell>
                    <TableCell
                      align="right"
                      style={{ color: status === 'Active' ? 'green' : 'red' }}
                    >
                      <strong>{status}</strong>
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell align="left">Price</TableCell>
                    <TableCell align="right">{price}</TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell align="left">Validity Type</TableCell>
                    <TableCell align="right">{validityType}</TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell align="left">Validity Count</TableCell>
                    <TableCell align="right">{validityCount}</TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell align="left">Enabled For Channels</TableCell>
                    <TableCell align="right">{enabledForChannels}</TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell align="left">English Content</TableCell>
                    <TableCell align="right">{en}</TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell align="left">Bangala Content</TableCell>
                    <TableCell align="right">{bn}</TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell align="left">Start Delay In Days</TableCell>
                    <TableCell align="right">{startDelayInDays}</TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell align="left">
                      Max Activation Count Per Year
                    </TableCell>
                    <TableCell align="right">
                      {maxActivationCountPerYear}
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell align="left">
                      Max Activation Count Per Month
                    </TableCell>
                    <TableCell align="right">
                      {maxActivationCountPerMonth}
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell align="left">
                      Max Activation Count Per Month
                    </TableCell>
                    <TableCell align="right">
                      {maxActivationCountPerMonth}
                    </TableCell>
                  </TableRow>
                </TableBody>
              </Table>
            </GridItem>
          </GridContainer>
        </CardBody>
      </Card>

    

      <CopyPackage
        packageDetails={packageDetails}
        openCopyForm={openCopyForm}
        setOpenCopyForm={setOpenCopyForm}
      />
    </>
  );
};

const StyledTableCell = withStyles(theme => ({
  head: {
    backgroundColor: '#778899',
    color: theme.palette.common.white,
    paddingTop: '20px',
    paddingBottom: '20px',
  },
  body: {
    fontSize: 14,
  },
}))(TableCell);

const useStyles = makeStyles({
  topSpacing: {
    marginTop: '30px',
  },
  marginTopNone: {
    marginTop: 0,
  },
});

export default PackagesDetails;
