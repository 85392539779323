import * as Yup from 'yup';

export const initialValues = {
  name: '',
  mobile: '',
  email: '',
  password: '',
  role: '',
  target: 0,
};

export const editFormInitialValues = {
  name: '',
  mobile: '',
  email: '',
  password: '',
  role: '',
  target: 0,
};

const phoneRegExp = /^((0))[0-9]{10}$/


export const validationSchema = Yup.object({
  name: Yup.string('Enter a name').required('Name is required!'),
  mobile: Yup.string('Enter your mobile').required("Mobile is required!")
  .matches(phoneRegExp, 'Phone number is not valid, It should be start with 0 and 11 digits number'),
  email: Yup.string('Enter your email')
    .email('Enter a valid email!')
    .required('Email is required!'),
  password: Yup.string()
    .min(8, 'Password must contain at least 8 characters!')
    .max(30, 'Max Password length is limited to 30 characters!')
    .matches(
      /^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!@$%^&*-]).{8,30}$/,
      'Password should be a minimum of 8 characters with at least one uppercase, lowercase, number and a symbol in it',
    )
    .required('Enter your password!'),
    target: Yup.number('should be a number'),
  role: Yup.string('Select a role').required('Role is required!'),
});

export const editValidationSchema = Yup.object({
  name: Yup.string('Enter a name').required('Name is required!'),
  status: Yup.boolean(''),
  target: Yup.number('should be a number'),
  role: Yup.string('Select a role').required('Role is required!'),
});
