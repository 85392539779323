import axiosInstance from './axiosInstance';

export const addNewCustomerAndSubscribe = async values => {
  try {
    const res = await axiosInstance.post(
      '/orders/registerAndSubscribe',
      values,
    );
    console.log(res);
    return res.data;
  } catch (error) {
    return error.response.data;
  }
};
