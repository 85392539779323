import { makeStyles } from '@material-ui/core';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import Select from '@material-ui/core/Select';
import { allActivities } from 'dataService/allActivities';
import React, { useEffect, useState } from 'react';
import 'react-quill/dist/quill.snow.css';
import Card from '../../components/Card/Card';
import CardBody from '../../components/Card/CardBody';
import TabContent from './tabs/TabContent';

const UpdateStaticData = () => {
  const classes = useStyles();

  const [activityCodeList, setActivityCodeList] = useState([]);
  const [selectedActivityCode, setSelectedActivityCode] = useState();
  const [searchActivityTerms, setSearchActivityTerms] = useState({
    _entity: 'Activity',
    _select: '*',
    _page: 1,
  });

  useEffect(() => {
    getActivityCodeList();
  }, []);

  const getActivityCodeList = async () => {
    const res = await allActivities(searchActivityTerms);
    const rows = [];

    if (res.status !== 'success') return [];

    res.data.forEach(item => {
      const { id, activityCode, title } = item;
      rows.push({
        value: activityCode,
        label: activityCode,
      });
    });
    setActivityCodeList(rows);
  };

  const handleActivityCodeChange = e => {
    console.log(e.target.value);
    setSelectedActivityCode(e.target.value);
  };

  return (
    <>
      <div className={classes.container}>
        <Card style={{ backgroundColor: '#800054' }}>
          <CardBody>
            <h3 style={{ color: '#ffffff' }}>
              <b>Update Email Content</b>
            </h3>
          </CardBody>
        </Card>
        <FormControl style={{ width: '49%' }} variant="outlined">
          <InputLabel htmlFor="outlined-age-native-simple">
            Select Activity Code
          </InputLabel>
          <Select
            native
            displayEmpty
            value={selectedActivityCode}
            onChange={handleActivityCodeChange}
            label="Select Activity"
            inputProps={{
              name: 'Select Activity',
              id: 'outlined-age-native-simple',
            }}
          >
            <option value="NONE">NONE</option>
            <option value="PACKAGE_TYPE_VC">PACKAGE_TYPE_VC</option>
            <option value="PACKAGE_TYPE_OTHERS">PACKAGE_TYPE_OTHERS</option>
            <option value="REGISTRATION">REGISTRATION</option>
            {activityCodeList.map((p, i) => (
              <option key={i} value={p.value}>
                {p.label}
              </option>
            ))}
          </Select>
        </FormControl>
        <TabContent type={selectedActivityCode} />
      </div>
    </>
  );
};

const useStyles = makeStyles({
  pageTopSpacing: {
    marginTop: '30px',
  },
  blockSpacing: {
    marginTop: '60px',
  },
});

export default UpdateStaticData;
