import * as Yup from 'yup';

export const yupSchemaObject = {
  promoCode: Yup.string('Enter a Promo Code')
    .matches(
      /^[A-Z][A-Z_0-9]+[A-Z-0-9]$/,
      'Promo Code must be in uppercase and contains only Alphabet, Number and Underscore (not at the end or start)',
    )
    .required('Promo Code is `mandatory`'),
  title: Yup.string('Enter Discount Title').required(
    'Discount Title `mandatory`',
  ),
  promoStart: Yup.string('Enter Discount Title').required(
    'Promo Start date is `mandatory`',
  ),
  promoEnd: Yup.string('Enter Discount Title').required(
    'Promo End date is `mandatory`',
  ),
  serviceType: Yup.string('Select a service Type').required(
    'Service Type is mandatory',
  ),
  isPrecentage: Yup.string('Set Discounted %').required(
    'Discounted % is mandatory',
  ),
  discountAmount: Yup.number('Enter Discounted amount')
    .typeError('Enter Discounted amount in number')
    .required('Discounted amount is mandatory')
    .max(999999, 'Maximum Discounted amount is 999999')
    .integer('please provide integer number'),
  status: Yup.string('Select a status').required('Status is mandatory'),
  channel: Yup.string('Select a channel').required('Channel is mandatory'),
};
export const initialValues = {
  promoCode: '',
  title: '',
  promoStart: '',
  promoEnd: '',
  serviceType: '',
  isPrecentage: '',
  discountAmount: '',
  status: '',
  channel: '',
};
