import axiosInstance from './axiosInstance';

export const updateFeature = async featureInput => {
  try {
    const res = await axiosInstance.put(
      `/features/${featureInput.featureCode}`,
      {
        featureType: featureInput.featureType,
        featureWeight: featureInput.featureWeight,
        content: featureInput.content,
      },
    );
    return res.data;
  } catch (error) {
    return error.response.data;
  }
};
