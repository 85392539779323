import {
    Chip,
    FormControl,
    Input,
    InputLabel,
    makeStyles,
    MenuItem,
    Select,
    Grid,
    TextField
  } from '@material-ui/core';
  import { get } from 'lodash';
  import React, { useEffect, useState } from 'react';
  import Card from '../../../components/Card/Card';
  import CustomInput from '../../../components/CustomInput/CustomInput';
  import Error from '../../../components/Error';
  import GridContainer from '../../../components/Grid/GridContainer';
  import GridItem from '../../../components/Grid/GridItem';
  import {
    channels,
    packageStatus,
    packageType,
    validityType,
    gender
  } from '../../../config/env.json';
  import { search } from '../../../dataService/search';
  import moment from 'moment'

  const GeneralInputFormBlock = ({ formik, isEdit }) => {
    const classes = useStyle();
    const { touched, errors } = formik;
    const [features, setFeatures] = useState([]);
    const [selectedStartDate, setSelectedStartDate] = React.useState('')
    let prevDate = moment().subtract(1, 'days').format("YYYY-MM-DDTHH:MM");
    
    const loadFeature = async () => {
      let allFeatures = [];
      let pageData = [];
      let counter = 1;
      while (pageData) {
        pageData = await search({
          _entity: 'Feature',
          featureCode: 'FTR',
          _select: 'featureCode,id',
          _page: counter,
        });
        if (pageData.data.length === 0) {
          break;
        }
        allFeatures = allFeatures.concat(pageData.data);
        counter++;
      }
      setFeatures(allFeatures);
    };
  
 
  
    const multipleSelectPropsProcess = props => {
      return { ...props, ...{ value: props.value || [] } };
    };
  
    useEffect(() => {
      loadFeature();

    }, []);
  

    return (
      <Card style={{ paddingBottom: '20px' }}>
        <h4 style={{ paddingLeft: '20px' }}>
          <b>Genral Customer Information</b>
        </h4>
        <GridItem>
          <GridContainer>
            <GridItem xs={4}>
              <CustomInput
            
                labelText="First Name *"
                formControlProps={{
                  fullWidth: true,
                }}
                inputProps={{
                  disabled: isEdit,
                  ...formik.getFieldProps('firstName'),
                  error: !!(touched.name && errors.name),
                }}
              />
              {touched.name && errors.name && (
                <Error msg={errors.name} />
              )}
            </GridItem>
            <GridItem xs={4}>
              <CustomInput
                labelText="Last Name *"
                formControlProps={{
                  fullWidth: true,
                }}
                inputProps={{
                  ...formik.getFieldProps('lastName'),
                  error: !!(touched.lname && errors.lname),
                }}
              />
              {touched.lname && errors.lname && (
                <Error msg={errors.lname} />
              )}
            </GridItem>
            <GridItem xs={4}>
              <FormControl className={classes.formControl}>
                <InputLabel>Gender *</InputLabel>
                <Select {...formik.getFieldProps('gender')}>
                  <MenuItem value="">Select the Gender</MenuItem>
                  {gender.map(gender => (
                    <MenuItem key={gender.id} value={gender.value}>
                      {gender.label}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
              {touched.gender && errors.gender && (
                <Error msg={errors.gender} />
              )}
            </GridItem>
          </GridContainer>
        </GridItem>
  
        <GridItem>
          <GridContainer>
          
       <Grid md={4} style={{marginTop:'28px', marginLeft:'10px', marginRight:'2px'}} item>
            <form className={classes.container} noValidate>
              <TextField
                id="date-local-start"
                label="Date of birth"
                type="date"
                defaultValue={prevDate}
                className={classes.textField}
                onChange={val => {
                  setSelectedStartDate(val.target.value)
                }}
                InputLabelProps={{
                  shrink: true,
                }}
                size={'small'}
                variant={'outlined'}
                margin={'dense'}
                inputProps={{
                  ...formik.getFieldProps('dob'),
                  error: !!(touched.dob && errors.dob),
                }}

              />
                
            </form>
            {touched.dob && errors.dob && (
                <Error msg={errors.dob} />
              )}
          </Grid>
            <GridItem xs={4}>
              <CustomInput
                labelText="Mobile Number *"
                formControlProps={{
                  fullWidth: true,
                }}
                inputProps={{
                  ...formik.getFieldProps('mobile'),
                  error: !!(touched.mobile && errors.mobile),
                }}
              />
              {touched.mobile && errors.mobile && <Error msg={errors.mobile} />}
            </GridItem>

           
            <GridItem xs={4}>
              <CustomInput
                labelText="Email *"
                formControlProps={{
                  fullWidth: true,
                }}
                inputProps={{
                  ...formik.getFieldProps('email'),
                  error: !!(touched.email && errors.email),
                }}
              />
              {touched.email && errors.email && (
                <Error msg={errors.email} />
              )}
            </GridItem>
            

          </GridContainer>
        </GridItem>
        
          <GridItem>
                <GridContainer>
        <GridItem xs={4}>
              <CustomInput
                labelText="street *"
                formControlProps={{
                  fullWidth: true,
                }}
                inputProps={{
                  ...formik.getFieldProps('street'),
                  error: !!(touched.street && errors.street),
                }}
              />
              {touched.street && errors.street && (
                <Error msg={errors.street} />
              )}
            </GridItem>
            
            <GridItem xs={4}>
              <CustomInput
                labelText="city *"
                formControlProps={{
                  fullWidth: true,
                }}
                inputProps={{
                  ...formik.getFieldProps('city'),
                  error: !!(touched.city && errors.city),
                }}
              />
              {touched.city && errors.city && (
                <Error msg={errors.city} />
              )}
            </GridItem>
            <GridItem xs={4}>
              <CustomInput
                labelText="zip"
                formControlProps={{
                  fullWidth: true,
                }}
                inputProps={{
                  ...formik.getFieldProps('zip'),
                  error: !!(touched.zip && errors.zip),
                }}
              />
              {touched.zip && errors.zip && <Error msg={errors.zip} />}
            </GridItem>
            </GridContainer>
            </GridItem>
      </Card>
    );
  };
  
  const useStyle = makeStyles({
    formControl: {
      width: '100%',
      marginTop: '27px',
    },
  });
  
  export default GeneralInputFormBlock;
  