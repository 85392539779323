/* eslint-disable react/jsx-curly-newline */
import React, { useEffect } from 'react';
import { Route, Redirect } from 'react-router-dom';
import { checkLogin, logout } from '../../dataService/loginService';

const PrivateRoute = ({
  component: Component,
  location: { pathname },
  ...rest
}) => {
  useEffect(() => {
    const currentTime = Math.floor(Date.now() / 1000);
    const expireTime = localStorage.getItem('expiresIn');

    if (currentTime > expireTime) logout();
  }, [pathname]);

  return (
    <Route
      {...rest}
      render={props =>
        checkLogin() ? <Component {...props} /> : <Redirect to="/signin" />
      }
    />
  );
};

export default PrivateRoute;
