import React, { useState, useEffect } from 'react';
import {
  makeStyles,
  Grid,
  FormControlLabel,
  FormControl,
  FormLabel,
  RadioGroup,
  Radio,
  Select,
  MenuItem,
  InputLabel,
  TextField,
} from '@material-ui/core';
import Card from '../../components/Card/Card';
import CardBody from '../../components/Card/CardBody';
import { search } from '../../dataService/search';
import { serviceType } from '../../../src/config/env.json';

let queue;
const Filter = ({ searchTerms, setSearchTerms }) => {
  const classes = useStyles();
  const [featureCode, setFeatureCode] = useState('');

  useEffect(() => {
    const updatedSearchTerms = {
      ...searchTerms,
    };

    if (!featureCode) {
      delete updatedSearchTerms['featureCode'];
      setSearchTerms(updatedSearchTerms);
    }

    if (featureCode.length > 5) {
      updatedSearchTerms['featureCode'] = featureCode;
      clearTimeout(queue);
      queue = setTimeout(() => {
        setSearchTerms(updatedSearchTerms);
      }, 500);
    }
  }, [featureCode]);

  return (
    <Card>
      <CardBody>
        <h4 className={classes.filterTitle}>Search Feature Details</h4>
        <Grid container spacing={1}>
          <Grid md={2} item>
            <TextField
              value={featureCode}
              onChange={ev => setFeatureCode(ev.target.value)}
              className={classes.fullWidth}
              label="Feature Code"
              placeholder="FTR_MEDICAL"
              variant="outlined"
            />
          </Grid>
        </Grid>
      </CardBody>
    </Card>
  );
};

const useStyles = makeStyles({
  topSpacing: {
    marginTop: '30px',
  },
  filterTitle: {
    margin: '0 0 15px 0',
  },
  marginNone: {
    margin: 0,
  },
  formControl: {
    '& legend': {
      margin: '0 200px 0 0',
    },
  },
  fullWidth: {
    width: '100%',
  },
  formGroup: {
    '& label': {
      margin: 0,
    },
  },
});

export default Filter;
