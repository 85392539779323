import React from 'react';
import { get } from 'lodash';
import CustomInput from '../../../components/CustomInput/CustomInput';
import CardHeader from '../../../components/Card/CardHeader';
import Error from '../../../components/Error';
import GridItem from '../../../components/Grid/GridItem';
import GridContainer from '../../../components/Grid/GridContainer';
import Card from '../../../components/Card/Card';

const ContentFormBlock = ({ formik }) => {
  const { touched, errors } = formik;
  return (
    <Card>
      
      <h4 style={{ paddingLeft: '20px' }}>
        <b>Content</b>
      </h4>
      <GridItem>
        <GridContainer>
          <GridItem xs={4}>
            <CustomInput
              labelText="English Content *"
              formControlProps={{
                fullWidth: true,
              }}
              inputProps={{
                type: 'text',
                ...formik.getFieldProps('content.en'),
                error:
                  get(touched, 'content.en', '') &&
                  get(errors, 'content.enTitle', ''),
              }}
            />
            {get(errors, 'content.en', '') &&
              get(touched, 'content.en', '') && (
                <Error msg={get(errors, 'content.en', '')} />
              )}
          </GridItem>
          <GridItem xs={4}>
            <CustomInput
              labelText="Bangala Content *"
              formControlProps={{
                fullWidth: true,
              }}
              inputProps={{
                type: 'text',
                ...formik.getFieldProps('content.bn'),
                error:
                  get(touched, 'content.bn', '') &&
                  get(errors, 'content.bn', ''),
              }}
            />
            {get(errors, 'content.bn', '') &&
              get(touched, 'content.bn', '') && (
                <Error msg={get(errors, 'content.bn', '')} />
              )}
          </GridItem>
        </GridContainer>
      </GridItem>
    </Card>
  );
};

export default ContentFormBlock;
