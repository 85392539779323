import axiosInstance from './axiosInstance';

export const addNewFeature = async featureInput => {
  const { featureCode } = featureInput;

  const processedInfo = {
    ...featureInput,
    featureCode: `FTR_${featureCode}`,
  };

  try {
    const res = await axiosInstance.post('/features', processedInfo);
    return res.data;
  } catch (error) {
    return error.response.data;
  }
};
