import React from 'react';
import {
  Card,
  Table,
  TableHead,
  TableCell,
  TableRow,
  TableBody,
  makeStyles,
} from '@material-ui/core';
import CardBody from '../../components/Card/CardBody';
import GridItem from '../../components/Grid/GridItem';
import GridContainer from '../../components/Grid/GridContainer';

const RelativeDetails = ({familyDetails}) => {
  const relation = familyDetails.relation;
  const name = familyDetails.name;
  const mobile = familyDetails.mobile;
  const dob = familyDetails.dob;
  const gender = familyDetails.gender;
  const upi = familyDetails.upi;

  const classes = useStyles();
  return (
    <Card>
      <CardBody>
        <GridContainer>
          <GridItem md={12}>
            <h4 className={classes.subTitle}>
              <strong>{`${relation}`}</strong>
            </h4>
          </GridItem>
          <GridItem md={6}>
            <Table className={classes.table}>
              <TableHead>
               <TableRow>
                  <TableCell>Parameter</TableCell>
                  <TableCell align="right">Value</TableCell>
               </TableRow>
              </TableHead>
              <TableBody>
                <TableRow>
                  <TableCell align="left">Name</TableCell>
                  <TableCell align="right">
                    {name || '-'}
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell align="left">Mobile</TableCell>
                  <TableCell align="right">{mobile || '-'}</TableCell>
                </TableRow>
              </TableBody>
            </Table>
          </GridItem>

          <GridItem md={6}>
            <Table className={classes.table}>
              <TableHead>
                <TableRow>
                  <TableCell>Parameter</TableCell>
                  <TableCell align="right">Value</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                <TableRow>
                  <TableCell align="left">Date of Birth</TableCell>
                  <TableCell align="right">{dob || '-'}</TableCell>
                </TableRow>
                <TableRow>
                <TableCell align="left">UPI</TableCell>
                <TableCell align="right">{upi || '-'}</TableCell>
              </TableRow>
              </TableBody>
            </Table>
          </GridItem>
          <GridItem md={12}>
            <div className={classes.spacing} />
          </GridItem>
        </GridContainer>
      </CardBody>
    </Card>
  );
};

const useStyles = makeStyles({
  topSpacing: {
    marginTop: '30px',
  },
  subTitle: {
    marginTop: 0,
  },
  spacing: {
    height: '30px',
  },
  flexVCenter: {
    display: 'flex',
    alignItems: 'center',
  },
});

export default RelativeDetails;
