import React from 'react';
import { CircularProgress, makeStyles, Card, Grid } from '@material-ui/core';
import classNames from 'classnames';

const Loading = ({ msg, className, boxHeight }) => {
  const classes = useStyles();
  return (
    <Card
      className={classNames(classes.loadingCardContainer, className)}
      style={{ minHeight: boxHeight }}
    >
      <Grid
        container
        justify="center"
        alignItems="center"
        className={classes.loadingCard}
        style={{ minHeight: boxHeight }}
      >
        <CircularProgress color="secondary" className={classes.spinnerMargin} />
        {msg && msg.length && <div>{msg}</div>}
        {!msg && <div>your content is being loaded</div>}
      </Grid>
    </Card>
  );
};

const useStyles = makeStyles({
  loadingCardContainer: {
    boxShadow: 'none',
    minHeight: 'calc(100vh/3*2)',
    width: '100%',
  },
  spinnerMargin: {
    marginRight: '20px',
  },
  loadingCard: {
    minHeight: 'calc(100vh/3*2)',
    width: '100%',
  },
});

export default Loading;
