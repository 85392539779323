import { Input, makeStyles } from '@material-ui/core';
import { AddAlert } from '@material-ui/icons';
import { testResultSubmit } from 'dataService/munas/testResultSubmit';
import React, { useCallback, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import Card from '../../components/Card/Card';
import CardBody from '../../components/Card/CardBody';
import Button from '../../components/CustomButtons/Button';
import GridContainer from '../../components/Grid/GridContainer';
import GridItem from '../../components/Grid/GridItem';
import Snackbar from '../../components/Snackbar/Snackbar';
import SnackbarContent from '../../components/Snackbar/SnackbarContent';
import { getCustomerInfo } from '../../dataService/getCustomerInfo';
import { getServiceByUpi } from '../../dataService/getServiceByUpi';
import { createPatientAccount } from '../../dataService/munas/createPatientAccount';
import { getAccountIdentifierByPassport } from '../../dataService/munas/getAccountIdentifierByPassport';
import { updateMunasInfo } from '../../dataService/munas/updateMunasInfo';

const AddToMunas = () => {
  const classes = useStyles();
  const { customerCode } = useParams();

  const [isLoading, setIsLoading] = useState(false);
  const [isErrorViewVisible, setIsErrorViewVisible] = useState(false);
  const [errorMessage, setErrorMessage] = useState(false);

  const [customerInfo, setCustomerInfo] = useState(null);

  const [passportNo, setPassportNo] = useState('');
  const [profile, setProfilePic] = useState({
    preview: '',
    raw: '',
    name: '',
    type: '',
  });

  const [services, setServices] = useState([]);

  const [submitDisable, setSubmitDisable] = useState(false);
  const [snackbarConfig, setSnackbarConfig] = useState({
    open: false,
    message: '',
    color: 'success',
  });

  const snackBarClose = () => {
    setSnackbarConfig({ ...snackbarConfig, open: false });
  };

  const onSubmit = async () => {
   

    setIsLoading(true);
    if (!services.length || !customerInfo) {
      setIsErrorViewVisible(true);
      setErrorMessage('ERROR - Customer is not eligible for push to MUNAS');

      return;
    }

    setSubmitDisable(true);

    let base64 = await getBase64(profile.raw);

    const testResultPayload = {
      participant: null,
      manufacturer_of_prc_device: '',
      serial_number_of_device: '',
      manufacturer_of_reagent: '',
      calibration_date: services[0].certifiedDate
        ? services[0].certifiedDate
        : '',
      technician_or_physician_name: '',
      number_of_cycles: '',
      test_result: services[0].testResult
        ? services[0].testResult.toLowerCase()
        : '',
      test_name: services[0].serviceType,
      test_subtype_id: '',
      custom_patient_id: '',
      test_type_id: '',
      test_date: services[0].certifiedDate ? services[0].certifiedDate : '',
      documents: {
        patient_photo: {
          content: base64,
          name: profile.name,
          mime_type: profile.type,
        },
      },
      comments: '',
    };

    if (customerInfo && customerInfo.munasId) {
      testResultPayload.participant = customerInfo.munasId;
    } else {

      const isUserExistInMunas = await getAccountIdentifierByPassport(
        passportNo,
      );

      if (isUserExistInMunas.data.data.length) {
        const updateMunasInfoRes = await updateMunasInfo(customerInfo.id, {
          munasId: isUserExistInMunas.data.data[0].attributes.address,
        });

        testResultPayload.participant =
          isUserExistInMunas.data.data[0].attributes.address;
      } else {
        const patientAccountCreatePayload = {
          email: customerInfo.credential.email,
          passport: passportNo,
        };

        const res = await createPatientAccount(patientAccountCreatePayload);

        if (res.status === 'success') {
          testResultPayload.participant = res.data.data.id;
        } else {
          if (res.errors.length) {
            setIsErrorViewVisible(true);
            setErrorMessage('ERROR - ' + res.errors[0].title);
          }

          return;
        }
      }
    }

    if (testResultPayload.participant) {
      const testResultSubmitRes = await testResultSubmit(testResultPayload);

      if (testResultSubmitRes.status === 'success') {
        const updateMunasInfoRes = await updateMunasInfo(customerInfo.id, {
          isPushToMunas: true,
          isTraveller: true,
        });

        setSubmitDisable(false);
        setSnackbarConfig({
          open: true,
          message: 'Push to Munas successful!',
          icon: AddAlert,
          color: 'success',
        });
      } else {
        setSubmitDisable(false);
        setSnackbarConfig({
          open: true,
          message: 'Push to Munas error!',
          icon: AddAlert,
          color: 'danger',
        });
      }

      setIsLoading(false);
    }
  };

  const getServices = useCallback(async () => {
    const _customerInfo = await getCustomerInfo(customerCode);

    if (!_customerInfo.data) {
      setIsErrorViewVisible(true);
      setErrorMessage('ERROR - No customer found for this UPI');

      return;
    }

    setCustomerInfo(_customerInfo.data);

    const res = await getServiceByUpi(_customerInfo.data.upi);

    if (!res.data.length) {
      setIsErrorViewVisible(true);
      setErrorMessage('ERROR - Customer is not eligible for push to MUNAS');

      return;
    }

    setServices(res.data);
  }, []);

  useEffect(() => {
    getServices();
  }, []);

  const handleProfileChange = e => {
    setProfilePic({
      preview: URL.createObjectURL(e.target.files[0]),
      raw: e.target.files[0],
      name: e.target.files[0].name,
      type: e.target.files[0].type,
    });
  };

  const handlePassportNoChange = e => {
    setPassportNo(e.target.value);
  };

  const getBase64 = async file => {
    let base64 = await new Promise(resolve => {
      let fileReader = new FileReader();
      fileReader.onload = e => resolve(fileReader.result);
      fileReader.readAsDataURL(file);
    });

    return base64;
  };

  return (
    <>
      <Card style={{ backgroundColor: '#800054' }}>
        <CardBody>
          <h3 style={{ color: '#ffffff' }}>
            <b>Push to MUNAS</b>
          </h3>
        </CardBody>
      </Card>
      <GridContainer>
        {!services.length ? (
          ''
        ) : (
            <React.Fragment>
              <GridItem item xs={6}>
                <SnackbarContent
                  message={`First Name - ${customerInfo.profile.firstName}`}
                />
              </GridItem>
              <GridItem item xs={6}>
                <SnackbarContent
                  message={`Mobile - ${customerInfo.credential.mobile}`}
                />
              </GridItem>
              <GridItem item xs={6}>
                <SnackbarContent message={`UPI - ${services[0].upi}`} />
              </GridItem>
              <GridItem item xs={6}>
                <SnackbarContent
                  message={`Test Name - ${services[0].serviceType}`}
                />
              </GridItem>
              <GridItem item xs={6}>
                <SnackbarContent message={`Result - ${services[0].testResult}`} />
              </GridItem>
              <GridItem item xs={6}>
                <SnackbarContent
                  message={`Certified Date - ${services[0].certifiedDate}`}
                />
              </GridItem>
            </React.Fragment>
          )}
        <GridItem xs={6}>
          {isErrorViewVisible ? (
            <SnackbarContent message={errorMessage} close color="danger" />
          ) : (
              ''
            )}
          <div>
            <Input
              onChange={handlePassportNoChange}
              placeholder="Passport No"
              disabled={!services.length}
            />
          </div>

          <div>
            <h5>
              <label htmlFor="upload-profile">
                <input
                  type="file"
                  accept="image/*"
                  className={classes.input}
                  id="upload-profile"
                  style={{ display: 'none' }}
                  onChange={handleProfileChange}
                  disabled={!services.length}
                />
                <Button
                  disabled={!services.length}
                  variant="contained"
                  component="span"
                  className={classes.button}
                >
                  Browse Profile Picture
                </Button>
              </label>
            </h5>
            {profile.preview ? (
              <img
                src={profile.preview}
                width="200"
                height="200"
                alt="profile"
              />
            ) : (
                <></>
              )}
          </div>

          <GridContainer>
            <GridItem>
              <Button
                style={{ marginTop: 16 }}
                disabled={
                  !profile.raw ||
                  !passportNo ||
                  !services.length ||
                  !customerInfo
                }
                color="success"
                type="button"
                onClick={onSubmit}
              >
                {isLoading ? 'Please Wait' : 'Push to Munas'}
              </Button>
            </GridItem>
          </GridContainer>
        </GridItem>
      </GridContainer>
      <Snackbar
        {...snackbarConfig}
        icon={AddAlert}
        close
        closeNotification={snackBarClose}
        place="bl"
      />
    </>
  );
};

const useStyles = makeStyles({
  pageTopSpacing: {
    marginTop: '30px',
  },
  blockSpacing: {
    marginTop: '60px',
  },
});

export default AddToMunas;
