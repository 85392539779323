import axios from 'axios';
import {
  activityTrackerHost,
  clientName,
  activityTrackerToken,
} from '../config/env';

const headers = {
  'Content-Type': 'application/json',
  'X-Client-Name': clientName,
  Authorization: activityTrackerToken,
};

const axiosInstanceForActivityTracker = axios.create({
  baseURL: activityTrackerHost,
  headers,
  timeout: 3000,
});

export default axiosInstanceForActivityTracker;
