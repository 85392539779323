import axiosInstance from './axiosInstance';

export const search = async params => {
  try {
    const res = await axiosInstance.get('/search', {
      params,
      data: null,
    });
    return res.data;
  } catch (error) {
    return error.response.data;
  }
};
