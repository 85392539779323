import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core';
import { AddAlert } from '@material-ui/icons';
import { Formik } from 'formik';
import * as Yup from 'yup';
import Card from '../../components/Card/Card';
import SignInForm from './SignInForm';
import Snackbar from '../../components/Snackbar/Snackbar';
import { requestLogin, setLoginData } from '../../dataService/loginService';
import Logo from './Logo';
import CircularProgress from '@material-ui/core/CircularProgress';
import ForgotPassword from './ForgotPassword'

const Signin = () => {
  const classes = useStyles();
  const [isLoaderSpinning, setIsLoaderSpinning] = useState(false);
  const initialValues = {
    username: '',
    password: '',
  };

  const validationSchema = Yup.object({
    username: Yup.string('Your email address!')
      .email('It should be an valid email address!')
      .required('Email is required!'),
    password: Yup.string('******')
      .required('Password is required!')
      .min(8, 'Password should me at least 8 char!'),
  });

  const [snackbarConfig, setSnackbarConfig] = useState({
    open: false,
    message: '',
    color: 'success',
  });

  const snackBarClose = () => {
    setSnackbarConfig({ ...snackbarConfig, open: false });
  };

  const signInFormSubmit = async ({ username, password }) => {
    setIsLoaderSpinning(true);
    const res = await requestLogin({
      email: username,
      password,
    });

    const { status, message } = res;

    setSnackbarConfig({
      open: true,
      message:
        message || status === 'success'
          ? 'Login successful, redirecting...'
          : 'Login failed please check the credentials.',
      icon: AddAlert,
      color: status === 'success' ? 'success' : 'danger',
    });

    if (res.status === 'success') {
      const {
        data: { refreshToken, token, expiresIn },
      } = res;

      setLoginData(token, refreshToken, expiresIn);

      setTimeout(() => {
        window.location.href = `${window.location.origin}/customers`;
      }, 1000);
      setIsLoaderSpinning(true);
    }

    if (res.status == '400') {
      setIsLoaderSpinning(false);
    }
    console.log(res.status);
  };

  return (
    <div className={classes.centerItems}>
      <div className={classes.loginFormWrapper}>
        <img src="./praava_logo.png" />
        <Card>
          {isLoaderSpinning ? (
            <CircularProgress
              style={{
                position: 'absolute',
                left: '45%',
                top: '105%',
                color: '#800054',
              }}
            />
          ) : null}
          <Formik
            initialValues={initialValues}
            validationSchema={validationSchema}
            onSubmit={signInFormSubmit}
          >
            {formik => <SignInForm formik={formik} />}
          </Formik>
        <ForgotPassword forgetPW={true} />
        </Card>
        <Snackbar
          {...snackbarConfig}
          icon={AddAlert}
          close
          closeNotification={snackBarClose}
          place="bl"
        />
      </div>
    </div>
  );
};

const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
    '& > * + *': {
      marginLeft: theme.spacing(2),
    },
  },
  centerItems: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    height: '100vh',
  },
  loginFormWrapper: {
    maxWidth: '400px',
    minWidth: '400px',
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'column',
  },
}));

export default Signin;
