import axiosInstance from './axiosInstance';

export const updatePassword = async data => {
  try {
    const res = await axiosInstance.post(`/auth/updatePassword`,data);
    return res.data;
  } catch (error) {
    return error.response.data;
  }
};
