import axios from 'axios';
import {
  nebulaHost,
  clientName,
  clientVersion,
  countryCode,
  requestId,
} from '../config/env';

const token = localStorage.getItem('token');

const headers = {
  'Content-Type': 'application/json',
  'X-Client-Name': clientName,
  'X-Client-Version': clientVersion,
  'X-Country-Code': countryCode,
  'X-Request-ID': requestId,
  Authorization: token,
};

const axiosInstance = axios.create({
  baseURL: nebulaHost,
  headers,
  timeout: 30000,
});

export default axiosInstance;
