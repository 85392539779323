import React from 'react';
import { TableRow, TableCell } from '@material-ui/core';
import { Link } from 'react-router-dom';
import { EditOutlined, InfoOutlined } from '@material-ui/icons';
import Button from '../../components/CustomButtons/Button';

const FeatureCard = ({ content }) => {
  const { featureCode, featureType, featureWeight } = content;
  return (
    <TableRow>
      <TableCell component="th" scope="row">
        {featureCode}
      </TableCell>
      <TableCell align="right">{featureType}</TableCell>
      <TableCell align="right">{featureWeight}</TableCell>
      <TableCell align="right">
        <Link to={`/features/${featureCode}/edit`}>
          <Button small>Edit</Button>
        </Link>
        <Link to={`/features/${featureCode}`}>
          <Button color="success" small>
            <InfoOutlined />
            Details
          </Button>
        </Link>
      </TableCell>
    </TableRow>
  );
};

export default FeatureCard;
