import munasInstance from 'dataService/munasInstance';

export const createPatientAccount = async payload => {
  const { email, passport } = payload;

  const data = {
    email,
    passport,
  };

  try {
    const res = await munasInstance.post('/muna/identities', data);
    return res.data;
  } catch (error) {
    return error.response.errors;
  }
};
