import axiosInstance from './axiosInstance';

export const resetPassword = async data => {
  try {
    const res = await axiosInstance.post(`/auth/sendResetUrl`,data);
    return res.data;
  } catch (error) {
    return error.response.data;
  }
};
