import React, { useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/core';
import GridItem from '../../components/Grid/GridItem';
import GridContainer from '../../components/Grid/GridContainer';
import Card from '../../components/Card/Card';
import CardBody from '../../components/Card/CardBody';
import Header from '../../components/Header';
import Loading from '../../components/Loading';
import { getDiscountInfo } from './../../dataService/getDiscountInfo';
import DiscountInfoDetailsBody from './../../components/DiscountInfoDetailsBody/index';

const DiscountDetails = props => {
  const classes = useStyles();
  const [discountInfo, setDiscountInfo] = useState({});
  const [isLoading, setIsLoading] = useState(true);

  const requestDiscountInfo = async promoCode => {
    const res = await getDiscountInfo(promoCode);
    setIsLoading(false);
    if (res.status !== 'error') setDiscountInfo(res.data);
  };

  useEffect(() => {
    requestDiscountInfo(props.match.params.promoCode);
  }, []);

  if (isLoading) {
    return (
      <div className={classes.topSpacing}>
        <Loading />
      </div>
    );
  }

  if (!discountInfo.promoCode) {
    return (
      <Card>
        <CardBody>
          <h4>Discount info not found</h4>
        </CardBody>
      </Card>
    );
  }

  const { promoCode } = discountInfo;

  return (
    <div>
      <Card style={{ backgroundColor: '#800054' }}>
        <CardBody>
          <h3 style={{ color: '#ffffff' }}>
            <b>Promo Code: {promoCode} </b>
          </h3>
        </CardBody>
      </Card>
      <GridContainer>
        <GridItem xs={12} className={classes.topSpacing}>
          <div className={classes.topSpacing} />
          <DiscountInfoDetailsBody discountInfo={discountInfo} />
        </GridItem>
      </GridContainer>
    </div>
  );
};

const useStyles = makeStyles({
  topSpacing: {
    marginTop: '30px',
  },
  subTitle: {
    fontSize: '24px',
    margin: '10px 0 20px 0',
  },
  spacing: {
    height: '30px',
  },
});

export default DiscountDetails;
