import React from 'react';
import { Router, Route, Switch } from 'react-router-dom';
import { createBrowserHistory } from 'history';
import Signin from './pages/Signin';
import ResetPassword from './pages/Signin/ResetPassword';
import ResetCustomer from './pages/Signin/ResetCustomer';
import Routes from './Routes';
import PrivateRoute from './components/PrivateRoute';
import Customers from 'pages/Customers';
import CustomerPublicDetails from 'pages/CustomerPublicDetails';

const hist = createBrowserHistory();
const App = props => {
  return (
    <Router history={hist}>
      <Switch>
        <Route
          exact
          {...props}
          component={Customers}
          path="/search-customer-using-upi"
        />
        <Route
          exact
          {...props}
          component={CustomerPublicDetails}
          path="/search-customer-using-upi/:customerCode"
        />
        <Route
          exact
          path="/signin"
          render={routeProps => <Signin {...routeProps} {...props} />}
        />
        <Route
          exact
          path="/resetPassword"
          render={routeProps => <ResetPassword {...routeProps} {...props} />}
        />
        <Route
          exact
          path="/resetCustomer"
          render={routeProps => <ResetCustomer {...routeProps} {...props} />}
        />
        <PrivateRoute
          path="/"
          component={routeProps => <Routes {...routeProps} {...props} />}
        />
      </Switch>
    </Router>
  );
};

export default App;
