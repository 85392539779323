import axiosInstanceForActivityTracker from './axiosInstanceForActivityTracker';

export const updateActivity = async activityInput => {
  try {
    const res = await axiosInstanceForActivityTracker.put(
      `/activity/${activityInput.activityCode}`,
      {
        title: activityInput.title,
      },
    );
    return res.data;
  } catch (error) {
    return error.response.data;
  }
};
