import React, { useState, useEffect, useCallback } from 'react';
import { makeStyles, Grid } from '@material-ui/core';
import { KeyboardArrowRight, KeyboardArrowLeft } from '@material-ui/icons';
import TableFormatter from '../../components/TableFormatter';
import Button from '../../components/CustomButtons/Button';
import GridItem from '../../components/Grid/GridItem';
import GridContainer from '../../components/Grid/GridContainer';
import { search as allFamilyMembers } from '../../dataService/search';
import FamilyMemberRow from './FamilyMemberRow';
import Header from '../../components/Header';
import Filter from './Filter';
import NoContentCard from '../../components/NoContentCard';
import Loading from '../../components/Loading';
import { createMuiTheme, MuiThemeProvider } from '@material-ui/core/styles';
import LastPageIcon from '@material-ui/icons/LastPage';
import FirstPageIcon from '@material-ui/icons/FirstPage';
export default () => {
    const classes = useStyles();
    const [rows, setRows] = useState([]);
    const [isLoading, setIsLoading] = useState(true);
    const [pageNumber, setPageNumber] = useState(0);
    const [resultCount, setResultCount] = useState(20);
    const [pageCount, setPageCount] = useState(0);
    const [totalCount, setTotalCount] = useState(0);
    const tableHead = [
        { name: 'UPI', align: 'left' },
        { name: 'name', align: 'left' },
        { name: 'relation', align: 'left' },
        { name: 'Mobile', align: 'left' },
        { name: 'DOB', align: 'left' },
        { name: 'Created At', align: 'left' },
        { name: 'Push To Munas', align: 'left' },
    ];
    const [searchTerms, setSearchTerms] = useState({
        _entity: 'FamilyMember',
        _select: '*',
        _page: 1,
    });

    const createData = (
        customerCode,
        upi,
        name,
        relation,
        mobile,
        dob,
        createdAt
    ) => {
        return {
            customerCode,
            upi,
            name,
            relation,
            mobile,
            dob,
            createdAt
        };
    };

    const createUserRows = useCallback(async () => {
        const res = await allFamilyMembers(searchTerms);
        console.log(res.data);
        setIsLoading(true);
        const rows = [];

        if (res.status !== 'success') return [];

        res.data.forEach(item => {
            const {
                customerCode,
                upi,
                name,
                relation,
                mobile,
                dob,
                createdAt
            } = item;
            rows.push(
                createData(
                    customerCode,
                    upi,
                    name,
                    relation,
                    mobile,
                    dob,
                    createdAt
                ),
            );
        });
        const count = Math.ceil(res.meta.totalCount / 20);
        console.log(count);
        setRows(rows);
        setIsLoading(false);
        setPageNumber(res.meta.page);
        setResultCount(res.meta.resultCount);
        setTotalCount(res.meta.totalCount);
        setPageCount(count);
    }, [searchTerms]);

    useEffect(() => {
        createUserRows();
    }, [searchTerms]);

    const prevPage = () => {
        setSearchTerms({
            ...searchTerms,
            ...{ _page: searchTerms._page - 1 },
        });
    };

    const nextPage = () => {
        setSearchTerms({
            ...searchTerms,
            ...{ _page: searchTerms._page + 1 },
        });
    };

    const lastPage = () => {
        setSearchTerms({
            ...searchTerms,
            ...{ _page: searchTerms._page = pageCount },
        });
    };

    const firstPage = () => {
        setSearchTerms({
            ...searchTerms,
            ...{ _page: searchTerms._page = 1 },
        });
    };


    return (
        <>
            <React.Fragment>
                <h3>Available Family Members</h3>
            </React.Fragment>
            <Filter searchTerms={searchTerms} setSearchTerms={setSearchTerms} />
            {isLoading && (
                <Loading
                    className={classes.topSpacing}
                    boxHeight="calc(100vh - 120px)"
                />
            )}

            {!isLoading && !rows.length && (
                <Grid container justify="flex-end" className={classes.topSpacing}>
                    <Grid item md={12}>
                        <NoContentCard message="No customers found" />
                        <Pagination
                            nextPage={nextPage}
                            prevPage={prevPage}
                            pageNumber={pageNumber}
                            resultCount={resultCount}
                            pageCount={pageCount}
                            lastPage={lastPage}
                            firstPage={firstPage}
                        />
                    </Grid>
                </Grid>
            )}

            {rows.length > 0 && !isLoading && (
                <TableFormatter
                    tableHead={tableHead}
                    height="calc(100vh - 150px)"
                    pagination={() => (
                        <Pagination
                            nextPage={nextPage}
                            prevPage={prevPage}
                            pageNumber={pageNumber}
                            resultCount={resultCount}
                            pageCount={pageCount}
                            lastPage={lastPage}
                            firstPage={firstPage}
                        />
                    )}
                >
                    {rows.map(row => (
                        <MuiThemeProvider theme={theme}>
                            <FamilyMemberRow key={row.customerCode} {...row} />
                        </MuiThemeProvider>
                    ))}
                </TableFormatter>
            )}
        </>
    );
};

const Pagination = ({ nextPage, prevPage, pageNumber, resultCount, pageCount, lastPage, firstPage }) => {
    const classes = useStyles();
    return (
        <div className={classes.pagination}>
            <GridContainer direction="row" justify="center">
                <GridItem>
                    <Button
                        onClick={firstPage}
                        color="transparent"
                        round
                        small
                        disabled={pageNumber === 1}
                    >
                        <FirstPageIcon />
                    </Button>
                </GridItem>
                {pageNumber === 1 ? null : (
                    <GridItem>
                        <Button
                            onClick={prevPage}
                            color="transparent"
                            round
                            small
                            disabled={pageNumber === 1}
                        >
                            <KeyboardArrowLeft />
                        </Button>
                    </GridItem>
                )}
                <p>
                    <strong>
                        {pageNumber}
                    </strong>
                </p>
                <GridItem>
                    <Button
                        onClick={nextPage}
                        color="transparent"
                        round
                        small
                        disabled={resultCount < 20}
                    >
                        <KeyboardArrowRight />
                    </Button>
                </GridItem>
                <GridItem>
                    <Button
                        onClick={lastPage}
                        color="transparent"
                        round
                        small
                        disabled={resultCount < 20}
                    >
                        <strong>{pageCount}</strong>
                        <LastPageIcon />
                    </Button>
                </GridItem>
            </GridContainer>
        </div>
    );
};

const useStyles = makeStyles({
    topSpacing: {
        marginTop: '30px',
    },
    marginNone: {
        margin: 0,
    },
    wrapperCard: {
        marginBottom: 0,
    },
    pagination: {
        paddingTop: '15px',
    },
    tableContainer: {
        height: 'calc(100vh - 373px)',
        '& .MuiTableCell-stickyHeader': {
            background: 'white',
        },
    },
    notFoundContainer: {
        display: 'flex',
        alignItems: 'center',
        width: '100%',
        justifyContent: 'center',
        height: 'calc(100% - 57px)',
    },
});

const theme = createMuiTheme({
    overrides: {
        MuiTableCell: {
            root: {
                paddingTop: 4,
                paddingBottom: 4,
                paddingRight: 5,
            },
        },
    },
});
