import React from 'react';
import { TableRow, TableCell } from '@material-ui/core';
import { Link } from 'react-router-dom';
import { EditOutlined, InfoOutlined } from '@material-ui/icons';
import Button from '../../components/CustomButtons/Button';

const DiscountRow = ({
  promoCode,
  title,
  discountAmount,
  promoStart,
  promoEnd,
  status,
  serviceType,
  channel,
  isPrecentage,
}) => {
  if (isPrecentage === true) {
    var checkDiscountYes = discountAmount.concat('%');
  } else {
    var checkDiscountNo = discountAmount;
  }
  return (
    <TableRow>
      <TableCell>{promoCode}</TableCell>
      <TableCell>{title}</TableCell>
      <TableCell>{checkDiscountYes || checkDiscountNo}</TableCell>
      <TableCell>{promoStart.slice(0, 10)}</TableCell>
      <TableCell>{promoEnd.slice(0, 10)}</TableCell>
      <TableCell>{serviceType.join(' ,')}</TableCell>
      <TableCell>{channel.join(' ,')}</TableCell>
      <TableCell
        align="right"
        style={{ color: status === 'Active' ? 'green' : 'red' }}
      >
        <strong>{status}</strong>
      </TableCell>
      <TableCell align="right">
        <Link to={`/manage/discounts/${promoCode}/edit`}>
          <Button small>Edit</Button>
        </Link>
        <Link to={`/discounts/${promoCode}`}>
          <Button color="success" small>
            <InfoOutlined />
            Details
          </Button>
        </Link>
      </TableCell>
    </TableRow>
  );
};

export default DiscountRow;
