import React, { useState, useEffect, useCallback } from 'react';
import { makeStyles, Grid } from '@material-ui/core';
import TableFormatter from '../../components/TableFormatter';
import Loading from '../../components/Loading';
import { search as allFilterUsers } from '../../dataService/search';
import UserRow from './UserRow';
import Header from '../../components/Header';
import NoContentCard from '../../components/NoContentCard';
import { createMuiTheme, MuiThemeProvider } from '@material-ui/core/styles';
import { Link } from 'react-router-dom';
import { KeyboardArrowRight, KeyboardArrowLeft } from '@material-ui/icons';
import Button from '../../components/CustomButtons/Button';
import GridItem from '../../components/Grid/GridItem';
import GridContainer from '../../components/Grid/GridContainer';
import LastPageIcon from '@material-ui/icons/LastPage';
import FirstPageIcon from '@material-ui/icons/FirstPage';

import Filter from './Filter';
export default () => {
  const classes = useStyles();
  const [isLoading, setIsLoading] = useState(true);
  const [rows, setRows] = useState([]);
  const [pageNumber, setPageNumber] = useState(0);
  const [resultCount, setResultCount] = useState(20);
  const [pageCount, setPageCount] = useState(0);
  const tableHead = [
    { name: 'Name', align: 'left' },
    { name: 'Email', align: 'left' },
    { name: 'Mobile', align: 'right' },
    { name: 'Role', align: 'right' },
    { name: 'Status', align: 'right' },
    { name: 'Action', align: 'right' },
  ];

  const [searchTerms, setSearchTerms] = useState({
    _entity: 'InternalUser',
    _select: 'status,role,name,credential.email,credential.mobile',
    role: 'Admin',
    _page: 1,
  });

  const createData = (id, name, email, role, status, mobile) => {
    return { id, name, email, role, status, mobile };
  };

  const createUserRows = useCallback(async () => {
    const res = await allFilterUsers(searchTerms);
    setIsLoading(true);
    const rows = [];

    if (res.status !== 'success') return [];

    res.data.forEach(item => {
      const { id, name, role, status, email, mobile } = item;
      rows.push(createData(id, name, email, role, status, mobile));
    });
    const count = Math.ceil(res.meta.totalCount / 20);
    setRows(rows);
    setIsLoading(false);
    setPageNumber(res.meta.page);
    setResultCount(res.meta.resultCount);
    setPageCount(count);
  }, [searchTerms]);

  useEffect(() => {
    createUserRows();
  }, [searchTerms]);

  const prevPage = () => {
    setSearchTerms({
      ...searchTerms,
      // eslint-disable-next-line no-underscore-dangle
      ...{ _page: searchTerms._page - 1 },
    });
  };

  const nextPage = () => {
    setSearchTerms({
      ...searchTerms,
      // eslint-disable-next-line no-underscore-dangle
      ...{ _page: searchTerms._page + 1 },
    });
  };

  const lastPage = () => {
    setSearchTerms({
      ...searchTerms,
      // eslint-disable-next-line no-underscore-dangle
      ...{ _page: searchTerms._page = pageCount },
    });
  };

  const firstPage = () => {
    setSearchTerms({
      ...searchTerms,
      // eslint-disable-next-line no-underscore-dangle
      ...{ _page: searchTerms._page = 1 },
    });
  };

  return (
    <div>
      <React.Fragment>
        <h3>All Available Users</h3>
        <Link to={`/manage/users/new`}>
          <Button color="success" small>
            Add new Users
          </Button>
        </Link>
      </React.Fragment>
      <Filter searchTerms={searchTerms} setSearchTerms={setSearchTerms} />
      {isLoading && (
        <Loading
          className={classes.topSpacing}
          boxHeight="calc(100vh - 120px)"
        />
      )}

      {!isLoading && !rows.length && (
        <Grid container justify="flex-end" className={classes.topSpacing}>
          <Grid item md={12}>
            <NoContentCard message="No internal users found" />
            <Pagination
              nextPage={nextPage}
              prevPage={prevPage}
              pageNumber={pageNumber}
              resultCount={resultCount}
              pageCount={pageCount}
              lastPage={lastPage}
              firstPage={firstPage}
            />
          </Grid>
        </Grid>
      )}

      {rows.length > 0 && !isLoading > 0 && (
        <TableFormatter tableHead={tableHead} height="fit-content" pagination={() => (
          <Pagination
            nextPage={nextPage}
            prevPage={prevPage}
            pageNumber={pageNumber}
            resultCount={resultCount}
            pageCount={pageCount}
            lastPage={lastPage}
            firstPage={firstPage}
          />
        )}>
          {rows.map(row => (
            <MuiThemeProvider theme={theme}>
              <UserRow key={row.id} {...row} />
            </MuiThemeProvider>
          ))}
        </TableFormatter>
      )}
    </div>
  );
};

const useStyles = makeStyles({
  topSpacing: {
    marginTop: '30px',
  },
});

const Pagination = ({ nextPage, prevPage, pageNumber, resultCount, pageCount, lastPage, firstPage }) => {
  const classes = useStyles();
  return (
    <div className={classes.pagination}>
      <GridContainer direction="row" justify="center">
        <GridItem>
          <Button
            onClick={firstPage}
            color="transparent"
            round
            small
            disabled={pageNumber === 1}
          >
            <FirstPageIcon />
          </Button>
        </GridItem>
        {pageNumber === 1 ? null : (
          <GridItem>
            <Button
              onClick={prevPage}
              color="transparent"
              round
              small
              disabled={pageNumber === 1}
            >
              <KeyboardArrowLeft />
            </Button>
          </GridItem>
        )}
        <p>
          <strong>
            {pageNumber}
          </strong>
        </p>
        <GridItem>
          <Button
            onClick={nextPage}
            color="transparent"
            round
            small
            disabled={resultCount < 20}
          >
            <KeyboardArrowRight />
          </Button>
        </GridItem>
        <GridItem>
          <Button
            onClick={lastPage}
            color="transparent"
            round
            small
            disabled={resultCount < 20}
          >
            <strong>{pageCount}</strong>
            <LastPageIcon />
          </Button>
        </GridItem>
      </GridContainer>
    </div>
  );
};

const theme = createMuiTheme({
  overrides: {
    MuiTableCell: {
      root: {
        paddingTop: 4,
        paddingBottom: 4,
        paddingRight: 5,
      },
    },
  },
});
