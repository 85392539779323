import React, { useState, useEffect, useCallback } from 'react';
import { makeStyles, Grid } from '@material-ui/core';
import { search as allPackages } from '../../dataService/search';
import PackageRow from './PackageRow';
import Header from '../../components/Header';
import Filter from './Filter';
import NoContentCard from '../../components/NoContentCard';
import Loading from '../../components/Loading';
import TableFormatter from '../../components/TableFormatter';
import { KeyboardArrowRight, KeyboardArrowLeft } from '@material-ui/icons';
import Button from '../../components/CustomButtons/Button';
import GridItem from '../../components/Grid/GridItem';
import GridContainer from '../../components/Grid/GridContainer';
import { createMuiTheme, MuiThemeProvider } from '@material-ui/core/styles';
import { Link } from 'react-router-dom';
import LastPageIcon from '@material-ui/icons/LastPage';
import FirstPageIcon from '@material-ui/icons/FirstPage';
const Package = () => {
  const classes = useStyles();
  const [packages, setRows] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [pageNumber, setPageNumber] = useState(0);
  const [resultCount, setResultCount] = useState(20);
  const [pageCount, setPageCount] = useState(0);
  const [totalCount, setTotalCount] = useState(0);
  const tableHead = [
    { name: 'Package Type', align: 'left' },
    { name: 'Package Title', align: 'left' },
    { name: 'Package Code', align: 'left' },
    { name: 'Status', align: 'right' },
    { name: 'Price', align: 'right' },
    { name: 'Actions', align: 'right' },
  ];

  const [searchTerms, setSearchTerms] = useState({
    _entity: 'Package',
    _select:
      '*',
    _page: 1,
    status: 'Active'
  });

  const createData = (
    packageType,
    packageTitle,
    packageCode,
    packageWeight,
    status,
    price,
  ) => {
    return { packageType, packageTitle, packageCode, packageWeight, status, price };
  };

  const createPackageRows = useCallback(async () => {
    const res = await allPackages(searchTerms);
    setIsLoading(true);
    const packages = [];

    if (res.status !== 'success') return [];

    res.data.forEach(item => {
      const { packageType, packageTitle, packageCode, packageWeight, status, price } = item;

      packages.push(
        createData(packageType, packageTitle, packageCode, packageWeight, status, price),
      );
    });
    const count = Math.ceil(res.meta.totalCount / 20);
    console.log(count);
    setRows(packages);
    setIsLoading(false);
    setPageNumber(res.meta.page);
    setResultCount(res.meta.resultCount);
    setTotalCount(res.meta.totalCount);
    setPageCount(count);
  }, [searchTerms]);

  useEffect(() => {
    createPackageRows();
  }, [searchTerms]);

  const prevPage = () => {
    setSearchTerms({
      ...searchTerms,
      // eslint-disable-next-line no-underscore-dangle
      ...{ _page: searchTerms._page - 1 },
    });
  };

  const nextPage = () => {
    setSearchTerms({
      ...searchTerms,
      // eslint-disable-next-line no-underscore-dangle
      ...{ _page: searchTerms._page + 1 },
    });
  };

  const lastPage = () => {
    setSearchTerms({
      ...searchTerms,
      // eslint-disable-next-line no-underscore-dangle
      ...{ _page: searchTerms._page = pageCount },
    });
  };

  const firstPage = () => {
    setSearchTerms({
      ...searchTerms,
      // eslint-disable-next-line no-underscore-dangle
      ...{ _page: searchTerms._page = 1 },
    });
  };

  return (
    <div>
      
      <React.Fragment>
        <h3>All Available Packages</h3>
        <Link to={`/packages/new`}>
          <Button color="success" small>
            Add new package
          </Button>
        </Link>
      </React.Fragment>
      <Filter searchTerms={searchTerms} setSearchTerms={setSearchTerms} />
      {isLoading && (
        <Loading
          className={classes.topSpacing}
          boxHeight="calc(100vh - 120px)"
        />
      )}

      {!isLoading && !packages.length && (
        <Grid container justify="flex-end" className={classes.topSpacing}>
          <Grid item md={12}>
            <NoContentCard message="No packages found" />
            <Pagination
              nextPage={nextPage}
              prevPage={prevPage}
              pageNumber={pageNumber}
              resultCount={resultCount}
              pageCount={pageCount}
              lastPage={lastPage}
              firstPage={firstPage}
            />
          </Grid>
        </Grid>
      )}

      {packages.length > 0 && !isLoading && (
        <TableFormatter
          tableHead={tableHead}
          pagination={() => (
            <Pagination
              nextPage={nextPage}
              prevPage={prevPage}
              pageNumber={pageNumber}
              resultCount={resultCount}
              pageCount={pageCount}
              lastPage={lastPage}
              firstPage={firstPage}
            />
          )}
        >
          {packages.map(row => (
            <MuiThemeProvider theme={theme}>
              <PackageRow key={row.packageCode} content={row} />
            </MuiThemeProvider>
          ))}
        </TableFormatter>
      )}
    </div>
  );
};

const Pagination = ({ nextPage, prevPage, pageNumber, resultCount, pageCount, lastPage, firstPage }) => {
  const classes = useStyles();
  return (
    <div className={classes.pagination}>
      <GridContainer direction="row" justify="center">
      <GridItem>
          <Button
            onClick={firstPage}
            color="transparent"
            round
            small
            disabled={pageNumber === 1}
          >
            <FirstPageIcon />
          </Button>
        </GridItem>
        { pageNumber === 1 ? null : (
        <GridItem>
          <Button
            onClick={prevPage}
            color="transparent"
            round
            small
            disabled={pageNumber === 1}
          >
            <KeyboardArrowLeft />
          </Button>
        </GridItem>
        )}
        <p>
          <strong>
            {pageNumber}
          </strong>
        </p>
        <GridItem>
          <Button
            onClick={nextPage}
            color="transparent"
            round
            small
            disabled={resultCount < 20}
          >
            <KeyboardArrowRight />
          </Button>
        </GridItem>
        <GridItem>
          <Button
            onClick={lastPage}
            color="transparent"
            round
            small
            disabled={resultCount < 20}
          >
            <strong>{pageCount}</strong>
            <LastPageIcon />
          </Button>
        </GridItem>
      </GridContainer>
    </div>
  );
};

const useStyles = makeStyles({
  topSpacing: {
    marginTop: '30px',
  },
});

const theme = createMuiTheme({
  overrides: {
    MuiTableCell: {
      root: {
        paddingTop: 4,
        paddingBottom: 4,
        paddingRight: 5,
      },
    },
  },
});

export default Package;
