import React, { useState, useEffect } from 'react';
import {
  makeStyles,
  Grid,
  FormControlLabel,
  FormControl,
  FormLabel,
  RadioGroup,
  Radio,
  Select,
  MenuItem,
  InputLabel,
  TextField,
} from '@material-ui/core';
import Card from '../../components/Card/Card';
import CardBody from '../../components/Card/CardBody';
import { userRoles } from '../../config/env.json';

let queue;
const Filter = ({ searchTerms, setSearchTerms }) => {
  const classes = useStyles();
  const [userStatus, setUserStatus] = useState('Active');
  const [role, setRole] = useState('Admin');

  useEffect(() => {
    const updatedSearchTerms = {
      ...searchTerms,
      ...{ status: userStatus },
    };

    if (!role) {
      delete updatedSearchTerms['role'];
      setSearchTerms(updatedSearchTerms);
    }

    if (role.length > 2) {
      updatedSearchTerms['role'] = role;
      clearTimeout(queue);
      queue = setTimeout(() => {
        setSearchTerms(updatedSearchTerms);
      }, 500);
    }
  }, [role]);

  useEffect(() => {
    const updatedSearchTerms = {
      ...searchTerms,
      ...{ status: userStatus },
    };

    if (!userStatus) {
      delete updatedSearchTerms['status'];
      setSearchTerms(updatedSearchTerms);
    }

    if (userStatus.length > 2) {
      updatedSearchTerms['status'] = userStatus;
      clearTimeout(queue);
      queue = setTimeout(() => {
        setSearchTerms(updatedSearchTerms);
      }, 500);
    }
  }, [userStatus]);

  return (
    <Card>
      <CardBody>
        <h4 className={classes.filterTitle}>Search User Details</h4>
        <Grid container spacing={2}>
          <Grid item md={4}>
            <FormControl variant="outlined" className={classes.formControl}>
              <FormLabel
                component="legend"
                style={{ position: 'relative', top: '-5px' }}
              >
                Customer Status
              </FormLabel>
              <Select
                value={userStatus}
                row
                onChange={ev => setUserStatus(ev.target.value)}
                style={{ position: 'relative', top: '-5px' }}
              >
                <MenuItem value="">Select User Status</MenuItem>
                <MenuItem value="Active">Active</MenuItem>
                <MenuItem value="Inactive">Inactive</MenuItem>
              </Select>
            </FormControl>
          </Grid>
          <Grid item md={6}>
            <FormControl variant="outlined" className={classes.formControl}>
              <FormLabel
                component="legend"
                style={{ position: 'relative', top: '-5px' }}
              >
                User Role
              </FormLabel>
              <Select
                value={role}
                row
                onChange={ev => setRole(ev.target.value)}
              >
                <MenuItem>Select User Role</MenuItem>
                {userRoles.map(userRoles => (
                  <MenuItem
                    key={userRoles.id}
                    value={userRoles.value.toString()}
                  >
                    {userRoles.label}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>
        </Grid>
      </CardBody>
    </Card>
  );
};

const useStyles = makeStyles({
  topSpacing: {
    marginTop: '30px',
  },
  filterTitle: {
    margin: '0 0 15px 0',
  },
  marginNone: {
    margin: 0,
  },
  formControl: {
    '& legend': {
      margin: '0 200px 0 0',
    },
  },
  fullWidth: {
    width: '100%',
  },
  formGroup: {
    '& label': {
      margin: 0,
    },
  },
});

export default Filter;
