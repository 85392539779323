import React, { useState, useEffect } from 'react';
import { Formik } from 'formik';
import * as Yup from 'yup';
import { makeStyles } from '@material-ui/core';
import { AddAlert } from '@material-ui/icons';
import GridItem from '../../components/Grid/GridItem';
import CardBody from '../../components/Card/CardBody';
import GridContainer from '../../components/Grid/GridContainer';
import Card from '../../components/Card/Card';
import Snackbar from '../../components/Snackbar/Snackbar';
import GeneralInputFormBlock from './formParts/GeneralInputFormBlock';
import ActivationRulesFormBlock from './formParts/ActivationRulesFormBlock';
import ContentFormBlock from './formParts/ContentFormBlock';
import Header from '../../components/Header';
import Button from '../../components/CustomButtons/Button';
import { updatePackage } from '../../dataService/updatePackage';
import { getPackageDetails } from '../../dataService/getPackageDetails';
import { yupSchemaObject, initialValues } from './formSchema';
import Loading from '../../components/Loading/index';

const EditPackage = ({
  match: {
    params: { packageCode },
  },
}) => {
  const classes = useStyles();
  const validationSchema = Yup.object(yupSchemaObject);
  const [formValues, setFormValues] = useState(initialValues);
  const [isLoaded, setIsLoaded] = useState(false);
  const [submitDisable, setSubmitDisable] = useState(false);
  const [snackbarConfig, setSnackbarConfig] = useState({
    open: false,
    message: '',
    color: 'success',
  });

  const snackBarClose = () => {
    setSnackbarConfig({ ...snackbarConfig, open: false });
  };

  const setSchemaAndInitialValueForEditForm = async packageCode => {
    const { data: formValues } = await getPackageDetails(packageCode, true);
    setFormValues(formValues);
    setIsLoaded(true);
  };

  useEffect(() => {
    setSchemaAndInitialValueForEditForm(packageCode);
  }, []);

  const onSubmit = async values => {
    setSubmitDisable(true);

    const { status, message } = await updatePackage(values);

    if (status === 'success') {
      setSubmitDisable(false);
      setSnackbarConfig({
        open: true,
        message,
        icon: AddAlert,
        color: 'success',
      });
      setSchemaAndInitialValueForEditForm(packageCode);
    } else {
      setSubmitDisable(false);
      setSnackbarConfig({
        open: true,
        message,
        icon: AddAlert,
        color: 'danger',
      });
    }
  };

  if (!isLoaded) {
    return (
      <>
        {/* <Header title="Edit Package" /> */}
        <Card style={{ backgroundColor: '#800054' }}>
          <CardBody>
            <h3 style={{ color: '#ffffff' }}>
              <b>Edit Package: {packageCode}</b>
            </h3>
          </CardBody>
        </Card>
        <Loading
          className={classes.pageTopSpacing}
          boxHeight="calc(100vh - 124px)"
        />
      </>
    );
  }

  return (
    <>
      {/* <Header title={`Edit Package: ${formValues.packageCode}`} /> */}
      <Card style={{ backgroundColor: '#800054' }}>
        <CardBody>
          <h3 style={{ color: '#ffffff' }}>
            <b>Edit Package: {packageCode}</b>
          </h3>
        </CardBody>
      </Card>
      <Formik
        initialValues={formValues}
        enableReinitialize
        validationSchema={validationSchema}
        onSubmit={onSubmit}
      >
        {formik => (
          <form
            className={classes.pageTopSpacing}
            onSubmit={formik.handleSubmit}
          >
            <GridContainer>
              <GridItem xs={12}>
                <GeneralInputFormBlock formik={formik} isEdit />

                <div className={classes.blockSpacing} />
                <ContentFormBlock formik={formik} />

                <div className={classes.blockSpacing} />
                <ActivationRulesFormBlock formik={formik} />

                <Card>
                  <CardBody>
                    <GridContainer>
                      <GridItem>
                        <Button
                          disabled={
                            submitDisable ||
                            (formik.isValid && !formik.dirty) ||
                            !formik.isValid
                          }
                          color="success"
                          type="submit"
                        >
                          update package
                        </Button>
                      </GridItem>
                    </GridContainer>
                  </CardBody>
                </Card>
              </GridItem>
            </GridContainer>
          </form>
        )}
      </Formik>
      <Snackbar
        {...snackbarConfig}
        icon={AddAlert}
        close
        closeNotification={snackBarClose}
        place="bl"
      />
    </>
  );
};

const useStyles = makeStyles({
  pageTopSpacing: {
    marginTop: '30px',
  },
  blockSpacing: {
    marginTop: '60px',
  },
});

export default EditPackage;
