import axiosInstance from './axiosInstance';
import axiosInstanceUploadFile from './axiosInstanceForUploadFile';

export const updateDocument = async payload => {
  const { customerId, documentType, documentNumber, document } = payload;

  const data = {
    customerId,
    documentType,
    documentNumber,
    document,
  };

  try {
    const res = await axiosInstance.post('/customers/updateDocument', data);
    return res.data;
  } catch (error) {
    return error.response.data;
  }
};

export const uploadDocument = async payload => {
  try {
    const res = await axiosInstanceUploadFile.post('/upload', payload);
    return res.data;
  } catch (error) {
    return error.response.data;
  }
};
