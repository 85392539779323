import React, { useState } from 'react';
import { TableRow, TableCell } from '@material-ui/core';
import { Link } from 'react-router-dom';
import { EditOutlined, InfoOutlined } from '@material-ui/icons';
import InfoIcon from '@material-ui/icons/Info';
import Button from '../../components/CustomButtons/Button';
import MoreHorizIcon from '@material-ui/icons/MoreHoriz';
import { IconButton } from '@material-ui/core';
import { getCustomerInfo } from './../../dataService/getCustomerInfo';
import { getFamilyMemberDetails } from './../../dataService/getFamilyMemberDetails';
import Popover from '@material-ui/core/Popover';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import CircularProgress from '@material-ui/core/CircularProgress';
import Badge from '@material-ui/core/Badge'
import AccessibilityNewIcon from '@material-ui/icons/AccessibilityNew';
import DialogTitle from '@material-ui/core/DialogTitle';
import Dialog from '@material-ui/core/Dialog';
import CustomerSubscriptionDetailsBody from '../../components/CustomerSubscriptionDetailsBody';
import Loading from '../../components/Loading';
import CloseIcon from '@material-ui/icons/Close';
import Divider from '@material-ui/core/Divider';
import moment from 'moment';

const useStyles = makeStyles((theme) => ({
    popover: {
        pointerEvents: 'none',
    },
    paper: {
        padding: theme.spacing(1),
    },
    packagekk: {
        height: '300px',
        width: '300px'
    },
    container: {
        display: "flex",
        justifyContent: "space-between",
    },
    content1: {
        justifySelf: "start",
    },
    content2: {
        justifySelf: "start",
    }
}));

const FamilyMemberRow = ({
    customerCode,
    upi,
    name,
    relation,
    mobile,
    dob,
    createdAt
}) => {
    const classes = useStyles();
    const [isLoading, setIsLoading] = useState(true);
    const [packageFlag, setPackageFlag] = useState(false);
    const [familyFlag, setFamilyFlag] = useState(false);
    const [anchorEl, setAnchorEl] = React.useState(null);
    const [packageLIst, setPackageLIst] = useState([])
    const [familyLIst, setFamilyLIst] = useState([])
    const [packageShowFlage, setPackageShowFlage] = useState(false);
    const [subscriptionDetails, setSubscriptionDetails] = useState({});
    const [packageDialogOpen, setPackageDialogOpen] = useState(false);
    const [familyDialogOpen, setFamilyDialogOpen] = useState(false);

    const handleClosePackage = () => {
        setPackageDialogOpen(false);
    }
    const handleCloseFamily = () => {
        setFamilyDialogOpen(false);
    }

    const closeIcon = () => {
        setPackageDialogOpen(false);
        setFamilyDialogOpen(false);
    }

    return (
        <TableRow>
            <Dialog
                contentStyle={{ width: "100%", maxWidth: "none" }}
                onClose={handleClosePackage}
                aria-labelledby="simple-dialog-title"
                scroll={'body'}
                open={packageDialogOpen}>
                {!isLoading && <div id="simple-dialog-title" style={{ textAlign: "end" }}>
                    <IconButton >
                        <CloseIcon onClick={closeIcon} />
                    </IconButton>
                </div>}
                {isLoading && (
                    <Loading
                        className={classes.topSpacing}
                        boxHeight="calc(50vh - 120px)"
                    />
                )}
                {packageFlag && subscriptionDetails.currentPackages.map((data) => {
                    return (<>
                        <CustomerSubscriptionDetailsBody subscriptionDetails={data} />
                        <Divider light />
                    </>)
                })}
            </Dialog>
            <Dialog
                contentStyle={{ width: "100%", maxWidth: "none" }}
                onClose={handleCloseFamily}
                aria-labelledby="simple-dialog-title"
                scroll={'body'}
                open={familyDialogOpen}>
                {!isLoading && <div style={{ textAlign: "end" }} >
                    <IconButton >
                        <CloseIcon onClick={closeIcon} />
                    </IconButton>
                </div>}
                {isLoading && (
                    <Loading
                        className={classes.topSpacing}
                        boxHeight="calc(50vh - 120px)"
                    />
                )}
            </Dialog>
            <TableCell>
                <div className={classes.container}>
                    <div className={classes.content1}>
                        {upi}
                    </div>
                </div>
            </TableCell>
            <TableCell>{name}</TableCell>
            <TableCell>{relation}</TableCell>
            <TableCell>{mobile}</TableCell>
            <TableCell>{moment(dob).format("YYYY-MM-DD")}</TableCell>
            <TableCell>{moment(createdAt).format("YYYY-MM-DD HH:MM")}</TableCell>
            <TableCell align="right">
                <Link to={`/sendToMunas/${customerCode}`}>
                    <IconButton>
                        <InfoOutlined />
                    </IconButton>
                </Link>
            </TableCell>
        </TableRow>
    );
};

export default FamilyMemberRow;
