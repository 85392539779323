import React, { useState, useEffect } from 'react';
import {
  makeStyles,
  Grid,
  FormControlLabel,
  FormControl,
  FormLabel,
  RadioGroup,
  Radio,
  Select,
  MenuItem,
  InputLabel,
  TextField,
} from '@material-ui/core';
import Card from '../../components/Card/Card';
import CardBody from '../../components/Card/CardBody';
import { search } from '../../dataService/search';
import { serviceType } from '../../../src/config/env.json';

let queue;
const Filter = ({ searchTerms, setSearchTerms }) => {
  const classes = useStyles();
  const [userStatus, setUserStatus] = useState();
  const [serviceTypeStatus, setServiceTypeStatus] = useState();
  const [promoCode, setPromoCode] = useState('');

 
  useEffect(() => {
    const updatedSearchTerms = {
      ...searchTerms,
      ...{ serviceType: serviceTypeStatus },
    };

    if (promoCode.length) {
      updatedSearchTerms['promoCode'] = promoCode;
    } else {
      delete updatedSearchTerms['promoCode'];
    }

    setSearchTerms(updatedSearchTerms);
  }, [serviceTypeStatus, promoCode]);
  //service type end

  useEffect(() => {
    const updatedSearchTerms = {
      ...searchTerms,
      ...{ status: userStatus },
    };

    if (promoCode.length) {
      updatedSearchTerms['promoCode'] = promoCode;
    } else {
      delete updatedSearchTerms['promoCode'];
    }

    setSearchTerms(updatedSearchTerms);
  }, [userStatus, promoCode]);

  useEffect(() => {
    const updatedSearchTerms = {
      ...searchTerms,
      ...{ status: userStatus },
    };

    if (!promoCode) {
      delete updatedSearchTerms['promoCode'];
      setSearchTerms(updatedSearchTerms);
    }

    if (promoCode.length > 5) {
      updatedSearchTerms['promoCode'] = promoCode;
      clearTimeout(queue);
      queue = setTimeout(() => {
        setSearchTerms(updatedSearchTerms);
      }, 500);
    }
  }, [promoCode]);

  return (
    <Card>
      <CardBody>
        <h4 className={classes.filterTitle}>Search Discount Details</h4>
        <Grid container spacing={1}>
        
          <Grid md={2} item>
            <TextField
              value={promoCode}
              onChange={ev => setPromoCode(ev.target.value)}
              className={classes.fullWidth}
              label="Promo Code"
              placeholder="PRM_ABS_TEST4"
              variant="outlined"
            />
          </Grid>
        </Grid>
      </CardBody>
    </Card>
  );
};

const useStyles = makeStyles({
  topSpacing: {
    marginTop: '30px',
  },
  filterTitle: {
    margin: '0 0 15px 0',
  },
  marginNone: {
    margin: 0,
  },
  formControl: {
    '& legend': {
      margin: '0 200px 0 0',
    },
  },
  fullWidth: {
    width: '100%',
  },
  formGroup: {
    '& label': {
      margin: 0,
    },
  },
});

export default Filter;
