import React, { useState } from 'react';
import {
  TextField,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from '@material-ui/core';
import swal from 'sweetalert';
import Error from '../Error';
import Button from '../CustomButtons/Button';
import { addNewPackage } from '../../dataService/addNewPackage';

const CopyPackage = ({ setOpenCopyForm, openCopyForm, packageDetails }) => {
  const [packageCode, setPackageCode] = useState(packageDetails.packageCode);

  const handleClose = () => {
    setOpenCopyForm(false);
  };

  const createPackage = async () => {
    const {
      createdAt,
      id,
      status,
      updatedAt,
      updatedBy,
      content,
      features,
      ...justPackage
    } = packageDetails;

    const res = await addNewPackage({
      ...justPackage,
      ...{ packageCode: packageCode.substring(4) },
    });

    if (res.status !== 'error') {
      swal({
        title: 'Success!',
        text: 'Package creation successful!',
        icon: 'success',
      });
      handleClose();
    } else {
      swal({
        title: 'Failed!',
        text: res.message,
        icon: 'error',
      });
    }
  };

  const isValidated = () => {
    const pattern = new RegExp(/^PKG_[A-Z][A-Z_0-9]+[A-Z-0-9]$/);
    return pattern.test(packageCode)
  };
  
  const isTouched = () => {
    return (packageCode !== packageDetails.packageCode);
  };

  return (
    <>
      <Dialog open={openCopyForm} onClose={handleClose}>
        <DialogTitle>Copy Package</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Are you sure you want to copy this package? If yes fillup the input
            field with new package code and click the copy button.
          </DialogContentText>
          <TextField
            autoFocus
            value={packageCode}
            onChange={ev => setPackageCode(ev.target.value)}
            margin="dense"
            label="New Pakcage Code"
            type="text"
            fullWidth
          />
          {!isValidated() && isTouched() && (
            <Error msg="Package Code must be in uppercase and contains only Alphabets, Numbers and Underscores (not at the end or start)" />
          )}
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="danger">
            Cancel
          </Button>
          <Button
            color="success"
            onClick={createPackage}
            disabled={!isValidated() || !isTouched()}
          >
            Copy this package
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default CopyPackage;
