import axiosInstance from './axiosInstance';

export const getPackageDetails = async (packageCode, isCompact) => {
  try {
    const res = await axiosInstance.get(
      `/packages/${packageCode}${isCompact ? '' : '/detailed'}`,
      {
        data: null,
      },
    );
    return res.data;
  } catch (error) {
    return error.response.data;
  }
};
