import * as Yup from 'yup';

export const yupSchemaObject = {
  activityCode: Yup.string('Enter a Activity Code')
    .matches(
      /^[A-Z][A-Z_0-9]+[A-Z-0-9]$/,
      'Activity Code must be in uppercase and contains only Alphabet, Number and Underscore (not at the end or start)',
    )
    .required('Activity Code is mandatory'),
  title: Yup.string('Enter Activity Title').required(
    'Activity Title is mandatory',
  )
};
export const initialValues = {
  activityCode: '',
  title: '',
};
