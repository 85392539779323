import React, { useState, useEffect } from 'react';
import {
  makeStyles,
  FormControl,
} from '@material-ui/core';
import { get } from 'lodash';
import CustomInput from '../../../components/CustomInput/CustomInput';
import Error from '../../../components/Error';
import GridItem from '../../../components/Grid/GridItem';
import GridContainer from '../../../components/Grid/GridContainer';
import Card from '../../../components/Card/Card';
import Grid from '@material-ui/core/Grid';
import TextField from '@material-ui/core/TextField';


const DiscountFormBlock = ({ formik, isEdit }) => {
  const classes = useStyle();
  const { touched, errors } = formik;
  const multipleSelectPropsProcess = props => {
    return { ...props, ...{ value: props.value || [] } };
  };
  return (
    <Card>
      <h3 style={{ paddingLeft: '20px' }}>
        <b>Activity Information</b>
      </h3>

      <GridItem>
        <GridContainer>
          <GridItem xs={6}>
            <CustomInput
              groupText={!isEdit ? 'ACT_' : ''}
              labelText="Activity Code *"
              formControlProps={{
                fullWidth: true,
              }}
              inputProps={{
                ...formik.getFieldProps('activityCode'),
                disabled: isEdit,
                error: !!(touched.activityCode && errors.activityCode),
              }}
            />
            {touched.activityCode && errors.activityCode && (
              <Error msg={errors.activityCode} />
            )}
          </GridItem>

          <GridItem xs={6}>
            <CustomInput
              labelText="Title *"
              formControlProps={{
                fullWidth: true,
              }}
              inputProps={{
                type: 'text',
                ...formik.getFieldProps('title'),
                error: get(touched, 'title', '') && get(errors, 'title', ''),
              }}
            />
            {get(errors, 'title', '') && get(touched, 'title', '') && (
              <Error msg={get(errors, 'title', '')} />
            )}
          </GridItem>
        </GridContainer>
      </GridItem>
    </Card>
  );
};

const useStyle = makeStyles(theme => ({
  formControl: {
    width: '100%',
    marginTop: '27px',
  },
  container: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  textField: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    width: 200,
  },
}));

export default DiscountFormBlock;
