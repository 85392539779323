import {
  Chip,
  FormControl,
  Input,
  InputLabel,
  makeStyles,
  MenuItem,
  Select,
} from '@material-ui/core';
import { get } from 'lodash';
import React, { useEffect, useState } from 'react';
import Card from '../../../components/Card/Card';
import CustomInput from '../../../components/CustomInput/CustomInput';
import Error from '../../../components/Error';
import GridContainer from '../../../components/Grid/GridContainer';
import GridItem from '../../../components/Grid/GridItem';
import {
  channels,
  packageStatus,
  packageType,
  validityType,
} from '../../../config/env.json';
import { search } from '../../../dataService/search';

const GeneralInputFormBlock = ({ formik, isEdit }) => {
  const classes = useStyle();
  const { touched, errors } = formik;
  const [features, setFeatures] = useState([]);

  const loadFeature = async () => {
    let allFeatures = [];
    let pageData = [];
    let counter = 1;
    while (pageData) {
      pageData = await search({
        _entity: 'Feature',
        featureCode: 'FTR',
        _select: 'featureCode,id',
        _page: counter,
      });
      if (pageData.data.length === 0) {
        break;
      }
      allFeatures = allFeatures.concat(pageData.data);
      counter++;
    }
    setFeatures(allFeatures);
  };

 
  const multipleSelectPropsProcess = props => {
    return { ...props, ...{ value: props.value || [] } };
  };

  useEffect(() => {
    loadFeature();
    // loadContents();
  }, []);

  const [contents, setContents] = useState([]);

  return (
    <Card style={{ paddingBottom: '20px' }}>
 
      <h4 style={{ paddingLeft: '20px' }}>
        <b>Common package Information</b>
      </h4>
      <GridItem>
        <GridContainer>
          <GridItem xs={4}>
            <CustomInput
              groupText={!isEdit ? 'PKG_' : ''}
              labelText="Package Code *"
              formControlProps={{
                fullWidth: true,
              }}
              inputProps={{
                disabled: isEdit,
                ...formik.getFieldProps('packageCode'),
                error: !!(touched.packageCode && errors.packageCode),
              }}
            />
            {touched.packageCode && errors.packageCode && (
              <Error msg={errors.packageCode} />
            )}
          </GridItem>
          <GridItem xs={4}>
            <CustomInput
              labelText="Package Title *"
              formControlProps={{
                fullWidth: true,
              }}
              inputProps={{
                ...formik.getFieldProps('packageTitle'),
                error: !!(touched.packageTitle && errors.packageTitle),
              }}
            />
            {touched.packageTitle && errors.packageTitle && (
              <Error msg={errors.packageTitle} />
            )}
          </GridItem>
          <GridItem xs={4}>
            <FormControl className={classes.formControl}>
              <InputLabel>Package Type *</InputLabel>
              <Select {...formik.getFieldProps('packageType')}>
                <MenuItem value="">Select Package Type</MenuItem>
                {packageType.map(packageType => (
                  <MenuItem key={packageType.id} value={packageType.value}>
                    {packageType.label}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
            {touched.packageType && errors.packageType && (
              <Error msg={errors.packageType} />
            )}
          </GridItem>
        </GridContainer>
      </GridItem>

      <GridItem>
        <GridContainer>
         

          <GridItem xs={4}>
            <CustomInput
              labelText="Price *"
              formControlProps={{
                fullWidth: true,
              }}
              inputProps={{
                ...formik.getFieldProps('price'),
                error: !!(touched.price && errors.price),
              }}
            />
            {touched.price && errors.price && <Error msg={errors.price} />}
          </GridItem>

          <GridItem xs={4}>
            <FormControl className={classes.formControl}>
              <InputLabel>Validity Type *</InputLabel>
              <Select {...formik.getFieldProps('validity.type')}>
                <MenuItem value="">Select Validity Type</MenuItem>
                {validityType.map(validityType => (
                  <MenuItem key={validityType.id} value={validityType.value}>
                    {validityType.label}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
            {get(errors, 'validity.type', '') &&
              get(touched, 'validity.type', '') && (
                <Error msg={get(errors, 'validity.type', '')} />
              )}
          </GridItem>

          <GridItem xs={4}>
            <CustomInput
              labelText="Validity Count *"
              formControlProps={{
                fullWidth: true,
              }}
              inputProps={{
                type: 'string',
                ...formik.getFieldProps('validity.count'),
                error:
                  get(touched, 'validity.count', '') &&
                  get(errors, 'validity.count', ''),
              }}
            />
            {get(errors, 'validity.count', '') &&
              get(touched, 'validity.count', '') && (
                <Error msg={get(errors, 'validity.count', '')} />
              )}
          </GridItem>

          <GridItem xs={8}>
            <FormControl className={classes.formControl}>
              <InputLabel>Feature Ids*</InputLabel>
              <Select
                multiple
                input={<Input id="select-feature-ids" />}
                renderValue={selected => (
                  <div className={classes.chips}>
                    {selected.map(value => (
                      <Chip
                        key={value}
                        label={
                          features.length &&
                          features.filter(f => f.id === value)[0].featureCode
                        }
                        className={classes.chip}
                      />
                    ))}
                  </div>
                )}
                {...formik.getFieldProps('featureIds')}
              >
                {features.map(({ featureCode, id }) => (
                  <MenuItem key={featureCode} value={id}>
                    {featureCode}
                  </MenuItem>
                ))}
              </Select>

              {errors.featureIds && touched.featureIds && (
                <Error msg={errors.featureIds} />
              )}
            </FormControl>
          </GridItem>
          <GridItem xs={4}>
            <FormControl className={classes.formControl}>
              <InputLabel>Package status *</InputLabel>
              <Select {...formik.getFieldProps('status')}>
                <MenuItem value="">Select Package Status</MenuItem>
                {packageStatus.map(packageStatus => (
                  <MenuItem key={packageStatus.id} value={packageStatus.value}>
                    {packageStatus.label}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
            {get(errors, 'status', '') && get(touched, 'status', '') && (
              <Error msg={get(errors, 'status', '')} />
            )}
          </GridItem>
          <GridItem xs={8}>
            <FormControl className={classes.formControl}>
              <InputLabel>Enabled For Channels</InputLabel>
              <Select
                multiple
                input={<Input id="select-channels" />}
                renderValue={selected => (
                  <div className={classes.chips}>
                    {selected.map(value => (
                      <Chip
                        key={value}
                        label={value}
                        className={classes.chip}
                      />
                    ))}
                  </div>
                )}
                {...multipleSelectPropsProcess(
                  formik.getFieldProps('enabledForChannels'),
                )}
              >
                {channels.map(channel => (
                  <MenuItem key={channel} value={channel}>
                    {channel}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
            {get(errors, 'enabledForChannels', '') &&
              get(touched, 'enabledForChannels', '') && (
                <Error msg={get(errors, 'enabledForChannels', '')} />
              )}
          </GridItem>
          <GridItem xs={4}>
            <FormControl className={classes.formControl}>
              <InputLabel>Is Registration Fee Chargeable?</InputLabel>
              <Select {...formik.getFieldProps('isRegistrationFeeChargeable')}>
                <MenuItem value="false">No</MenuItem>
                <MenuItem value="true">Yes</MenuItem>
              </Select>
            </FormControl>
            {get(errors, 'status', '') && get(touched, 'status', '') && (
              <Error msg={get(errors, 'status', '')} />
            )}
          </GridItem>
         
        </GridContainer>
      </GridItem>
    </Card>
  );
};

const useStyle = makeStyles({
  formControl: {
    width: '100%',
    marginTop: '27px',
  },
});

export default GeneralInputFormBlock;
