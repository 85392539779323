import axiosInstance from './axiosInstance';

export const updatePackage = async packageInput => {
  try {
    const res = await axiosInstance.put(
      `/packages/${packageInput.packageCode}`,
      {
        packageCode: packageInput.packageCode,
        packageTitle: packageInput.packageTitle,
        packageType: packageInput.packageType,
        content: packageInput.content,
        price: Number(packageInput.price),
        validity: packageInput.validity,
        activationRules: packageInput.activationRules,
        featureIds: packageInput.featureIds,
        enabledForChannels: packageInput.enabledForChannels,
        status: packageInput.status,
      },
    );
    return res.data;
  } catch (error) {
    return error.response.data;
  }
};
