import axiosInstance from './axiosInstance';

export const addNewPackage = async ({ price, packageCode, ...rest }) => {
  const processedInfo = {
    ...rest,
    packageCode: `PKG_${packageCode}`,
    price: Number(price),
  };

  try {
    const res = await axiosInstance.post('/packages', processedInfo);
    return res.data;
  } catch (error) {
    return error.response.data;
  }
};
