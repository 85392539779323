import React, { useState } from 'react';
import { Formik } from 'formik';
import * as Yup from 'yup';
import { makeStyles } from '@material-ui/core';
import { AddAlert } from '@material-ui/icons';
import GridItem from '../../components/Grid/GridItem';
import CardBody from '../../components/Card/CardBody';
import GridContainer from '../../components/Grid/GridContainer';
import Card from '../../components/Card/Card';
import Snackbar from '../../components/Snackbar/Snackbar';
import Header from '../../components/Header';
import Button from '../../components/CustomButtons/Button';
import { addCSV } from '../../dataService/addCSV';
import {
  FormControl,
  Link,
  Typography
} from '@material-ui/core';

const AddCSV = () => {
  const classes = useStyles();

  const [csvFile, setCSVFile] = useState({ raw: '' });
  const [submitDisable, setSubmitDisable] = useState(false);
  const [snackbarConfig, setSnackbarConfig] = useState({
    open: false,
    message: '',
    color: 'success',
  });

  const handleCSVChange = e => {
    setCSVFile({
      raw: e.target.files[0]
    });
  };

  const snackBarClose = () => {
    setSnackbarConfig({ ...snackbarConfig, open: false });
  };

  const onSubmit = async (values) => {
    setSubmitDisable(true);
    const { status, message } = await addCSV( csvFile.raw);

    if (status === 'success') {
      setSubmitDisable(false);
      setSnackbarConfig({
        open: true,
        message: 'CSV added successfully, customers are creating..!',
        icon: AddAlert,
        color: 'success',
      });

    } else {
      setSubmitDisable(false);
      setSnackbarConfig({
        open: true,
        message,
        icon: AddAlert,
        color: 'danger',
      });
    }
  };

  return (
    <>
      <form className={classes.container} onSubmit={onSubmit} >
        <Card style={{ backgroundColor: '#800054' }}>
          <CardBody>
            <h3 style={{ color: '#ffffff' }}>
              <b>Add Customers By CSV</b>
            </h3>
          </CardBody>
        </Card>
        <Card>
          <h3 style={{ paddingLeft: '20px' }}>
            <b>Add CSV</b> <Link style={{ float: "right", paddingRight: "20px" }} 
            href='https://praava-profiles-data-staging.s3.ap-south-1.amazonaws.com/samples/1613362741367%20-%20import.csv'>Download Sample</Link>
          </h3>
          <Typography style={{ paddingLeft: '20px' }}>
            Please use the sample CSV to add data <br/>
            Following field need to contained<br/>
            1. First Name <br/>
            2. Last Name <br/>
            3. Mobile ( 13 digit mobile number starts with 880) <br/>
            4. Email <br/>
            5. Gender (Male/ FeMale/ Other) <br/>
            6. BirthDay (YYYY-MM-DD) <br/>
            7. Address <br/>
          </Typography> 
          <GridItem>
            <GridContainer>
              <GridItem xs={6}>
                <FormControl className={classes.formControl}>
                  <h5>
                    <label htmlFor="upload-csv">
                      <input
                        type="file"
                        accept=".csv, .xls, .xlsx, text/csv, application/csv"
                        className={classes.input}
                        id="upload-csv"
                        style={{ display: 'none' }}
                        onChange={handleCSVChange}
                      />
                      <Button
                        variant="contained"
                        component="span"
                        className={classes.button}
                      >
                        Browse CSV
                        </Button>
                    </label>
                    {csvFile.raw ? (
                      <p>{csvFile.raw.name}</p>
                    ) : (
                        <></>
                      )}
                  </h5>
                </FormControl>
              </GridItem>
            </GridContainer>
          </GridItem>
        </Card>
        <GridContainer>
          <GridItem xs={12}>
            <GridContainer>
              <GridItem>
                <Button
                  color="success"
                  type="submit"
                >
                  Upload
                    </Button>
              </GridItem>
            </GridContainer>
          </GridItem>
        </GridContainer>
      </form>
      <Snackbar
        {...snackbarConfig}
        icon={AddAlert}
        close
        closeNotification={snackBarClose}
        place="bl"
      />
    </>
  );
};

const useStyles = makeStyles({
  pageTopSpacing: {
    marginTop: '30px',
  },
  blockSpacing: {
    marginTop: '60px',
  },
});

export default AddCSV;
