import { Grid, makeStyles, TextField } from '@material-ui/core';
import { createMuiTheme, MuiThemeProvider } from '@material-ui/core/styles';
import { KeyboardArrowLeft, KeyboardArrowRight } from '@material-ui/icons';
import FirstPageIcon from '@material-ui/icons/FirstPage';
import LastPageIcon from '@material-ui/icons/LastPage';
import { getByUpi } from 'dataService/getByUpi';
import React, { useState } from 'react';
import Card from '../../components/Card/Card';
import CardBody from '../../components/Card/CardBody';
import Button from '../../components/CustomButtons/Button';
import GridContainer from '../../components/Grid/GridContainer';
import GridItem from '../../components/Grid/GridItem';
import NoContentCard from '../../components/NoContentCard';
import TableFormatter from '../../components/TableFormatter';
import CustomerRow from './CustomerRow';

export default () => {
  const classes = useStyles();
  const [customerUpi, setCustomerUpi] = useState();
  const [rows, setRows] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [pageNumber, setPageNumber] = useState(0);
  const [resultCount, setResultCount] = useState(20);
  const [pageCount, setPageCount] = useState(0);
  const [totalCount, setTotalCount] = useState(0);
  const tableHead = [
    { name: 'First Name', align: 'left' },
    { name: 'Mobile', align: 'left' },
    // { name: 'Email', align: 'left' },s
    { name: 'UPI', align: 'left' },
    { name: 'Created Date', align: 'left' },
    // { name: 'Packages', align: 'left' },
    // { name: 'Family Members', align: 'left' },
    { name: 'Details', align: 'left' },
    // { name: 'Munas', align: 'left' },
  ];
 

  const createData = (
    firstName,
    lastName,
    customerCode,
    status,
    mobile,
    email,
    upi,
    createdAt,
    channel,
  ) => {
    return {
      firstName,
      lastName,
      customerCode,
      status,
      mobile,
      email,
      upi,
      createdAt,
      channel,
    };
  };

  

  const prevPage = () => {};

  const nextPage = () => {};

  const lastPage = () => {};

  const firstPage = () => {};

  const onCustomerUPIChange = async e => {
    setCustomerUpi(e.target.value);

    console.log(e.target.value);

    if (e.target.value.length === 14) {
      setRows([]);

      const res = await getByUpi(e.target.value);

      if (res.data.length) {
        window.location.href = `/search-customer-using-upi/${res.data[0].customerCode}`;
      }
      
    }
  };

  return (
    <div className={classes.container}>
      <React.Fragment>
        <div>
          <img src="./praava_logo.png" alt="logo" style={{ width: '10%' }} />
        </div>
      </React.Fragment>
      <Card>
        <CardBody>
          <h4 className={classes.filterTitle}>Enter Your PHBL Number</h4>
          <Grid container spacing={2}>
            <Grid md={3} item>
              <TextField
                value={customerUpi}
                onChange={onCustomerUPIChange}
                className={classes.fullWidth}
                label="Enter PHBL Number"
                placeholder="PHBL0000000000"
                variant="outlined"
              />
            </Grid>
          </Grid>
        </CardBody>
      </Card>
      
    </div>
  );
};

const Pagination = ({
  nextPage,
  prevPage,
  pageNumber,
  resultCount,
  pageCount,
  lastPage,
  firstPage,
}) => {
  const classes = useStyles();
  return (
    <div className={classes.pagination}>
      <GridContainer direction="row" justify="center">
        <GridItem>
          <Button
            onClick={firstPage}
            color="transparent"
            round
            small
            disabled={pageNumber === 1}
          >
            <FirstPageIcon />
          </Button>
        </GridItem>
        {pageNumber === 1 ? null : (
          <GridItem>
            <Button
              onClick={prevPage}
              color="transparent"
              round
              small
              disabled={pageNumber === 1}
            >
              <KeyboardArrowLeft />
            </Button>
          </GridItem>
        )}
        <p>
          <strong>{pageNumber}</strong>
        </p>
        <GridItem>
          <Button
            onClick={nextPage}
            color="transparent"
            round
            small
            disabled={resultCount < 20}
          >
            <KeyboardArrowRight />
          </Button>
        </GridItem>
        <GridItem>
          <Button
            onClick={lastPage}
            color="transparent"
            round
            small
            disabled={resultCount < 20}
          >
            <strong>{pageCount}</strong>
            <LastPageIcon />
          </Button>
        </GridItem>
      </GridContainer>
    </div>
  );
};

const useStyles = makeStyles({
  topSpacing: {
    marginTop: '30px',
  },
  marginNone: {
    margin: 0,
  },
  wrapperCard: {
    marginBottom: 0,
  },
  pagination: {
    paddingTop: '15px',
  },
  tableContainer: {
    height: 'calc(100vh - 373px)',
    '& .MuiTableCell-stickyHeader': {
      background: 'white',
    },
  },
  notFoundContainer: {
    display: 'flex',
    alignItems: 'center',
    width: '100%',
    justifyContent: 'center',
    height: 'calc(100% - 57px)',
  },
  container: {
    padding: 32,
  },
  img: {
    marginTop: 8,
  },
});

const theme = createMuiTheme({
  overrides: {
    MuiTableCell: {
      root: {
        paddingTop: 4,
        paddingBottom: 4,
        paddingRight: 5,
      },
    },
  },
});
