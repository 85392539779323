import React from 'react';
import { get } from 'lodash';
import CustomInput from '../../../components/CustomInput/CustomInput';
import CardHeader from '../../../components/Card/CardHeader';
import Error from '../../../components/Error';
import GridItem from '../../../components/Grid/GridItem';
import GridContainer from '../../../components/Grid/GridContainer';
import Card from '../../../components/Card/Card';

const PurchaseRulesFormBlock = ({ formik }) => {
  const { touched, errors } = formik;
  return (
    <Card>
     
      <h4 style={{ paddingLeft: '20px' }}>
        <b>Activation Rules</b>
      </h4>
      <GridItem>
        <GridContainer>
          <GridItem xs={4}>
            <CustomInput
              labelText="Start Delay In Days *"
              formControlProps={{
                fullWidth: true,
              }}
              inputProps={{
                type: 'text',
                ...formik.getFieldProps('activationRules.startDelayInDays'),
                error:
                  get(touched, 'activationRules.startDelayInDays', '') &&
                  get(errors, 'activationRules.startDelayInDays', ''),
              }}
            />
            {get(errors, 'activationRules.startDelayInDays', '') &&
              get(touched, 'activationRules.startDelayInDays', '') && (
                <Error
                  msg={get(errors, 'activationRules.startDelayInDays', '')}
                />
              )}
          </GridItem>
          <GridItem xs={4}>
            <CustomInput
              labelText="Max Activation Count Per Year *"
              formControlProps={{
                fullWidth: true,
              }}
              inputProps={{
                type: 'text',
                ...formik.getFieldProps(
                  'activationRules.maxActivationCountPerYear',
                ),
                error:
                  get(
                    touched,
                    'activationRules.maxActivationCountPerYear',
                    '',
                  ) &&
                  get(errors, 'activationRules.maxActivationCountPerYear', ''),
              }}
            />
            {get(errors, 'activationRules.maxActivationCountPerYear', '') &&
              get(touched, 'activationRules.maxActivationCountPerYear', '') && (
                <Error
                  msg={get(
                    errors,
                    'activationRules.maxActivationCountPerYear',
                    '',
                  )}
                />
              )}
          </GridItem>
          <GridItem xs={4}>
            <CustomInput
              labelText="Max Activation Count Per Month *"
              formControlProps={{
                fullWidth: true,
              }}
              inputProps={{
                type: 'string',
                ...formik.getFieldProps(
                  'activationRules.maxActivationCountPerMonth',
                ),
                error:
                  get(
                    touched,
                    'activationRules.maxActivationCountPerMonth',
                    '',
                  ) &&
                  get(errors, 'activationRules.maxActivationCountPerMonth', ''),
              }}
            />
            {get(errors, 'activationRules.maxActivationCountPerMonth', '') &&
              get(
                touched,
                'activationRules.maxActivationCountPerMonth',
                '',
              ) && (
                <Error
                  msg={get(
                    errors,
                    'activationRules.maxActivationCountPerMonth',
                    '',
                  )}
                />
              )}
          </GridItem>
        </GridContainer>
      </GridItem>
    </Card>
  );
};

export default PurchaseRulesFormBlock;
