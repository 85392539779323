import React, { useState, useEffect } from 'react';
import { Formik } from 'formik';
import * as Yup from 'yup';
import { makeStyles } from '@material-ui/core';
import { AddAlert } from '@material-ui/icons';
import GridItem from '../../components/Grid/GridItem';
import CardBody from '../../components/Card/CardBody';
import GridContainer from '../../components/Grid/GridContainer';
import Card from '../../components/Card/Card';
import Snackbar from '../../components/Snackbar/Snackbar';
import GeneralInputFormBlock from './formParts/GeneralInputFormBlock';
import ContentFormBlock from './formParts/PackageFormBlock';
import Header from '../../components/Header';
import {Link} from 'react-router-dom'
import Button from '../../components/CustomButtons/Button';
import { addNewCustomerAndSubscribe } from '../../dataService/addNewCustomerAndSubscribe';
import { yupSchemaObject, initialValues } from './formSchema';
import { useHistory } from "react-router-dom";
import { getUser } from '../../dataService/getUser'

const AddNewCustomer = () => {
  const classes = useStyles();
  const validationSchema = Yup.object(yupSchemaObject);
  let history = useHistory();
  const [submitDisable, setSubmitDisable] = useState(false);
  const [snackbarConfig, setSnackbarConfig] = useState({
    open: false,
    message: '',
    color: 'success',
  });

  const snackBarClose = () => {
    setSnackbarConfig({ ...snackbarConfig, open: false });
  };

  const onSubmit = async (values, { resetForm }) => {
    setSubmitDisable(true);

    setSubmitDisable(false);

    console.log("triggered")
    
   

    console.log(values)
    const newValues = {
      "channel": "PRAAVA_ADMIN",
      "credential": {
         "type": "Customer",
         "mobile": values.mobile,
         "email": values.email,
         "password": "12345678%"
      },
      "profile": {
        "firstName": values.firstName,
        "lastName": values.lastName,
        "dob": values.dob,
        "gender":values.gender,
        "address": {
          "country": "BD",
          "street": values.street,
          "city": values.city,
          "zip": values.zip,
        }
      },
    "order": {
      "productType": "Package",
      "productReferenceCode": values.validPackages,
      "paymentMethod": "Invoice",
      "salesInfo": {
        "channel": "USSD"
      }
         
    },
    "payment": {
      "paymentMethod": "Invoice",
      "provider": "NebulaInternal",
      "reference": "ref123"
    }
    }
    console.log(newValues)
    const data = await addNewCustomerAndSubscribe(newValues);

    if (data.status === 'success') {
      setSubmitDisable(false);
      console.log('sucsess')
      setSnackbarConfig({
        open: true,
        message: 'New customer added successful!',
        icon: AddAlert,
        color: 'success',
      });
      
      resetForm();
      history.push('/customers')
      
    } else {
      setSubmitDisable(false);
      setSnackbarConfig({
        open: true,
        message: data.message,
        icon: AddAlert,
        color: 'danger',
      });
    }
  };


  return (
    <>
     
      <Card style={{ backgroundColor: '#800054' }}>
        <CardBody>
          <h3 style={{ color: '#ffffff' }}>
            <b>Add New Customer</b>
          </h3>
        </CardBody>
      </Card>
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={onSubmit}
      >
        {formik => (
          <form
            className={classes.pageTopSpacing}
            onSubmit={formik.handleSubmit}
          >
            <GridContainer>
              <GridItem xs={12}>
                <GeneralInputFormBlock formik={formik} />

                <div className={classes.blockSpacing} />
                <ContentFormBlock formik={formik} /> 
 
                <GridContainer>
                  <GridItem>
                    <Button
                      disabled={
                        submitDisable ||
                        (formik.isValid && !formik.dirty) ||
                        !formik.isValid
                      }
                      color="success"
                      type="submit"
                    
                    >
                      Create Customer
                    </Button>
                  </GridItem>
                </GridContainer>
               
              </GridItem>
            </GridContainer>
          </form>
        )}
      </Formik>
      <Snackbar
        {...snackbarConfig}
        icon={AddAlert}
        close
        closeNotification={snackBarClose}
        place="bl"
      />
    </>
  );
};

const useStyles = makeStyles({
  pageTopSpacing: {
    marginTop: '30px',
  },
  blockSpacing: {
    marginTop: '60px',
  },
});

export default AddNewCustomer;
