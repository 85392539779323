import React from 'react';
import {
  Card,
  Table,
  TableHead,
  TableCell,
  TableRow,
  TableBody,
  makeStyles,
} from '@material-ui/core';
import { Link } from 'react-router-dom';
import { Edit } from '@material-ui/icons';
import Button from '../CustomButtons/Button';
import CardBody from '../Card/CardBody';
import GridItem from '../Grid/GridItem';
import GridContainer from '../Grid/GridContainer';

const FeatureDetailsBody = ({ feature }) => {
  const classes = useStyles();
  const {
    featureCode,
    featureType,
    featureWeight,
    content: {
      bn,
      en
    },
  } = feature;

  return (
    <Card>
      <CardBody>
        <GridContainer>
          {/* <GridItem md={6}>
            <h4 className={classes.subTitle}>
              {`Feature Sub Type: ${featureSubType}`}
            </h4>
          </GridItem> */}
          <GridItem md={6}>
            <GridContainer justify="flex-end" alignItems="center">
            </GridContainer>
          </GridItem>
          <GridItem md={12}>
            <Table className={classes.table}>
              <TableHead>
                <TableRow>
                  <TableCell>Feature Name</TableCell>
                  <TableCell align="right">Feature Value</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                <TableRow>
                  <TableCell align="left">Feature Code</TableCell>
                  <TableCell align="right">{featureCode}</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell align="left">Feature Type</TableCell>
                  <TableCell align="right">{featureType}</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell align="left">Feature Weight</TableCell>
                  <TableCell align="right">{featureWeight}</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell align="left">English Content</TableCell>
                  <TableCell align="right">{en}</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell align="left">Bangala Content</TableCell>
                  <TableCell align="right">{bn}</TableCell>
                </TableRow>
              </TableBody>
            </Table>
          </GridItem>
          <GridItem md={12}>
            <div className={classes.spacing} />
          </GridItem>
        </GridContainer>
      </CardBody>
    </Card>
  );
};

const useStyles = makeStyles({
  topSpacing: {
    marginTop: '30px',
  },
  subTitle: {
    marginTop: 0,
  },
  spacing: {
    height: '30px',
  },
  flexVCenter: {
    display: 'flex',
    alignItems: 'center',
  },
});

export default FeatureDetailsBody;
