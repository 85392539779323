import React from 'react';
import {
  FormControl,
  FormLabel,
  RadioGroup,
  FormControlLabel,
  Radio,
  makeStyles,
  Grid,
  Switch,
} from '@material-ui/core';
import CustomInput from '../../components/CustomInput/CustomInput';
import Button from '../../components/CustomButtons/Button';
import CardBody from '../../components/Card/CardBody';
import CardFooter from '../../components/Card/CardFooter';
import Error from '../../components/Error';
import GridItem from '../../components/Grid/GridItem';
import GridContainer from '../../components/Grid/GridContainer';
import { userRoles as roles } from '../../config/env';
import TextField from '@material-ui/core/TextField';

const AddNewUserForm = ({ formik, submitDisable, edit }) => {
  const [value, setValue] = React.useState('');
  const handleChange = (event) => {
    setValue(event.target.value);
  };

  const classes = useStyles();
  return (
    <form onSubmit={formik.handleSubmit}>
      <CardBody>
        <GridContainer>
          <GridItem xs={12} md={6}>
            <TextField
              inputProps={{
                ...formik.getFieldProps('name'),
                error: !!(formik.touched.name && formik.errors.name),
              }}
              label="Name"
              fullWidth
              variant="outlined"
            
            />
            {formik.touched.name && formik.errors.name && (
              <Error msg={formik.errors.name} />
            )}
          </GridItem>
          {!edit && (
            <>
            <GridItem xs={12} md={6}>
            <TextField
              inputProps={{
                ...formik.getFieldProps('mobile'),
                error: !!(formik.touched.mobile && formik.errors.mobile),
              }}
              label="Mobile"
              fullWidth
              variant="outlined"
          
            />
            {formik.touched.mobile && formik.errors.mobile && (
              <Error msg={formik.errors.mobile} />
            )}
          </GridItem>
              <GridItem xs={12} md={6}>
                <TextField
                  style={{ marginTop: '20px' }}
                  inputProps={{
                    ...formik.getFieldProps('email'),
                    error: !!(formik.touched.email && formik.errors.email),
                  }}
                  label="Email address"
                  fullWidth
                  variant="outlined"
               
                />
                {formik.touched.email && formik.errors.email && (
                  <Error msg={formik.errors.email} />
                )}
              </GridItem>
              <GridItem xs={12} md={6}>
                <TextField
                  style={{ marginTop: '20px' }}
                  inputProps={{
                    ...formik.getFieldProps('password'),
                    type: 'password',
                    error: !!(
                      formik.touched.password && formik.errors.password
                    ),
                  }}
                  label="Password"
                  fullWidth
                  variant="outlined"
                
                />
                {formik.touched.password && formik.errors.password && (
                  <Error msg={formik.errors.password} />
                )}
              </GridItem>
              </>
              )}
              <br /><br /><br /><br />
          {(value=="Retailer" || formik.getFieldProps('role').value == "Retailer") && (<GridItem xs={12} md={6}>
            <TextField
              inputProps={{
                ...formik.getFieldProps('target'),
                error: !!(formik.touched.target && formik.errors.target),
              }}
              label="Target Value"
              fullWidth
              variant="outlined"
            />
            {formik.touched.target && formik.errors.target && (
              <Error msg={(formik.errors.target?('Should be a number'):(''))} />
            )}
          </GridItem>)}
        </GridContainer>
        {edit && (
          <GridContainer>
            <GridItem xs={12} md={6}>
              <p className={classes.label}>Status (active/inactive)</p>
              <FormControlLabel
                control={
                
                  <Switch
                    checked={formik.getFieldProps('status').value}
                    {...formik.getFieldProps('status')}
                    color="primary"
                  />
                }
              />
            </GridItem>
          </GridContainer>
        )}
        <br />
        <GridContainer>
          <GridItem>
            <FormLabel component="legend">Roles</FormLabel>
            <FormControl component="fieldset">
              <RadioGroup
                aria-label="position"
                name="position"
                value={value}
                onClick={handleChange}
                {...formik.getFieldProps('role')}
              >
                <Grid container>
                  {roles.map(item => (
                    <Grid item md={4} key={item.id}>
                      <FormControlLabel
                        value={item.value}
                        control={<Radio />}
                        label={item.label}
                        labelPlacement="end"
                      />
                    </Grid>
                  ))}
                </Grid>
              </RadioGroup>
            </FormControl>
            {formik.touched.role && formik.errors.role && (
              <Error msg={formik.errors.role} />
            )}
          </GridItem>
        </GridContainer>
      </CardBody>
      <CardFooter>
        <Button
          style={{ borderRadius: '10px' }}
          disabled={
            submitDisable ||
            (formik.isValid && !formik.dirty) ||
            (formik.dirty && !formik.isValid)
          }
          color="success"
          type="submit"
        >
          {!edit && 'Create New User'}
          {edit && 'Update User Profile'}
        </Button>
      </CardFooter>
    </form>
  );
};

const useStyles = makeStyles({
  label: {
    color: 'rgba(0, 0, 0, 0.54)',
    padding: 0,
    fontSize: '1rem',
    fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
    fontWeight: 400,
    lineHeight: 1,
    marginBottom: 0,
  },
});

export default AddNewUserForm;
