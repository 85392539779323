import { makeStyles } from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import GridContainer from '../../components/Grid/GridContainer';
import GridItem from '../../components/Grid/GridItem';
import Loading from '../../components/Loading';
import CustomerDocuments from './../../components/CustomerDocuments';
import CustomerPublicInfoDetailsBody from './../../components/CustomerPublicInfoDetailsBody/index';
import { getCustomerPublicInfo } from './../../dataService/getCustomerInfo';

const CustomerPublicDetails = props => {
  const classes = useStyles();
  const [subscriptionDetails, setSubscriptionDetails] = useState({});
  const [customerInfo, setCustomerInfo] = useState({});
  const [isLoading, setIsLoading] = useState(true);
  const [subscriptionDetailsFlag, setSubscriptionDetailsFlag] = useState(false);

  const requestCustomerInfo = async customerCode => {
    const res = await getCustomerPublicInfo(customerCode);

    if (res.status !== 'error') {
      setCustomerInfo(res.data);
    }
    setIsLoading(false);
  };

  useEffect(() => {
    requestCustomerInfo(props.match.params.customerCode);
  }, []);
  if (isLoading) {
    return (
      <div className={classes.topSpacing}>
        <Loading />
      </div>
    );
  }

  if (customerInfo) {
    return (
      <GridContainer>
        <GridItem xs={12} className={classes.topSpacing}>
          <div className={classes.topSpacing} />
          <CustomerPublicInfoDetailsBody customerInfo={customerInfo} />
        </GridItem>
        <GridItem xs={12} className={classes.topSpacing}>
          <div className={classes.topSpacing} />
          <CustomerDocuments customerInfo={customerInfo} />
        </GridItem>
      </GridContainer>
    );
  }

};

const useStyles = makeStyles({
  topSpacing: {
    marginTop: '30px',
  },
  subTitle: {
    fontSize: '24px',
    margin: '10px 0 20px 0',
  },
  spacing: {
    height: '30px',
  },
});

export default CustomerPublicDetails;
