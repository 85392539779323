import * as Yup from 'yup';

export const yupSchemaObject = {
  packageCode: Yup.string('Enter a Package Code')
    .matches(
      /^[A-Z][A-Z_0-9]+[A-Z-0-9]$/,
      'Package Code must be in uppercase and contains only Alphabet, Number and Underscore (not at the end or start)',
    )
    .required('Package Title is mandatory'),
  packageTitle: Yup.string('Enter a Package Title').required(
    'Package Title is mandatory',
  ),
  enabledForChannels: Yup.string('Enter a Channel enability').required(
    ' Channel Enability is mandatory',
  ),
  packageType: Yup.string('Enter a Package Type').required(
    'Package Type is mandatory',
  ),
  content: Yup.object().shape({
    en: Yup.string('Englsih Content Title is mandatory').required(
      'Englsih Content Title is mandatory',
    ),
    bn: Yup.string('Bangala Content Title is mandatory').required(
      'Bangala Content Title is mandatory',
    ),
  }),
  price: Yup.number()
    .typeError('Enter price in number')
    .max(999999, 'Maximum Price is 999999')
    .required('Price is mandatory')
    .integer('please provide integer number'),

  validity: Yup.object().shape({
    type: Yup.string('Enter a Package Validity').required(
      'Package Validity is mandatory',
    ),
    count: Yup.number('Enter a Validity Count')
      .typeError('Enter validity count in number')
      .max(1000, 'Maximum Validity count is 1000')
      .required('Validity Count is mandatory')
      .integer('please provide integer number'),
  }),

  activationRules: Yup.object().shape({
    startDelayInDays: Yup.number('Enter a Start Delay In Days')
      .typeError('Enter a Start Delay In Days')
      .max(1000, 'Maximum Validity count is 1000')
      .integer('please provide integer number')
      .required('Start Delay In Days is mandatory'),
    maxActivationCountPerYear: Yup.number(
      'Enter a Max Activation Count Per Year',
    )
      .typeError('Enter a Max Activation Count Per Year')
      .max(500, 'Maximum activation count per year is 500')
      .integer('please provide integer number')
      .required('Max Activation Count Per Year is mandatory'),
    maxActivationCountPerMonth: Yup.number(
      'Enter a Max Activation Count Per Month',
    )
      .typeError('Enter a Max Activation Count Per Month')
      .max(500, 'Maximum activation count per year is 500')
      .integer('please provide integer number')
      .required('Max Activation Count Per Month is mandatory'),
  }),

  featureIds: Yup.string('Enter Feature IDs').required(
    'Feature IDs field is mandatory',
  ),
  status: Yup.string('Enter Feature IDs').required(
    'Package status is mandatory',
  ),
};

export const initialValues = {
  packageCode: '',
  packageType: '',
  isRegistrationFeeChargeable: false,
  price: '',
  enabledForChannels: '',
  status: 'Active',
  content: {
    bn: '',
    en: '',
  },
  validity: {
    type: '',
    count: '',
  },
  activationRules: {
    startDelayInDays: '',
    maxActivationCountPerYear: '',
    maxActivationCountPerMonth: '',
  },
  featureIds: [],
};
