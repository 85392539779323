import axiosInstanceForActivityTracker from './axiosInstanceForActivityTracker';

export const addNewActivity = async activityInput => {
  const { title, activityCode } = activityInput;

  const data = {
    activityCode: `ACT_${activityCode}`,
    title: title
  };

  try {
    const res = await axiosInstanceForActivityTracker.post('/activity', data);
    return res.data;
  } catch (error) {
    return error.response.data;
  }
};
