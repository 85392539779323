import axiosInstanceForActivityTracker from './axiosInstanceForActivityTracker';

export const allActivities = async (params) => {
  try {
    const res = await axiosInstanceForActivityTracker.get(`/search`, {
      params,
      data: null,
    });
    return res.data;
  } catch (error) {
    return error.response.data;
  }
};
