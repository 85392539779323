import React, { useState, useEffect, useCallback } from 'react';
import { makeStyles, Grid } from '@material-ui/core';
import { search as allFeatures } from '../../dataService/search';
import FeatureRow from './FeatureRow';
import Header from '../../components/Header';
import Filter from './Filter';
import NoContentCard from '../../components/NoContentCard';
import Loading from '../../components/Loading';
import TableFormatter from '../../components/TableFormatter';
import { KeyboardArrowRight, KeyboardArrowLeft } from '@material-ui/icons';
import Button from '../../components/CustomButtons/Button';
import GridItem from '../../components/Grid/GridItem';
import GridContainer from '../../components/Grid/GridContainer';
import { createMuiTheme, MuiThemeProvider } from '@material-ui/core/styles';
import { Link } from 'react-router-dom';
import LastPageIcon from '@material-ui/icons/LastPage';
import FirstPageIcon from '@material-ui/icons/FirstPage';
const Feature = () => {
  const classes = useStyles();
  const [features, setRows] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [pageNumber, setPageNumber] = useState(0);
  const [resultCount, setResultCount] = useState(20);
  const [pageCount, setPageCount] = useState(0);
  const [totalCount, setTotalCount] = useState(0);
  const tableHead = [
    { name: 'Feature Code', align: 'left' },
    { name: 'Feature Type', align: 'right' },
    { name: 'Feature Weight', align: 'right' },
    { name: 'Actions', align: 'right' },
  ];

  const [searchTerms, setSearchTerms] = useState({
    _entity: 'Feature',
    _select:
      '*',
    _page: 1,
  });

  const createData = (featureCode, featureType, featureWeight) => {
    return { featureCode, featureType, featureWeight };
  };

  const createFeatureRows = useCallback(async () => {
    const res = await allFeatures(searchTerms);
    setIsLoading(true);
    const features = [];

    if (res.status !== 'success') return [];

    res.data.forEach(item => {
      const { featureCode, featureType, featureWeight } = item;
      features.push(createData(featureCode, featureType, featureWeight));
    });
    const count = Math.ceil(res.meta.totalCount / 20);
    console.log(count);
    setRows(features);
    setIsLoading(false);
    setPageNumber(res.meta.page);
    setResultCount(res.meta.resultCount);
    setTotalCount(res.meta.totalCount);
    setPageCount(count);
  }, [searchTerms]);

  useEffect(() => {
    createFeatureRows();
  }, [searchTerms]);

  const prevPage = () => {
    setSearchTerms({
      ...searchTerms,
      // eslint-disable-next-line no-underscore-dangle
      ...{ _page: searchTerms._page - 1 },
    });
  };

  const nextPage = () => {
    setSearchTerms({
      ...searchTerms,
      // eslint-disable-next-line no-underscore-dangle
      ...{ _page: searchTerms._page + 1 },
    });
  };

  const lastPage = () => {
    setSearchTerms({
      ...searchTerms,
      // eslint-disable-next-line no-underscore-dangle
      ...{ _page: searchTerms._page = pageCount },
    });
  };

  const firstPage = () => {
    setSearchTerms({
      ...searchTerms,
      // eslint-disable-next-line no-underscore-dangle
      ...{ _page: searchTerms._page = 1 },
    });
  };

  return (
    <>
    
      <React.Fragment>
        <h3>All Available Features</h3>
        <Link to={`/features/new`}>
          <Button color="success" small>
            Add new features
          </Button>
        </Link>
      </React.Fragment>
      <Filter searchTerms={searchTerms} setSearchTerms={setSearchTerms} />
      {isLoading && (
        <Loading
          className={classes.topSpacing}
          boxHeight="calc(100vh - 120px)"
        />
      )}

      {!isLoading && !features.length && (
        <Grid container justify="flex-end" className={classes.topSpacing}>
          <Grid item md={12}>
            <NoContentCard message="No features found" />
            <Pagination
              nextPage={nextPage}
              prevPage={prevPage}
              pageNumber={pageNumber}
              resultCount={resultCount}
              pageCount={pageCount}
              lastPage={lastPage}
              firstPage={firstPage}
            />
          </Grid>
        </Grid>
      )}

      {features.length > 0 && !isLoading && (
        <TableFormatter
          tableHead={tableHead}
          pagination={() => (
            <Pagination
              nextPage={nextPage}
              prevPage={prevPage}
              pageNumber={pageNumber}
              resultCount={resultCount}
              pageCount={pageCount}
              lastPage={lastPage}
              firstPage={firstPage}
            />
          )}
        >
          {features.map(feature => (
            <MuiThemeProvider theme={theme}>
              <FeatureRow key={feature.featureCode} content={feature} />
            </MuiThemeProvider>
          ))}
        </TableFormatter>
      )}
    </>
  );
};

const Pagination = ({ nextPage, prevPage, pageNumber, resultCount, pageCount, lastPage, firstPage  }) => {
  const classes = useStyles();
  return (
    <div className={classes.pagination}>
      <GridContainer direction="row" justify="center">
      <GridItem>
          <Button
            onClick={firstPage}
            color="transparent"
            round
            small
            disabled={pageNumber === 1}
          >
            <FirstPageIcon />
          </Button>
        </GridItem>
        { pageNumber === 1 ? null : (
        <GridItem>
          <Button
            onClick={prevPage}
            color="transparent"
            round
            small
            disabled={pageNumber === 1}
          >
            <KeyboardArrowLeft />
          </Button>
        </GridItem>
        )}
        <p>
          <strong>{pageNumber}</strong>
        </p>
        <GridItem>
          <Button
            onClick={nextPage}
            color="transparent"
            round
            small
            disabled={resultCount < 20}
          >
            <KeyboardArrowRight />
          </Button>
        </GridItem>
        <GridItem>
          <Button
            onClick={lastPage}
            color="transparent"
            round
            small
            disabled={resultCount < 20}
          >
            {pageCount}
            <LastPageIcon />
          </Button>
        </GridItem>
      </GridContainer>
    </div>
  );
};

const useStyles = makeStyles({
  cardCategoryWhite: {
    color: 'rgba(255,255,255,.62)',
    margin: '0',
    fontSize: '14px',
    marginTop: '0',
    marginBottom: '0',
  },
  topSpacing: {
    marginTop: '30px',
  },
  cardTitleWhite: {
    color: '#FFFFFF',
    marginTop: '0px',
    minHeight: 'auto',
    fontWeight: '300',
    fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
    marginBottom: '3px',
    textDecoration: 'none',
  },
});

const theme = createMuiTheme({
  overrides: {
    MuiTableCell: {
      root: {
        paddingTop: 4,
        paddingBottom: 4,
        paddingRight: 5,
      },
    },
  },
});

export default Feature;
