import axiosInstance from '../axiosInstance';

export const updateMunasInfo = async (customerId, payload) => {
  const data = {
    ...payload,
  };

  try {
    const res = await axiosInstance.put(
      `/customers/updateMunasInfo/` + customerId,
      data,
    );
    return res.data;
  } catch (error) {
    return error.response.data;
  }
};
