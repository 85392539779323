import axiosInstance from './axiosInstance';

export const getByUpi = async upi => {
  try {
    const res = await axiosInstance.get(
      `/search?_entity=Customer&_select=customer.customerCode,customer.status,customer.channel,customer.upi,customer.channel,profile.firstName,profile.lastName,credential.mobile,credential.email,customer.createdAt&_page=1&customer.status=Active&customer.upi=${upi}`,
      {
        data: null,
      },
    );
    return res.data;
  } catch (error) {
    return error.response.data;
  }
};
