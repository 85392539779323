import React from 'react';
import { NavLink } from 'react-router-dom';
import { ListItem, ListItemText, Icon, makeStyles } from '@material-ui/core';
import styles from '../../../assets/jss/material-dashboard-react/components/sidebarStyle';

export default ({ name, to, icon }) => {
  const classes = useStyles();
  const MenuIcon = icon;
  return (
    <NavLink to={to} className={`${classes.item}`} activeClassName="active">
      <ListItem button className={`${classes.itemLink}`}>
        {MenuIcon && (
          <Icon className={`${classes.itemIcon} ${classes.whiteFont}`}>
            <MenuIcon />
          </Icon>
        )}
        <ListItemText className={classes.submenu}>{name}</ListItemText>
      </ListItem>
    </NavLink>
  );
};

const useStyles = makeStyles(styles);
