import * as Yup from 'yup';

const phoneRegExp = /^((0))[0-9]{10}$/;
export const yupSchemaObject = {
  email: Yup.string('Enter your email')
    .email('Enter a valid email!')
    .required('Email is required!'),
  firstName: Yup.string('Enter a name').required('Name is required!'),
  lastName: Yup.string('Enter a Last name').required('Last Name is required!'),
  mobile: Yup.string('Enter your mobile')
    .required('Mobile is required!')
    .matches(
      phoneRegExp,
      'Phone number is not valid, It should be start with 0 and 11 digits number',
    ),

  validPackages: Yup.string('Select a Valid Packages').required(
    'Valid Package is mandatory',
  ),
  gender: Yup.string('Select a Gender').required('Gender is mandatory'),
  street: Yup.string('Enter a Street').required('Street is mandatory'),
  zip: Yup.number('Enter a zip'),
  city: Yup.string('Enter a city').required('City is manadatory'),
  dob: Yup.date().required()
};

export const initialValues = {
  email: '',
  firstName: '',
  lastName: '',
  mobile: '',
  validPackages: '',
  gender: '',
  street: '',
  zip: '',
  city: '',
  dob: '',
};
