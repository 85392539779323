import React, { useState, useEffect } from 'react';
import {
  makeStyles,
  Grid,
  FormControlLabel,
  FormControl,
  FormLabel,
  RadioGroup,
  Radio,
  Select,
  MenuItem,
  InputLabel,
  TextField,
} from '@material-ui/core';
import Card from '../../components/Card/Card';
import CardBody from '../../components/Card/CardBody';
import { search } from '../../dataService/search';
import { getPackageToDropdown } from '../../dataService/getPackageToDropdown';

let queue;
const Filter = ({ searchTerms, setSearchTerms }) => {
  const classes = useStyles();
  const [customerCode, setCustomerCode] = useState('');
  const [customerUPI, setCustomerUPI] = useState('');
  const [name, setCustomerName] = useState('');
  const [allPackages, setallPackages] = useState([]);
  const [email, setEmail] = useState('');
  const [mobile, setMobile] = useState('');

  useEffect(() => {
    const updatedSearchTerms = {
      ...searchTerms,
      ...{ _page: 1 },
    };

    if (!customerUPI) {
      delete updatedSearchTerms['upi'];
      setSearchTerms(updatedSearchTerms);
    }

    if (customerUPI.length > 2) {
      updatedSearchTerms['upi'] = customerUPI;
      clearTimeout(queue);
      queue = setTimeout(() => {
        setSearchTerms(updatedSearchTerms);
      }, 500);
    }
  }, [customerUPI]);

  useEffect(() => {
    const updatedSearchTerms = {
      ...searchTerms,
      ...{ _page: 1 },
    };

    if (!mobile) {
      delete updatedSearchTerms['mobile'];
      setSearchTerms(updatedSearchTerms);
    }

    if (mobile.length > 2) {
      updatedSearchTerms['mobile'] = mobile;
      clearTimeout(queue);
      queue = setTimeout(() => {
        setSearchTerms(updatedSearchTerms);
      }, 500);
    }
  }, [mobile]);

  useEffect(() => {
    const updatedSearchTerms = {
      ...searchTerms,
      ...{ _page: 1 },
    };

    if (!name) {
      delete updatedSearchTerms['name'];
      setSearchTerms(updatedSearchTerms);
    }

    if (name.length > 2) {
      updatedSearchTerms['name'] = name;
      clearTimeout(queue);
      queue = setTimeout(() => {
        setSearchTerms(updatedSearchTerms);
      }, 500);
    }
  }, [name]);

  useEffect(() => {
    const updatedSearchTerms = {
      ...searchTerms,
      ...{ _page: 1 },
    };
    setSearchTerms(updatedSearchTerms);
  }, []);

  return (
    <Card>
      <CardBody>
        <h4 className={classes.filterTitle}>Search family member details</h4>
        <Grid container spacing={2}>
          <Grid md={3} item>
            <TextField
              value={customerUPI}
              onChange={ev => setCustomerUPI(ev.target.value)}
              className={classes.fullWidth}
              label="UPI"
              placeholder="PHBL0000000000"
              variant="outlined"
            />
          </Grid>
          <Grid md={2} item>
            <TextField
              value={name}
              onChange={ev => setCustomerName(ev.target.value)}
              className={classes.fullWidth}
              label="Name"
              variant="outlined"
            />
          </Grid>
          <Grid md={2} item>
            <TextField
              value={mobile}
              onChange={ev => setMobile(ev.target.value)}
              className={classes.fullWidth}
              label="Mobile"
              variant="outlined"
            />
          </Grid>
        </Grid>
      </CardBody>
    </Card>
  );
};

const useStyles = makeStyles({
  topSpacing: {
    marginTop: '30px',
  },
  filterTitle: {
    margin: '0 0 15px 0',
  },
  marginNone: {
    margin: 0,
  },
  formControl: {
    '& legend': {
      margin: '0 200px 0 0',
    },
  },
  fullWidth: {
    width: '100%',
  },
  formGroup: {
    '& label': {
      margin: 0,
    },
  },
});

export default Filter;
