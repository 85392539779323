import React, { useState, useEffect } from 'react';
import {
  makeStyles,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
} from '@material-ui/core';
import { get } from 'lodash';
import CustomInput from '../../../components/CustomInput/CustomInput';
import CardHeader from '../../../components/Card/CardHeader';
import Error from '../../../components/Error';
import GridItem from '../../../components/Grid/GridItem';
import GridContainer from '../../../components/Grid/GridContainer';
import Card from '../../../components/Card/Card';
import { featureType } from '../../../config/env.json';

const GeneralInputFormBlock = ({ formik, isEdit }) => {
  const classes = useStyle();

  const { touched, errors } = formik;
  return (
    <Card>
    
      <h4 style={{ paddingLeft: '20px' }}>
        <b>Common feature Information</b>
      </h4>
      <GridItem>
        <GridContainer>
          <GridItem xs={6}>
            <CustomInput
              groupText={!isEdit ? 'FTR_' : ''}
              labelText="Feature Code *"
              formControlProps={{
                fullWidth: true,
              }}
              inputProps={{
                ...formik.getFieldProps('featureCode'),
                disabled: isEdit,
                error: !!(touched.featureCode && errors.featureCode),
              }}
            />
            {touched.featureCode && errors.featureCode && (
              <Error msg={errors.featureCode} />
            )}
          </GridItem>

          <GridItem xs={6}>
            <FormControl className={classes.formControl}>
              <InputLabel>Feature Type *</InputLabel>
              <Select {...formik.getFieldProps('featureType')}>
                <MenuItem value="">Select Feature Type</MenuItem>
                {featureType.map(featureType => (
                  <MenuItem key={featureType.id} value={featureType.value}>
                    {featureType.label}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
            {touched.featureType && errors.featureType && (
              <Error msg={errors.featureType} />
            )}
          </GridItem>
        </GridContainer>
      </GridItem>

      <GridItem>
        <GridContainer>
          <GridItem xs={3}>
            <CustomInput
              labelText="Feature Weight *"
              formControlProps={{
                fullWidth: true,
              }}
              inputProps={{
                type: 'text',
                ...formik.getFieldProps('featureWeight'),
                error:
                  get(touched, 'featureWeight', '') &&
                  get(errors, 'featureWeight', ''),
              }}
            />
            {get(errors, 'featureWeight', '') &&
              get(touched, 'featureWeight', '') && (
                <Error msg={get(errors, 'featureWeight', '')} />
              )}
          </GridItem>

        </GridContainer>
      </GridItem>
    </Card>
  );
};

const useStyle = makeStyles({
  formControl: {
    width: '100%',
    marginTop: '27px',
  },
});

export default GeneralInputFormBlock;
