import React, { useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/core';
import GridItem from '../../components/Grid/GridItem';
import GridContainer from '../../components/Grid/GridContainer';
import Card from '../../components/Card/Card';
import CardBody from '../../components/Card/CardBody';
import Header from '../../components/Header';
import Loading from '../../components/Loading';
import CustomerSubscriptionDetailsBody from '../../components/CustomerSubscriptionDetailsBody';
import { getCustomerInfo } from './../../dataService/getCustomerInfo';
import CustomerInfoDetailsBody from './../../components/CustomerInfoDetailsBody/index';
import CustomerDocuments from './../../components/CustomerDocuments';
import { forEach } from 'lodash';

const CustomerDetails = props => {
  const classes = useStyles();
  const [subscriptionDetails, setSubscriptionDetails] = useState({});
  const [customerInfo, setCustomerInfo] = useState({});
  const [isLoading, setIsLoading] = useState(true);
  const [subscriptionDetailsFlag, setSubscriptionDetailsFlag] = useState(false);

  const requestCustomerInfo = async customerCode => {
    const res = await getCustomerInfo(customerCode);
    if (res.data.currentPackages.length > 0) {
      setSubscriptionDetailsFlag(true);
    }
    if (res.status !== 'error') {
      setCustomerInfo(res.data);
      setSubscriptionDetails(res.data);
    }
    setIsLoading(false);
  };

  useEffect(() => {
    requestCustomerInfo(props.match.params.customerCode);
  }, []);
  if (isLoading) {
    return (
      <div className={classes.topSpacing}>
        <Loading />
      </div>
    );
  }

  if (!subscriptionDetailsFlag && customerInfo.customerCode) {
    return (
      <GridContainer>
        <GridItem xs={12} className={classes.topSpacing}>
          <div className={classes.topSpacing} />
          <CustomerInfoDetailsBody customerInfo={customerInfo} />
          <h4>Customer subscription not found</h4>
        </GridItem>
        <GridItem xs={12} className={classes.topSpacing}>
          <div className={classes.topSpacing} />
          <CustomerDocuments customerInfo={customerInfo} />
        </GridItem>
      </GridContainer>
    );
  }

  if (subscriptionDetailsFlag && customerInfo.customerCode) {
    return (
      <GridContainer>
        <GridItem xs={12} className={classes.topSpacing}>
          <div className={classes.topSpacing} />
          <CustomerInfoDetailsBody customerInfo={customerInfo} />
        </GridItem>
        <GridItem xs={12} className={classes.topSpacing}>
          <div className={classes.topSpacing} />
          {subscriptionDetails.currentPackages.map(data => (
            <CustomerSubscriptionDetailsBody subscriptionDetails={data} />
          ))}
        </GridItem>
        <GridItem xs={12} className={classes.topSpacing}>
          <div className={classes.topSpacing} />
          <CustomerDocuments customerInfo={customerInfo} />
        </GridItem>
      </GridContainer>
    );
  }

  if (!customerInfo.customerCode) {
    return (
      <Card>
        <CardBody>
          <h4>Customer info not found</h4>
        </CardBody>
      </Card>
    );
  }

  const { customerCode } = customerInfo;
  return (
    <div>
      <Card style={{ backgroundColor: '#800054' }}>
        <CardBody>
          <h3 style={{ color: '#ffffff' }}>
            <b>Customer Code: {customerCode} </b>
          </h3>
        </CardBody>
      </Card>
      <GridContainer>
        <GridItem xs={12} className={classes.topSpacing}>
          <div className={classes.topSpacing} />
          <CustomerSubscriptionDetailsBody
            subscriptionDetails={subscriptionDetails}
          />
        </GridItem>
      </GridContainer>
      <GridContainer>
        <GridItem xs={12} className={classes.topSpacing}>
          <div className={classes.topSpacing} />
          <CustomerInfoDetailsBody customerInfo={customerInfo} />
        </GridItem>
      </GridContainer>
      <GridContainer>
        <GridItem xs={12} className={classes.topSpacing}>
          <div className={classes.topSpacing} />
          <CustomerDocuments customerInfo={customerInfo} />
        </GridItem>
      </GridContainer>
    </div>
  );
};

const useStyles = makeStyles({
  topSpacing: {
    marginTop: '30px',
  },
  subTitle: {
    fontSize: '24px',
    margin: '10px 0 20px 0',
  },
  spacing: {
    height: '30px',
  },
});

export default CustomerDetails;
