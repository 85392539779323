import React, { useState } from 'react';
import { AddAlert } from '@material-ui/icons';
import { Formik } from 'formik';
import GridItem from '../../components/Grid/GridItem';
import GridContainer from '../../components/Grid/GridContainer';
import Card from '../../components/Card/Card';
import Snackbar from '../../components/Snackbar/Snackbar';
import AddUserForm from './form';
import { addNewUser } from '../../dataService/addNewUser';
import Header from '../../components/Header';
import { initialValues, validationSchema } from './formSchema';
import CardBody from '../../components/Card/CardBody';
const AddNewUser = () => {
  const [submitDisable, setSubmitDisable] = useState(false);
  const [snackbarConfig, setSnackbarConfig] = useState({
    open: false,
    message: '',
    color: 'success',
  });

  const snackBarClose = () => {
    setSnackbarConfig({ ...snackbarConfig, open: false });
  };

  const onSubmit = async (values, { resetForm }) => {
    setSubmitDisable(true);

    const { status, message } = await addNewUser(values);
 
    if (status === 'success') {
      setSubmitDisable(false);
      setSnackbarConfig({
        open: true,
        message: 'User creation successful!',
        icon: AddAlert,
        color: 'success',
      });

      resetForm();
    } else {
      setSubmitDisable(false);
      setSnackbarConfig({
        open: true,
        message,
        icon: AddAlert,
        color: 'danger',
      });
    }
  };

  return (
    <>
      
      <Card style={{ backgroundColor: '#800054' }}>
        <CardBody>
          <h3 style={{ color: '#ffffff' }}>
            <b>Add New Users</b>
          </h3>
        </CardBody>
      </Card>
      <GridContainer>
        <GridItem xs={12}>
          <Card>
            <Formik
              initialValues={initialValues}
              validationSchema={validationSchema}
              onSubmit={onSubmit}
            >
              {formik => (
                <AddUserForm submitDisable={submitDisable} formik={formik} />
              )}
            </Formik>
          </Card>
        </GridItem>
      </GridContainer>
      <Snackbar
        {...snackbarConfig}
        icon={AddAlert}
        close
        closeNotification={snackBarClose}
        place="bl"
      />
    </>
  );
};

export default AddNewUser;
