import React from 'react';
import { makeStyles, Card, CardContent, Typography } from '@material-ui/core';

const NoContentCard = ({ message }) => {
  const classes = useStyles();
  return (
    <Card className={classes.card}>
      <CardContent>
        <Typography gutterBottom variant="h5" component="h2">
          {message}
        </Typography>
      </CardContent>
    </Card>
  );
};

const useStyles = makeStyles({
  card: {
    width: '100%',
    textAlign: 'center',
    height: '300px',
    marginTop: '30px',
    justifyContent: 'center',
    display: 'flex',
    alignItems: 'center',
  },
});

export default NoContentCard;
