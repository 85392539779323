import React from 'react';
import { TableRow, TableCell } from '@material-ui/core';
import { Link } from 'react-router-dom';
import EditIcon from '@material-ui/icons/Edit';
import Button from '../../components/CustomButtons/Button';

const ActivityRow = ({
  id,
  activityCode,
  title,
  createdAt,
  updatedAt,
}) => {

  return (
    <TableRow>
      <TableCell>{activityCode}</TableCell>
      <TableCell>{title}</TableCell>
      <TableCell>{createdAt || '-'}</TableCell>
      <TableCell>{updatedAt || '-'}</TableCell>
      <TableCell align="right">
        <Link to={`/manage/activities/${activityCode}/edit`}>
          <Button small>
          <EditIcon />
          Edit
          </Button>
        </Link>
      </TableCell>
    </TableRow>
  );
};

export default ActivityRow;
