// import axiosLocalInstance from './axiosLocalInstance';
import axiosInstance from './axiosInstance';

export const updateEmailContent = async payload => {
  const { emailBody, emailSubject, type } = payload;

  const data = {
    emailBody,
    emailSubject,
    type,
  };

  try {
    const res = await axiosInstance.post(
      '/staticDatas/updateEmailContent',
      data,
    );
    return res.data;
  } catch (error) {
    return error.response.data;
  }
};

export const getEmailContent = async type => {
  try {
    const res = await axiosInstance.get(
      `/staticDatas/getEmailContent?type=${type}`,
      {
        data: null,
      },
    );
    return res.data;
  } catch (error) {
    return error.response.data;
  }
};
