import React from 'react';
import {
  Card,
  Table,
  TableHead,
  TableCell,
  TableRow,
  TableBody,
  makeStyles,
} from '@material-ui/core';
import CardBody from '../Card/CardBody';
import GridItem from '../Grid/GridItem';
import GridContainer from '../Grid/GridContainer';

const CustomerSubscriptionDetailsBody = (data) => {
  const packageCode = data.subscriptionDetails.packagecode;
  const packageTitle = data.subscriptionDetails.packagetitle;
  const subscriptionStart = data.subscriptionDetails.subscriptionstart;
  const subscriptionEnd = data.subscriptionDetails.subscriptionend;
  const ordercode = data.subscriptionDetails.ordercode;
  const classes = useStyles();
  return (
    <Card>
      <CardBody>
        <GridContainer>
          <GridItem md={12}>
            <h4 className={classes.subTitle}>
              {packageTitle||'-'}
            </h4>
          </GridItem>
          <GridItem md={6}>
            <Table className={classes.table}>
              <TableHead>
                <TableRow>
                  <TableCell>Parameter</TableCell>
                  <TableCell align="right">Value</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                <TableRow>
                  <TableCell align="left">Subscription Start</TableCell>
                  <TableCell align="right">
                    {subscriptionStart || '-'}
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell align="left">Subscription End</TableCell>
                  <TableCell align="right">{subscriptionEnd || '-'}</TableCell>
                </TableRow>
              </TableBody>
            </Table>
          </GridItem>

          <GridItem md={6}>
            <Table className={classes.table}>
              <TableHead>
                <TableRow>
                  <TableCell>Parameter</TableCell>
                  <TableCell align="right">Value</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
              <TableRow>
                  <TableCell align="left">Package Code</TableCell>
                  <TableCell align="right">{packageCode || '-'}</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell align="left">Order Code</TableCell>
                  <TableCell align="right">{ordercode || '-'}</TableCell>
                </TableRow>
              </TableBody>
            </Table>
          </GridItem>
          <GridItem md={12}>
            <div className={classes.spacing} />
          </GridItem>
        </GridContainer>
      </CardBody>
    </Card>
  );
};

const useStyles = makeStyles({
  topSpacing: {
    marginTop: '30px',
  },
  subTitle: {
    marginTop: 0,
  },
  spacing: {
    height: '30px',
  },
  flexVCenter: {
    display: 'flex',
    alignItems: 'center',
  },
});

export default CustomerSubscriptionDetailsBody;
